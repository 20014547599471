import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import './index.scss';

const ToggleButton = ({ className, value, icon = null, text, active, handleClick }) => {
    return (
        <Button
            className={`${className} ToggleButton`}
            fluid
            toggle
            active={active}
            value={value}on
            onClick={handleClick}
        >
            {icon !== null ? <Icon className={icon} size='large' /> : ''} {text}
        </Button>
    );
}

export default ToggleButton;

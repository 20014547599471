import React from "react";
import { Grid } from "semantic-ui-react";

const GridListRow = ({
    onClick = () => {},
    children,
    style = {},
    className = "",
}) => (
    <Grid.Row
        onClick={onClick}
        className={className}
        verticalAlign="middle"
        columns="equal"
        style={{
            marginBottom: "1em",
            borderRadius: "1em",
            ...style,
        }}
    >
        {children}
    </Grid.Row>
);

export default GridListRow;

import React from 'react';
import { Form } from 'semantic-ui-react';
import './index.scss';

const TopPaddedControls = ({children}) => (
    <Form.Group widths='equal' className='TopPaddedControls'>
        {children}
    </Form.Group>
)

export default TopPaddedControls;

import React from 'react';
import { Button, TimeUnitToggler, CustomRangeDateModal } from 'dyl-components';
import { Segment, Grid, Header, Popup } from 'semantic-ui-react';

const DateModificationToolbar = ({
    onClickPrevious,
    onClickNext,
    onClickPresent,
    dateOnDisplay,
    onToggleDay,
    onToggleMonth,
    onToggleWeek,
    isPresentButtonDisabled,
    onClickPreviousFromPresent,
    onClickYearToDate,
    areNextAndPreviousButtonsDisabled,
    onOpenCustomDateRange,
    isCustomDateRangeOpen,
    onCloseCustomDateRange,
    onDateRangeChange,
    dateRange,
    getWorkflowsByRange
}) => (
    <Segment attached color='blue' inverted>
        <Grid verticalAlign='middle' columns='equal' textAlign='center' stackable>
            <Grid.Column width={6} floated='left' textAlign='center'>
                <Button.Group size='large' basic floated='left' inverted>
                    <Button icon='chevron left' onClick={onClickPrevious} disabled={areNextAndPreviousButtonsDisabled} />
                    <Button icon='chevron right' onClick={onClickNext} disabled={areNextAndPreviousButtonsDisabled} />
                </Button.Group>
                <Button.Group basic size='large' floated='left' inverted>
                    <Button onClick={() => { onClickPresent('day') }} disabled={isPresentButtonDisabled}>
                        Today
                    </Button>
                </Button.Group>
            </Grid.Column>
            <Grid.Column width={4}>
                <Header as='h1' inverted>
                    {dateOnDisplay}
                </Header>
            </Grid.Column>
            <Grid.Column width={6} floated='right'>
                <Button.Group basic size='large' floated='right' inverted>
                    <Button onClick={onToggleWeek}>
                        Week
                    </Button>
                    <Button onClick={onToggleMonth}>
                        Month
                    </Button>
                    <Button onClick={onToggleDay}>
                        Day
                    </Button>
                    <Popup
                        flowing
                        trigger={<Button icon='calendar outline plus' />}
                        content={(
                            <Grid columns={'equal'}>
                                <Grid.Column>
                                    <TimeUnitToggler content='Today' onClick={() => { onClickPresent('day') }} />
                                    <TimeUnitToggler content='Yesterday' onClick={() => { onClickPreviousFromPresent('day') }} />
                                    <TimeUnitToggler content='This Week' onClick={() => { onClickPresent('week') }} />
                                    <TimeUnitToggler content='Last Week' onClick={() => { onClickPreviousFromPresent('week') }} />
                                    <TimeUnitToggler content='This Month' onClick={() => { onClickPresent('month') }} />
                                    <TimeUnitToggler content='Last Month' onClick={() => { onClickPreviousFromPresent('month') }} />
                                    <TimeUnitToggler content='Year to Date' onClick={onClickYearToDate} />
                                    <TimeUnitToggler content='Custom Range' onClick={onOpenCustomDateRange} />
                                </Grid.Column>
                            </Grid>
                        )}
                        on='click'
                    />
                </Button.Group>
            </Grid.Column>
        </Grid>
        <CustomRangeDateModal
            open={isCustomDateRangeOpen}
            onClose={onCloseCustomDateRange}
            onDateRangeChange={onDateRangeChange}
            dateRange={dateRange}
            getData={getWorkflowsByRange} />
    </Segment>
)

export default DateModificationToolbar;

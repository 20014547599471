import React from 'react';
import { Table } from 'dyl-components';
import { Loader, Segment } from 'semantic-ui-react';

const TableLoader = ({ colspan, isLoading }) => (
    isLoading ? <Table.Row>
        <Table.Cell colspan={colspan}>
            <Segment basic>
                <Loader active />
            </Segment>
        </Table.Cell>
    </Table.Row> : null
);

export default TableLoader;

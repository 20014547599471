import { ControlledPopup } from 'dyl-components';
import React from 'react';
import { Icon, Form, Header } from 'semantic-ui-react';

const SOCIAL_MEDIA_TYPES = [
    { icon: 'facebook', key: 'facebook', value: 'facebook', text: 'Facebook' },
    { icon: 'linkedin', key: 'linkedin', value: 'linkedin', text: 'LinkedIn' },
    { icon: 'twitter', key: 'twitter', value: 'twitter', text: 'Twitter' }
];

const EditSocialMediaForm = ({
    icon,
    type,
    onChangeSocialMediaType,
    link,
    onChangeSocialMediaLink,
    onClose,

    onConfirmEdit,
    isSavingEdit,
    onRemove,
    isDeleting,
    onDisablePopupClose,
    onEnablePopupClose
}) => (
    <Form size='tiny'>
        <Header as='h4'>Edit Account Type</Header>
        <Form.Select
            disabled
            options={SOCIAL_MEDIA_TYPES}
            selectOnBlur={false}
            value={type}
            onChange={onChangeSocialMediaType}
            text={<span><Icon name={icon} />{type}</span>}
        />
        <Form.Input
            size='tiny'
            value={link}
            onChange={onChangeSocialMediaLink}
            placeholder='Edit social media URL'
        />
        <Form.Group widths='equal'>
            <ControlledPopup
                trigger={<Form.Button onClick={() => {
                    if (onDisablePopupClose) {
                        onDisablePopupClose();
                    }
                }} size='tiny' disabled={isSavingEdit} loading={isDeleting} color='red'>Remove</Form.Button>}
                onConfirm={onRemove}
                onCancel={() => {
                    if (onEnablePopupClose) {
                        onEnablePopupClose();
                    }
                }}
            />
            <Form.Button basic primary disabled={isSavingEdit || isDeleting} size='tiny' onClick={onClose}>
                Cancel
            </Form.Button>
            <Form.Button disabled={isDeleting ? true : (type && link) ? false : true} loading={isSavingEdit} size='tiny' primary onClick={onConfirmEdit}>
                Save
            </Form.Button>
        </Form.Group>
    </Form>
);

class EditSocialMediaFormContainer extends React.Component {
    state = {
        link: this.props.link
    }

    onChangeSocialMediaLink = (_, { value }) => {
        this.setState({ link: value });
    }

    onConfirmEdit = () => {
        this.props.onConfirmEdit(this.props.id, this.state.link);
    }

    onRemove = () => {
        this.props.onRemove(this.props.id);
    }

    render() {
        return (
            <EditSocialMediaForm
                link={this.state.link}
                onChangeSocialMediaLink={this.onChangeSocialMediaLink}
                onConfirmEdit={this.onConfirmEdit}
                onRemove={this.onRemove}

                icon={this.props.icon}
                isSavingEdit={this.props.isSavingEdit}
                onClose={this.props.onClose}
                onChangeSocialMediaType={this.props.onChangeSocialMediaType}
                type={this.props.type}
                isDeleting={this.props.isDeleting}
                onDisablePopupClose={this.props.onDisablePopupClose}
                onEnablePopupClose={this.props.onEnablePopupClose}
            />
        )
    }
}

export default EditSocialMediaFormContainer;

import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import AddressForm from '../AddressForm';
import { DividingHeader, MultipleField } from 'dyl-components';
import MainContactPersonForm from '../MainContactPersonForm';

const AddHouseholdForm = ({
    household_name,
    household_type,

    industry,
    industry_options,

    onChange,

    phones,
    emails,
    onChangeItem,
    onToggleMainItem,
    onRemoveItem,
    onAddItem,

    websites,

    address = {
        label: '',
        street_number: '',
        street: '',
        city: '',
        state: '',
        zip: ''
    },
    state_options,
    household_contact = {
        name: '',
        emails: [{ id: 1, main: false, value: '' }],
        phones: [{ id: 1, main: false, value: '' }],
        faxes: [{ id: 1, value: '' }],
        title: '',
        role: 'none'
    },
    onChangeHouseholdContactDetail,
    onChangeHouseholdContactItem,
    onAddHouseholdContactItem,
    onRemoveHouseholdContactItem,
    onToggleMainHouseholdContactItem,
    primaryContactField,

    onChangeAddress,

    current_customer,
    add_to_hotlist,

    labelsField
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                name="household_name"
                value={household_name}
                onChange={onChange}
                label="Household Name"
                placeholder="Enter household name"
            />
            <Form.Input
                name="household_type"
                value={household_type}
                onChange={onChange}
                label="Household type"
                placeholder="Enter household type"
            />
            <Form.Select
                name="industry"
                value={industry}
                onChange={onChange}
                label="Industry"
                selectOnBlur={false}
                options={industry_options}
                placeholder="Select industry"
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Field
                control={MultipleField}
                items={phones}
                name="phones"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add phone"
                hasMain
                label="Phone"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={emails}
                name="emails"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add email"
                hasMain
                label="Email"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={websites}
                name="websites"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add website"
                label="Website"
                fluid
            />
        </Form.Group>
        <DividingHeader content="Household Address" />
        <AddressForm
            city={address.city}
            label={address.label}
            onChange={onChangeAddress}
            state={address.state}
            street={address.street}
            street_number={address.street_number}
            zip={address.zip}
            state_options={state_options}
        />
        <DividingHeader content="Primary Contact" />
        {primaryContactField}
        <DividingHeader content="OR Add a New Contact" />
        <MainContactPersonForm
            name={household_contact.name}
            role={household_contact.role}
            title={household_contact.title}
            onChange={onChangeHouseholdContactDetail}

            emails={household_contact.emails}
            faxes={household_contact.faxes}
            phones={household_contact.phones}
            onAddItem={onAddHouseholdContactItem}
            onChangeItem={onChangeHouseholdContactItem}
            onRemoveItem={onRemoveHouseholdContactItem}
            onToggleMainItem={onToggleMainHouseholdContactItem}
            type='Household'
        />
        <Form.Group widths="equal">
            <Form.Field>
                <label>Current Customer</label>
                <Checkbox
                    name="current_customer"
                    checked={current_customer}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            <Form.Field>
                <label>Add to Hotlist</label>
                <Checkbox
                    control
                    name="add_to_hotlist"
                    checked={add_to_hotlist}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            {labelsField}
        </Form.Group>
    </React.Fragment>
);

export default AddHouseholdForm;

import React from "react";
import {
    Table,
    TableLoader,
    EmptyListPlaceholder,
    DateTimeUtils,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import ListViewHeader from "./header";
import Rows from "./rows";

import "./index.scss";

export default function CalendarListView({
    events,
    isReadingEvents,
    calendarDate,
    setCalendarDate,
    timeUnits,
    setTimeUnits,
    onEventClick,
    onContactClick,
    onTaskComplete,
    onTaskRemove,
    onTaskCall,
    onTaskText,
    hasEmailIntegrations,
    onTaskEmailNoIntegrationsContent,
    onSequenceTaskComplete,
    onSequenceTaskRemove,
    onSequenceTaskCall,
    onSequenceTaskText,
    onSequenceTaskEmail,
    onRefresh,
    setModal,
    setContactId,
    onSequenceTaskReschedule,
}) {
    const goToDay = (day) => {
        setTimeUnits("day");
        setCalendarDate(day);
    };

    const groupEventsByDate = (events) => {
        // returns: [ { '02/03 Wed': { ts: timestamp, events: [ ... ] } ... ]

        const one_day = 86400; // 60*60*24;
        const start = DateTimeUtils.getStartOf(calendarDate, timeUnits);
        const end = DateTimeUtils.getEndOf(calendarDate, timeUnits);

        let response = {};
        for (let date = start; date < end; date = date + one_day) {
            response[`${DateTimeUtils.formatEpoch(date, "MM/DD ddd")}`] = {
                ts: date,
                events: [],
            };
        }

        events.forEach((event) => {
            response[event.date]?.events.push(event);
        });

        return response;
    };

    const isGroupedByDate = timeUnits !== "day";
    const items = !isGroupedByDate ? events : groupEventsByDate(events);

    return (
        <React.Fragment>
            <Table striped={false} unstackable className="CalendarTable">
                <SemanticTable.Header className="Table__header--primary">
                    <ListViewHeader />
                </SemanticTable.Header>
                <Table.Body>
                    {isReadingEvents ? (
                        <TableLoader isLoading colspan={7} />
                    ) : (
                        <Rows
                            isGroupedByDate={isGroupedByDate}
                            items={items}
                            onContactClick={onContactClick}
                            goToDay={goToDay}
                            onEventClick={onEventClick}
                            onTaskComplete={onTaskComplete}
                            onTaskRemove={onTaskRemove}
                            onTaskCall={onTaskCall}
                            onTaskText={onTaskText}
                            hasEmailIntegrations={hasEmailIntegrations}
                            onTaskEmailNoIntegrationsContent={
                                onTaskEmailNoIntegrationsContent
                            }
                            onSequenceTaskComplete={onSequenceTaskComplete}
                            onSequenceTaskRemove={onSequenceTaskRemove}
                            onSequenceTaskCall={onSequenceTaskCall}
                            onSequenceTaskText={onSequenceTaskText}
                            onSequenceTaskEmail={onSequenceTaskEmail}
                            onRefresh={onRefresh}
                            setModal={setModal}
                            setContactId={setContactId}
                            onSequenceTaskReschedule={onSequenceTaskReschedule}
                        />
                    )}
                </Table.Body>
            </Table>
            {!isReadingEvents && items.length <= 0 ? (
                <EmptyListPlaceholder
                    subtitle={<></>}
                    title="No Activities created Under Day"
                    wrapped={false}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
}

import React from 'react';
import './index.scss';
import { Segment } from 'semantic-ui-react';

const LeftPaddedContent = ({children}) => (
    <Segment basic className="LeftPaddedContent">
        {children}
    </Segment>
)

export default LeftPaddedContent;

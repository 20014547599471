import React from 'react';
import { Menu } from 'semantic-ui-react';

import './index.scss';

const TargetMenu = ({ children }) => (
    <Menu secondary pointing className='TargetMenu'>
        {children}
    </Menu>
)

TargetMenu.Item = ({ name, target, active, onClick, children }) => (
    <Menu.Item
        name={name}
        as='a'
        herf={target}
        active={active}
        onClick={onClick}
    >
        {children}
    </Menu.Item>
)

export default TargetMenu;

import React, { useState } from 'react';
import { Grid, Header, Divider, Icon } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';

import LabelField from './LabelField';

const ExistingLabels = ({ fields = [], items = [], name, getPropsByType, info, onRemove, onEditLabel, fieldRef }) => {

    const { control, formState: { isValid }, trigger, getValues, setValue, setFocus } = useForm({
        mode: 'onChange',
        defaultValues: {
            [`existing_${name}`]: items
        }
    });

    const { fields: existingItems, remove, update } = useFieldArray({
        control,
        name: `existing_${name}`,
        keyName: 'labelId'
    });

    const onRemoveExistingLabel = (id, index) => {
        if (onRemove) {
            onRemove(id).then(() => {
                remove(index);
            }, () => { })
        }
    }

    const [labelOnEdit, setLabelOnEdit] = useState({ id: 0, previousValues: [] });

    const onEdit = (id) => {
        let index = existingItems.findIndex(item => item.id === id);
        if (labelOnEdit.id) {
            onCancelEdit();
        }
        setLabelOnEdit({ id, previousValues: [...existingItems[index].value] });
    }

    const onCancelEdit = () => {
        let itemIndex = existingItems.findIndex(item => item.id === labelOnEdit.id);
        labelOnEdit.previousValues.forEach((value, index) => {
            setValue(`existing_${name}.${itemIndex}.value.${index}`, value);
        });
        trigger();
        setLabelOnEdit({ id: 0, previousValues: [] });
    }

    const onConfirmEdit = () => {
        let tempItems = getValues()[`existing_${name}`];
        let index = tempItems.findIndex(item => item.id === labelOnEdit.id);
        let value = tempItems[index].value;
        value[0] = value[0].replace(/\s+/g, ' ').trim();

        if (onEditLabel) {
            if (value[0] !== labelOnEdit.previousValues[0]) {
                onEditLabel(labelOnEdit.id, value[0]).then((id) => {
                    value[0] = value[0].replace(/\s+/g, ' ').trim();
                    update(index, { id: id || labelOnEdit.id, value })
                    setLabelOnEdit({ id: 0, previousValues: [] });
                }, () => {

                });
            } else {
                onCancelEdit();
            }
        }
    }

    const renderHeader = () => {

        if (fields.length > 1) {
            return (
                <React.Fragment>
                    <Grid.Row >
                        <Grid.Column width={16}>
                            <Header> {`Existing ${fields[0].header}`}</Header>
                            {info && <div className='EditableLabels__info'> <Icon name='fas fa-info-circle' color='grey' /> {info} </div>}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {fields.map(({ header }) => (
                            <Grid.Column width={6}>
                                <Header> {header}</Header>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </React.Fragment>
            )
        }

        return (
            <Grid.Row >
                <Grid.Column width={16}>
                    <Header> {`Existing ${fields[0].header}`}</Header>
                    {info && <div className='EditableLabels__info'> <Icon name='fas fa-info-circle' color='grey' /> {info} </div>}
                </Grid.Column>
            </Grid.Row>
        )
    }

    const hasExistingItems = existingItems.length > 0;

    return (
        hasExistingItems ?
            <React.Fragment>
                <Grid>
                    {renderHeader()}

                    {existingItems.map((item, itemIndex) => (
                        <Grid.Row key={item.labelId} style={{ paddingBottom: 0 }}>
                            {item.value.map((value, valueIndex) => (
                                <Grid.Column width={6}>
                                    <LabelField
                                        {...getPropsByType(valueIndex)}

                                        itemIndex={itemIndex}
                                        valueIndex={valueIndex}
                                        value={value}
                                        name={`existing_${name}`}
                                        control={control}

                                        remove={() => onRemoveExistingLabel(item.id, itemIndex)}
                                        hasMoreItems={true}
                                        isExisting={true}
                                        isDirty={true}
                                        isValid={isValid}

                                        shouldRenderActionButton={item.value.length === valueIndex + 1}
                                        editMode={item.id === labelOnEdit.id}

                                        onEdit={() => { onEdit(item.id) }}
                                        onCancelEdit={() => { onCancelEdit() }}
                                        onConfirmEdit={() => { onConfirmEdit() }}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    ))}
                </Grid>
                <Divider />
            </React.Fragment>
            : <React.Fragment />
    );
}

export default ExistingLabels;

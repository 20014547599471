import React, { useEffect, useRef } from 'react';
import { Grid, Icon, Ref, Segment } from 'semantic-ui-react';
import { Draggable } from 'react-beautiful-dnd';

import './index.scss';
import { ButtonLink } from 'dyl-components';
import { createPortal } from 'react-dom';
import { PhoneUtil } from 'utils';

export const CALL_STATUS = {
    REACHED: 'OUTBOUND',
    NOT_REACHED: 'NOT REACHED',
    CALLING: 'INBOUND'
}

export const Call = ({ call, isReading, index, draggable, onClickActiveCallerName }) => {
    if (draggable) {
        return <DraggableCall call={call} index={index} isReadingDroppables={isReading} onClickActiveCallerName={onClickActiveCallerName} />
    }
    return <NonDraggableCall call={call} />
}

const CallStatus = ({ status = '' }) => {
    status = status.toUpperCase();
    switch (status) {
        case CALL_STATUS.REACHED:
            return <Icon name='phone' link size='large' color='green' />
        case CALL_STATUS.NOT_REACHED:
            return <Icon name='microphone slash' link size='large' color='red' />
        case CALL_STATUS.CALLING:
            return <Icon name='phone volume' link size='large' color='blue' />
        default:
            return null;
    }
}

const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.pointerEvents = 'none';
        div.style.top = '0';
        div.style.width = '100%';
        div.style.height = '100%';

        self.elt = div;
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    return (render) => (provided, snapshot) => {
        const element = render(provided, snapshot);
        if (provided.draggableProps.style.position === 'fixed') {
            return createPortal(element, self.elt);
        }
        return element;
    };
};

const DraggableCall = ({ call, isReadingDroppables, index, onClickActiveCallerName }) => {
    const renderDraggable = useDraggableInPortal();

    return (
        <Draggable key={call.uuid_call} draggableId={call.uuid_call} index={index} isDragDisabled={isReadingDroppables}>
            {renderDraggable((provided, snapshot) => (
                <Ref innerRef={provided.innerRef}>
                    <Grid as={Segment} className={`Call Call--${snapshot.isDragging ? 'dragging' : ''}`}  {...provided.draggableProps}>
                        <Grid.Row columns='equal'>
                            <Grid.Column width={1} verticalAlign='middle'>
                                <CallStatus status={call.system_result || CALL_STATUS.CALLING} />
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <div className='Call__caller'>
                                        <ButtonLink onClick={() => { onClickActiveCallerName(call) }}>
                                            {call.name}
                                        </ButtonLink>
                                    </div>
                                    <div className='Call__details'>
                                        <span>
                                            {PhoneUtil.formatPhoneNumber(call.destination)}
                                        </span>
                                        <span>
                                            {call.duration}
                                        </span>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign='middle' className='Call__drag-handle'>
                                <Icon {...provided.dragHandleProps} name='arrows alternate' size="large" color='grey' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Ref>
            ))
            }
        </Draggable >
    )
};

const NonDraggableCall = ({ call }) => (
    <Grid as={Segment} className={`Call`}>
        <Grid.Row columns='equal'>
            <Grid.Column width={1} verticalAlign='middle'>
                <CallStatus status={call.system_result || CALL_STATUS.CALLING} />
            </Grid.Column>
            <Grid.Column>
                <div>
                    <div className='Call__caller'>
                        <ButtonLink>
                            {call.name}
                        </ButtonLink>
                    </div>
                    <div className='Call__details'>
                        <span>
                            {PhoneUtil.formatPhoneNumber(call.destination)}
                        </span>
                        <span>
                            {call.duration}
                        </span>
                    </div>
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

import React from 'react';
import { Popup } from 'semantic-ui-react';

import Avatar from 'react-avatar';

import './index.scss';

const LinkedAccount = ({ account, linkToAccount, subtitle, popup = true, maxWidth }) => {
    const details = (
        <div className='LinkedAccount'>
            {account && <Avatar name={account} maxInitials={2} round size='3em' />}
            <div className="LinkedAccount__information">
                <span style={{ maxWidth }} className='LinkedAccount__account'>{linkToAccount || account}</span>
                <div style={{ maxWidth }}><span className="LinkedAccount__subtitle">{subtitle}</span></div>
            </div>
        </div>
    )
    return (
        popup ? (
            <Popup
                inverted
                content={(
                    <div>
                        <p>{account}</p>
                        <p>{subtitle}</p>
                    </div>
                )}
                trigger={(details)}
            />
        ) : details
    )
};

export default LinkedAccount;

import React from 'react';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import './index.scss';

class CheckboxDropdown extends React.Component {
    state = {
        open: false
    }

    onOpen = () => {
        this.setState({
            open: true
        });
    }

    onClose = () => {
        this.setState({
            open: false
        });
    }

    onChange = (e, itemValue) => {
        const selectedItems = this.props.value.slice(0);
        if (this.isSelected(itemValue)) {
            const indexOfItemToUnselect = selectedItems.findIndex(
                selectedItem => selectedItem === itemValue
            );
            selectedItems.splice(indexOfItemToUnselect, 1);
        } else {
            selectedItems.push(itemValue);
        }
        if (this.props.onChange) {
            this.props.onChange(e, {
                name: this.props.name,
                value: selectedItems
            });
        }
    };

    isSelected = itemValue => {
        return (
            this.props.value.findIndex(
                selectedItem => selectedItem === itemValue
            ) !== -1
        );
    };

    render() {
        const { options, editable } = this.props;
        return (
            <Dropdown
                open={this.state.open}
                onOpen={editable ? this.onOpen : () => { }}
                onClose={this.onClose}
                onBlur={this.props.onBlur}
                className='CheckboxDropdown'
                selection={this.props.selection}
                placeholder={this.props.placeholder}
                text={this.props.text}
                disabled={this.props.disabled}
            >
                <Dropdown.Menu className='CheckboxDropdown__menu'>
                    {options.map(({ key, value, text }) => (
                        <Dropdown.Item
                            onClick={e => {
                                this.onChange(e, value);
                                e.stopPropagation();
                            }}
                            selected={this.isSelected(value)}
                            key={key}
                        >
                            <Checkbox
                                onMouseDown={e => { e.preventDefault() }}
                                checked={this.isSelected(value)}
                                label={text}
                            />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

CheckboxDropdown.Header = ({ children }) => (
    <Dropdown.Header>{children}</Dropdown.Header>
)

CheckboxDropdown.Item = class extends React.Component {
    state = {
        checked: false
    }

    handleChange() {
        this.setState({ checked: !this.state.checked });
    }

    render() {
        return (
            <Dropdown.Item onClick={this.handleChange.bind(this)}><Checkbox name={this.props.name} label={this.props.children || this.props.label} checked={this.state.checked} /></Dropdown.Item>
        )
    }
}

CheckboxDropdown.defaultProps = {
    options: [],
    value: [],
    editable: true
}

export default CheckboxDropdown;

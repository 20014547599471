import React from 'react';
import { Form, Icon, Checkbox } from 'semantic-ui-react';
import { STATES } from '__mocks__/mockData';
import './index.scss';

const LocationsForm = ({ locations, location_type, onChange, onRemove, onToggleMain, size = 'tiny', onInlineEditPrimary = false, onCreateMode = false, DataTabLink, AddLocationButton }) => (
    <Form size={size}>
        {locations.map(({ id, label, street, additional_street, city, state, zip, deleteable, main }, index) => (
            <React.Fragment key={id}>
                <Form.Input
                    className='LocationsForm__label'
                    placeholder={onCreateMode ? 'Ex. Corporate Headquarters' : 'Label'}
                    label={onCreateMode ?
                        <span style={{ paddingBottom: 10 }}><b>{`Create a label for your ${location_type}`}</b> {deleteable &&
                            <Icon
                                link
                                name='times'
                                color='red'
                                onClick={() => { onRemove('location', id) }}
                            />}
                        </span> : ''}
                    name='label'
                    size='tiny'
                    value={label}
                    onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }} />

                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='Street #'
                    onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }}
                    fluid
                    name='additional_street'
                    value={additional_street}
                />
                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='Street'
                    onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }}
                    fluid
                    name='street'
                    value={street}
                />
                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='City'
                    onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }}
                    fluid
                    name='city'
                    value={city}
                />
                <Form.Group widths='equal'>
                    <Form.Select
                        size='tiny'
                        placeholder={'State'}
                        header={'State'}
                        options={
                            STATES.map(({ key, text }) => ({
                                key, value: key, text
                            }))
                        }
                        fluid
                        style={{ zIndex: 11 }}
                        onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }}
                        name='state'
                        value={state}
                    />
                    <Form.Input
                        size='tiny'
                        name='zip'
                        onChange={(_, { name, value }) => { onChange('location', { name, value, id }) }}
                        placeholder='Zip Code'
                        fluid
                        value={zip}
                    />
                </Form.Group>

                <Checkbox
                    onChange={() => { onToggleMain('location', { id }) }}
                    label={`Make this my default location`}
                    size={size}
                    name='main'
                    checked={main}
                    style={{ marginBottom: 15 }}
                />

                {!onInlineEditPrimary && locations.length - 1 === index &&
                    <React.Fragment>
                        <Form.Group>
                            <div>
                                {AddLocationButton}
                            </div>
                        </Form.Group>
                        {DataTabLink &&
                            <Form.Group>
                                <div style={{ marginBottom: 15 }}>
                                    {DataTabLink}
                                </div>
                            </Form.Group>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        ))}
    </Form>
);

export default LocationsForm;

import React from 'react';
import { Image, Grid } from 'semantic-ui-react';

const ActionableCard = ({ imageUrl, name, actions }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column>
            <Image src={imageUrl} avatar />
        </Grid.Column>
        <Grid.Column width={10}>
            <div>{name}</div>
            <div>{actions}</div>
        </Grid.Column>
    </Grid>
);

export default ActionableCard;

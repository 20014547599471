import React from 'react';
import { Grid } from 'semantic-ui-react';

const GridListCell = ({ children, ...otherProps }) => (
    <Grid.Column {...otherProps}>
        {children}
    </Grid.Column>
);

export default GridListCell;



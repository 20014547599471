import React from "react";
import { SearchBar, VALIDATION_PATTERNS } from "dyl-components";

import "./index.scss";

import ColumnSelector from "./subcomponents/ColumnSelector";

const ModulesFilter = ({
    isLoading,
    search,
    onSearchSubmit,
    cancelFunction,
    onChangeSearch,
    columnRows,
    columnCount,
    isColumnHidden,
    onToggleColumn,
    hasSearchBar = true,
    searchBarPlaceholder = "Search by contact name, email or phone number",
    validationPattern = VALIDATION_PATTERNS.alphanumeric_search
}) => {
    return (
        <div
            className={`ModulesToolbar ModulesToolbar${
                hasSearchBar ? "" : "__no-search"
            }`}
        >
            {hasSearchBar && (
                <SearchBar
                    isSearching={isLoading}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder={searchBarPlaceholder}
                    onChange={onChangeSearch}
                    validationPattern={validationPattern}
                />
            )}

            <ColumnSelector
                columnRows={columnRows}
                columnCount={columnCount}
                isLoading={isLoading}
                isColumnHidden={isColumnHidden}
                onToggleColumn={onToggleColumn}
            />
        </div>
    );
};

export default ModulesFilter;

import React from 'react';
import './index.scss';
import { Grid, Input } from 'semantic-ui-react';
import { EditableField } from 'dyl-components';

const FieldValue = ({ field, value, name, onEditMode, onChange }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column style={{ paddingTop: 5, paddingBottom: 5 }} className='FieldValue__field'>
            {field}
        </Grid.Column>
        <Grid.Column width={9} style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }} className='FieldValue__value'>
            <EditableField
                isEditMode={onEditMode}
                value={value}
                name={name}
                onChange={onChange}
                control={Input}
                className='FieldValue__editBox'
            />
        </Grid.Column>
    </Grid>
);

export default FieldValue;

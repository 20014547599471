import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const TableWithHeader = ({
    header,
    table,
    className=''
}) => (
    <Segment.Group className={`TableWithHeader ${className} `}>
        <Segment className='TableWithHeader__header'>
            {header}
        </Segment>
        <Segment className='TableWithHeader__table'>
            {table}
        </Segment>
    </Segment.Group>
)

export default TableWithHeader;

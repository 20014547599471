import React from 'react';
import { Header, Icon, Popup } from 'semantic-ui-react';
import { RuleContainer, ButtonLink } from 'dyl-components'

const Trigger = ({ 
    triggers=[],
    onToggle,
    isActive = false,
    hasDisabledModules = false
}) => {

    return (
        <RuleContainer
            isActive={isActive}
            content={
                triggers.length === 0 ? (
                    <>
                        <Header as='h4' >
                            Trigger
                        </Header>
                        <Header textAlign='center'>
                            <Header.Subheader textAlign='center' style={{ marginTop: 20 }}>                                    
                                <strong>No Trigger Added</strong>
                                <div>Add a Trigger by clicking on the + icon on the panel</div>
                            </Header.Subheader>
                        </Header>
                    </>
                ) : (
                    <>
                        <Header as='h4' >
                            Trigger
                            {hasDisabledModules && (
                                <Popup
                                    trigger={<Icon size='small' className='fas fa-circle-info' color='primary' style={{marginBottom: 3, marginLeft: 5}}/>}
                                    content="Assigment Rules are limited to 25 per module."
                                    inverted
                                />
                            )}
                        </Header>
                        {
                        triggers.map(trigger => (
                            trigger
                        ))}
                        <ButtonLink onClick={onToggle} status='error'>
                            <Icon link size='large'  className='fas fa-circle-minus' /> Remove
                        </ButtonLink>
                    </>
                )
            }
        />
    )
}

export default Trigger;


import React from 'react';
import { Grid } from 'semantic-ui-react';

const GridListHeaderCell = ({ children, ...otherProps }) => (
    <Grid.Column  {...otherProps} style={{ fontSize: 15, fontWeight: 700, color: '#000000' }}>
        {children}
    </Grid.Column>
);

export default GridListHeaderCell;



import React from 'react';
import { Segment } from 'semantic-ui-react'

import './index.scss'

const StyledInputWrapper = ({ children, size, className }) => (
    <Segment className={`StyledInputWrapper ${className ? className : ''}`} size={size}>
        {children}
    </Segment>
);

export default StyledInputWrapper;;

import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import './index.scss';

const ConfirmationPrompt = ({ header = 'Are you sure?', subheader = '', onCancel, onConfirm }) => {
    return (
        <div>
            <Header textAlign='center' style={{ marginBottom: '30px' }}>
                {header}
                <Header.Subheader>
                    {subheader}
                </Header.Subheader>
            </Header>
            <div className="ConfirmationPrompt__actions">
                <Icon
                    onClick={onCancel}
                    className='times'
                    color='red'
                    link
                    size='large'
                />
                <Icon
                    onClick={onConfirm}
                    className='check'
                    color='blue'
                    link
                    size='large'
                />
            </div>
        </div>
    );
}

export default ConfirmationPrompt;

import React from 'react';
import { Table, TableLoader, EmptyListPlaceholder } from 'dyl-components';
import { Segment, Header } from 'semantic-ui-react';
import TextTemplatesRow from './Row';

import './TextTemplateTable.scss';

const TextTemplateTable = ({
    templates,
    isSearchEmpty,
    isReading,
    count,
    onPreview,
    onEdit,
    onCopy,
    onDelete,
    onFavorite,

    onOpenAddTemplateModal
}) => {
    return (
        <div className='TextMessageLibraryPanel'>
            <Segment.Group vertical>
                <Segment style={{ padding: 0 }}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}></Table.HeaderCell>
                                <Table.HeaderCell width={3}>Message Template</Table.HeaderCell>
                                <Table.HeaderCell width={8}>Message Text</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Created</Table.HeaderCell>

                                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {isReading ? <TableLoader isLoading colspan={6} /> : (
                                templates.map((text_template) => (
                                    <TextTemplatesRow
                                        key={text_template.id}
                                        text_template={text_template}
                                        onPreview={onPreview}
                                        onEdit={onEdit}
                                        onCopy={onCopy}
                                        onDelete={onDelete}
                                        onFavorite={onFavorite} />
                                ))
                            )}
                        </Table.Body>
                    </Table>
                </Segment>

                {!isReading && count === 0 &&
                    <Segment>
                        <EmptyListPlaceholder name='Text Template' onClick={onOpenAddTemplateModal} />
                    </Segment>
                }
            </Segment.Group>
        </div>
    )
}

export default TextTemplateTable;

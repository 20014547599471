import React from 'react';
import ButtonLink from "dyl-components/atoms/ButtonLink";
import { useController, useFormContext } from "react-hook-form";
import { Dropdown, Form, Popup } from "semantic-ui-react";

export const NumberRangePowerSelector = ({
    label,
    name,
    isCurrency,
    min = 0,
}) => {
    const { control } = useFormContext();

    const { field: minField, fieldState: minFieldState } = useController({
        control,
        name: `filters[min_${name}]`,
    });

    const { field: maxField, fieldState: maxFieldState } = useController({
        control,
        name: `filters[max_${name}]`,
    });

    const onReset = () => {
        minField.onChange({ target: { name: minField.name, value: min } });
        maxField.onChange({ target: { name: maxField.name, value: "" } });
    };
    return (
        <Popup
            on="click"
            position="bottom left"
            trigger={<Dropdown style={{ marginBottom: "1em" }} value={null} text={label} button />}
            content={
                <Form noValidate onSubmit={() => {}}>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Min"
                            value={minField.value}
                            onChange={(_, { value }) => {
                                minField.onChange({
                                    target: { name: minField.name, value },
                                });
                            }}
                            {...(isCurrency
                                ? { icon: "fas fa-dollar-sign", iconPosition: "left" }
                                : {})}
                            type="number"
                            error={minFieldState?.error?.message}
                        />
                        <Form.Input
                            label="Max"
                            value={maxField.value}
                            onChange={(_, { value }) => {
                                maxField.onChange({
                                    target: { name: maxField.name, value },
                                });
                            }}
                            {...(isCurrency
                                ? { icon: "fas fa-dollar-sign", iconPosition: "left" }
                                : {})}
                            type="number"
                            error={maxFieldState?.error?.message}
                        />
                    </Form.Group>
                    <ButtonLink onClick={onReset}>Reset</ButtonLink>
                </Form>
            }
        />
    );
};

import React from 'react';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import './index.scss';

class CategorizedCheckboxDropdown extends React.Component {
    state = {
        open: false
    }

    onOpen = () => {
        this.setState({
            open: true
        });
    }

    onClose = () => {
        this.setState({
            open: false
        });
    }

    onUpdateItems = (e, itemValue) => {
        let selectedItems = this.props.value.slice(0);
        if (this.isSelected(itemValue)) {
            selectedItems.splice(this.getIndexOfItemToBeRemoved(selectedItems, itemValue), 1);
        } else {
            selectedItems.push(itemValue);
        }

        this.onChange(e, selectedItems);
    };

    onChange = (e, value) => {
        const callback = async () => {
            await this.props.onChange(e, {
                name: this.props.name, value
            });

            if (this.props.onRead) {
                this.props.onRead();
            }
        }

        if (this.props.onChange) {
            if (this.props.closeOnLoad) {
                this.setState({ open: false }, callback);
            } else {
                callback();
            }
        }
    }

    isSelected = itemValue => {
        return (
            this.props.value.findIndex(
                selectedItem => selectedItem === itemValue
            ) !== -1
        );
    };

    getIndexOfItemToBeRemoved = (selectedItems, itemValue) => {
        return selectedItems.findIndex(
            selectedItem => selectedItem === itemValue
        );
    }

    toggleAllOption = (e, index) => {
        const items = this.props.categories[index].options.filter(({ all }) => !all).map(({ value }) => value);
        if (this.isAllOptionsSelected(index)) {
            const newValue = this.props.value;
            items.forEach((item) => {
                let indexOfItemToBeRemoved = newValue.indexOf(item);
                newValue.splice(indexOfItemToBeRemoved, 1);
            });
            this.onChange(e, newValue);
        } else {
            this.onChange(e,  [...new Set([...this.props.value, ...items])]);
        }
    }

    isAllOptionsSelected = (index) => {
        const items = this.props.categories[index].options.filter(({ all }) => !all).map(({ value }) => value);
        let isAllOptionsSelected = true;

        items.forEach((item) => {
            if (!this.props.value.includes(item)) {
                isAllOptionsSelected = false;
            }
        });
        return isAllOptionsSelected;
    }

    render() {

        return (
            <Dropdown
                open={this.state.open}
                onOpen={this.onOpen}
                onClose={this.onClose}
                className='CheckboxDropdown'
                selection={this.props.selection}
                text={this.props.text}
                fluid={this.props.fluid || false}
                loading={this.props.loading}
                disabled={this.props.disabled}
            >
                <Dropdown.Menu className='CheckboxDropdown__menu'>
                    {this.props.categories.map(({ name, options }, index) => (
                        <React.Fragment>
                            <Dropdown.Header content={name} />
                            {options?.map(({ key, value, text, all }) => (
                                all ?
                                    <Dropdown.Item
                                        onClick={e => {
                                            this.toggleAllOption(e, index);
                                            e.stopPropagation();
                                        }}
                                        selected={this.isAllOptionsSelected(index)}
                                        key={key}
                                    >
                                        <Checkbox
                                            onMouseDown={e => { e.preventDefault() }}
                                            checked={this.isAllOptionsSelected(index)}
                                            label={text}
                                        />
                                    </Dropdown.Item>
                                    :
                                    <Dropdown.Item
                                        onClick={e => {
                                            this.onUpdateItems(e, value);
                                            e.stopPropagation();
                                        }}
                                        selected={this.isSelected(value)}
                                        key={key}
                                    >
                                        <Checkbox
                                            onMouseDown={e => { e.preventDefault() }}
                                            checked={this.isSelected(value)}
                                            label={text}
                                        />
                                    </Dropdown.Item>
                            ))}
                        </React.Fragment>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

        );
    }
}

CategorizedCheckboxDropdown.Header = ({ children }) => (
    <Dropdown.Header>{children}</Dropdown.Header>
)

CategorizedCheckboxDropdown.Item = class extends React.Component {
    state = {
        checked: false
    }

    handleChange() {
        this.setState({ checked: !this.state.checked });
    }

    render() {
        return (
            <Dropdown.Item onClick={this.handleChange.bind(this)}><Checkbox name={this.props.name} label={this.props.children || this.props.label} checked={this.state.checked} /></Dropdown.Item>
        )
    }
}

CategorizedCheckboxDropdown.defaultProps = {
    categories: [],
    value: []
}

export default CategorizedCheckboxDropdown;

import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { RuleContainer, ButtonLink } from 'dyl-components'

const SegmentCondition = ({ 
    segments=[],
    onToggle, 
}) => {
    return (
        <RuleContainer
            content={
                segments.length === 0 ? (
                    <>
                        <Header as='h4' >
                            Segments
                        </Header>
                        <Header textAlign='center'>
                            <Header.Subheader textAlign='center' style={{ marginTop: 20 }}>                                    
                                <strong>No Conditions Added</strong>
                                <div>Add a Condition by clicking on the + icon on the panel</div>
                            </Header.Subheader>
                        </Header>
                    </>
                ) : (
                    <>
                        <Header as='h4' >
                        Segments
                        </Header>
                        {
                        segments.map(segment => (
                            segment
                        ))}
                        <ButtonLink onClick={() => { onToggle }} status='primary'>
                            <Icon link size='large'  className='fas fa-circle-x' /> Clear All
                        </ButtonLink>
                        <ButtonLink onClick={() => { onToggle }} status='error'>
                            <Icon link size='large'  className='fas fa-circle-minus' /> Remove
                        </ButtonLink>
                    </>
                )
            }
        />
    )
}

export default SegmentCondition;


import React from 'react';
import { Grid, Segment, Button, Popup, Transition } from 'semantic-ui-react';

import './index.scss';

export const RecordProfile = ({
    header,
    details,
    tabs,
    rightHandContent,

    tabContent,

    isSidePanelOpen,
    onToggleSidePanel,
    isDetailsSectionOpen
}) => {
    return (
        <Grid>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Segment.Group>
                        <Segment padded>
                            <Popup
                                inverted
                                position='left center'
                                content={`${isSidePanelOpen ? 'Close' : 'Open'} side panel`}
                                trigger={(
                                    <Button
                                        size='mini'
                                        onClick={() => { onToggleSidePanel(!isSidePanelOpen) }}
                                        className={'RecordProfile__toggler'}
                                        circular
                                        primary
                                        icon={`fas fa-chevron-${isSidePanelOpen ? 'right' : 'left'}`}
                                    />
                                )}
                            />
                            {header}
                        </Segment>
                        <Transition duration='100' visible={isDetailsSectionOpen} unmountOnHide animation='slide down'>
                            <Segment>
                                {details}
                            </Segment>
                        </Transition>
                    </Segment.Group>
                    <Segment>
                        {tabs}
                        <Segment basic>
                            {tabContent}
                        </Segment>
                    </Segment>
                </Grid.Column>
                <Transition duration='100' visible={isSidePanelOpen} unmountOnHide animation='slide left'>
                    <Grid.Column width={3}>
                        {rightHandContent}
                    </Grid.Column>
                </Transition>
            </Grid.Row>
        </Grid>
    )
}

import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { RuleContainer, ButtonLink } from 'dyl-components'

const Routing = ({ 
    routings=[],
    onToggle, 
}) => {
    return (
        <RuleContainer
            content={
                routings.length === 0 ? (
                    <>
                        <Header as='h4' >
                            Routing
                        </Header>
                        <Header textAlign='center'>
                            <Header.Subheader textAlign='center' style={{ marginTop: 20 }}>                                    
                                <strong>No Assignments Added</strong>
                                <div>Add an Assignment by clicking on the + icon on the panel</div>
                            </Header.Subheader>
                        </Header>
                    </>
                ) : (
                    <>
                        <Header as='h4' >
                        Routing
                        </Header>
                        {
                        routings.map(routing => (
                            routing
                        ))}
                    </>
                )
            }
        />
    )
}

export default Routing;


import React from 'react';
import { Checkbox, Dimmer, Grid, Header, Icon, Loader, Popup } from 'semantic-ui-react';

const ColumnSelector = ({ columnRows, columnCount, isLoading, isColumnHidden, onToggleColumn }) => {
    return (
        <Popup
            trigger={<Icon size='large' className='fas fa-table' link />}
            wide='very'
            on='click'
            content={(
                <React.Fragment>
                    <Header>
                        Columns
                    </Header>
                    <Grid style={{ height: '200px', overflow: 'auto' }} columns={columnCount}>
                        {columnRows.map(columnRow => (
                            <Grid.Row>
                                {columnRow.map(column => {
                                    return (
                                        <Grid.Column>
                                            <Checkbox
                                                label={column.label}
                                                checked={Boolean(!isColumnHidden(column.value) || column.isForcedChecked)}
                                                onChange={() => onToggleColumn(column.value)}
                                                disabled={column.isForcedChecked}
                                            />
                                        </Grid.Column>
                                    )
                                })}
                            </Grid.Row>
                        ))}
                    </Grid>
                    <Dimmer active={isLoading}>
                        <Loader active={isLoading} />
                    </Dimmer>
                </React.Fragment>
            )}
            position='bottom right'
        />
    );
}

export default ColumnSelector;

import React, { Component } from 'react';
import { Grid, List, Divider, Popup } from 'semantic-ui-react';
import Avatar from 'react-avatar';

import './index.scss';

const Group = ({
    groups,
    channelInfo,
    onSelectGroupDM,
    currentChannel,
    avatarColors
}) => (
        <Grid.Column>
            <Divider inverted className='Group__Divider' />
            <List className="Chat__GroupList">
                {groups !== undefined &&

                    groups.map((group, i) => {
                        let userlist = (group.map((user) => user.name)).join(', ')
                        return (
                            <List.Item key={i} >
                                <List.Content className={'Chat__ChannelName'}
                                    onClick={() => { onSelectGroupDM(`GDM-${i}`) }}
                                >
                                    <Avatar
                                        color={avatarColors[(i+1)]}
                                        value='GP'
                                        round='3px'
                                        size={32}
                                        style={{ marginRight: -5 }}
                                    />
                                    <span className="group_number">{group.length}</span>
                                    <Popup
                                        key={i}
                                        trigger={<span className={(currentChannel === `GDM-${i}` ? 'active' : null)}>{userlist}</span>}
                                        content={userlist}
                                        position='top left'
                                        pinned
                                        inverted
                                    />
                                </List.Content>
                            </List.Item>
                        )
                    })
                }
            </List>
        </Grid.Column>
    );
export default class Groups extends Component {

    onSelectGroupDM = (id) => {
        console.log(`|+| Clickon the GroupDM for id`, id);
        this.props.channelInfo.toggleChannel(id);
    }

    render() {
        return (
            <Group
                groups={this.props.groups}
                channelInfo={this.props.channelInfo}
                onSelectGroupDM={this.onSelectGroupDM}
                currentChannel={this.props.channelInfo.currentChannelId}
                avatarColors={this.props.avatarColors}
            />
        )
    }
}

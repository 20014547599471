import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import './index.scss';

export const ClippedContent = ({ children, popup = true, popupContent, onClose = () => { }, position = 'top left' }) => {
    const ref = useRef(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    useEffect(() => {
        setIsEllipsisActive(ref.current?.clientWidth < ref.current?.scrollWidth);
    }, [ref]);

    return (
        popup && isEllipsisActive ? (
            <Popup
                trigger={<div className='ClippedContent' ref={ref}>{children}</div>}
                content={popupContent || children}
                inverted
                on='hover'
                onClose={onClose}
                position={position}
            />
        ) : <div className='ClippedContent' ref={ref}>{children}</div>
    );
}
import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

export const Jumbotron = ({children, mobile}) => (
    <Segment
        textAlign='center'
        vertical
        className={`Jumbotron ${mobile ? 'Jumbotron--mobile' : ''}`}
        inverted
    >
        {children}
    </Segment>
)

import React  from 'react';
import { Popup, Icon, Form, Button, Dropdown } from 'semantic-ui-react';


import './addChannel.scss';


const ChannelForm = (props) => {
    const submitCreateRoom = (e) => {
        e.preventDefault();
        console.log(`|+|+|+|hit endpoint to create room `, e)
        props.close();
    };
    return (
        <div className='AddChannelForm'>
            <span className='AddChannelHeader'>Create a new channel</span>
            <Icon link
                name="close"
                onClick={props.close}
                className='CloseAddChannel' />

            <Form onSubmit={submitCreateRoom}>
                <Form.Field
                    name='name'
                    placeholder='Please enter chat name'
                    label='Chat Room'
                    control='input' />
                <Form.Field
                    label="Description"
                    placeholder='Please enter a description (optional)'
                    control='input' />

                <Form.Field>
                    <label className='DirectMessageLabel'>Invite Users</label>
                    <Dropdown
                        label="Testing "
                        placeholder='Users'
                        fluid
                        multiple
                        search
                        selection
                        options={[{ "key": 1, "value": "korgel0", "text": "Konstantin Orgel" },
                        { "key": 2, "value": "lslinn1", "text": "Lazar Slinn" },
                        { "key": 3, "value": "sfrancecione2", "text": "Sasha Francecione" },
                        { "key": 4, "value": "alourens3", "text": "Aidan Lourens" },
                        { "key": 5, "value": "sgoldberg4", "text": "Sibley Goldberg" },
                        { "key": 6, "value": "eguslon5", "text": "Essie Guslon" },
                        { "key": 7, "value": "kjosuweit6", "text": "Klara Josuweit" },
                        { "key": 8, "value": "acomberbach7", "text": "Ara Comberbach" },
                        { "key": 9, "value": "sridwood8", "text": "Sonja Ridwood" },
                        { "key": 10, "value": "mdoel9", "text": "Miriam Doel" },
                        { "key": 11, "value": "fdurrama", "text": "Florinda Durram" },
                        { "key": 12, "value": "pwiggallb", "text": "Petrina Wiggall" },
                        { "key": 13, "value": "lgerrellc", "text": "Luciano Gerrell" },
                        { "key": 14, "value": "lcooringtond", "text": "Lorine Coorington" },
                        { "key": 15, "value": "tfaradaye", "text": "Teresa Faraday" },
                        { "key": 16, "value": "nblakestonf", "text": "Nikolai Blakeston" },
                        { "key": 17, "value": "cdutsong", "text": "Corey Dutson" },
                        { "key": 18, "value": "cpersseh", "text": "Carey Persse" },
                        { "key": 19, "value": "upimletti", "text": "Ursula Pimlett" },
                        { "key": 20, "value": "csimsonj", "text": "Cal Simson" },
                        { "key": 21, "value": "veadesk", "text": "Vicki Eades" },
                        { "key": 22, "value": "mworthyl", "text": "Mira Worthy" },
                        { "key": 23, "value": "gcastelluzzim", "text": "Gerladina Castelluzzi" },
                        { "key": 24, "value": "inutleyn", "text": "Irina Nutley" },
                        { "key": 25, "value": "tmaccomeo", "text": "Tremayne MacCome" },
                        { "key": 26, "value": "ibradep", "text": "Iago Brade" },
                        { "key": 27, "value": "cdustingq", "text": "Catha Dusting" },
                        { "key": 28, "value": "gpietronir", "text": "Ginnifer Pietroni" },
                        { "key": 29, "value": "cnannettis", "text": "Cherilyn Nannetti" },
                        { "key": 30, "value": "mworshamt", "text": "Martita Worsham" },
                        { "key": 31, "value": "ddrynanu", "text": "Donnajean Drynan" },
                        { "key": 32, "value": "bmatchesv", "text": "Bond Matches" },
                        { "key": 33, "value": "lmanghamw", "text": "Lane Mangham" },
                        { "key": 34, "value": "tbroadburyx", "text": "Terrye Broadbury" },
                        { "key": 35, "value": "ldymockey", "text": "Law Dymocke" },
                        { "key": 36, "value": "rmadgwickz", "text": "Robby Madgwick" },
                        { "key": 37, "value": "hhurcombe10", "text": "Honey Hurcombe" },
                        { "key": 38, "value": "odybald11", "text": "Oralla Dybald" },
                        { "key": 39, "value": "ade12", "text": "Allis De Hoogh" },
                        { "key": 40, "value": "cguiu13", "text": "Clemence Guiu" },
                        { "key": 41, "value": "tyankin14", "text": "Tori Yankin" },
                        { "key": 42, "value": "cgwyneth15", "text": "Conni Gwyneth" },
                        { "key": 43, "value": "rmoulster16", "text": "Reggis Moulster" },
                        { "key": 44, "value": "nbannell17", "text": "Nissie Bannell" },
                        { "key": 45, "value": "lmccleary18", "text": "Laurena McCleary" },
                        { "key": 46, "value": "merbain19", "text": "Mehetabel Erbain" },
                        { "key": 47, "value": "rvatcher1a", "text": "Rogers Vatcher" },
                        { "key": 48, "value": "jvan1b", "text": "Jana Van der Velden" },
                        { "key": 49, "value": "laronstein1c", "text": "Laurie Aronstein" },
                        { "key": 50, "value": "witzik1d", "text": "Waylin Itzik" }]}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Checkbox className='AddChannelForm__private-chat' inline label='Make Chat Private' toggle />
                </Form.Field>
                <Button type='submit'>Create</Button>
            </Form>
        </div>
    )
};

const AddedChannelPopup = ({options}) => {
 

    return (
        <span>
                <Popup style={{position: 'absolute', left: '100px', top: '120px', transform: 'none'}}
                basic
                className="tempPopup"
                trigger={<Icon className='AddChannelIcon'  name='plus circle' onClick={options.closePopup} />}
                on='click'
                content={<ChannelForm close={options.closePopup} />}
                open={options.isOpen}
                position='bottom left'
                size='large'
            />
        </span>
    )
};


export default class AddChannel extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isOpen : false,
            closePopup: this.closePopup
        }
    }
    closePopup = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    render() {

        return (
            <AddedChannelPopup options={this.state}/>
        )
    }
}

import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const AccountSettingsPanel = ({ header, content }) => (
    <TableWithHeader 
        header={header}
        table={(
            <Segment className="AccountSettingsPanel">
                {content}
            </Segment>
        )}
    />
)

export default AccountSettingsPanel;

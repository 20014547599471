import React from 'react';
import { Table, EllipsisDropdown, ControlledPopup } from 'dyl-components';
import { Dropdown, Icon } from 'semantic-ui-react';

const Row = ({ text_template, onPreview, onEdit, onCopy, onDelete, onFavorite }) => {

    const ELLIPSIS_OPTIONS = [
        { text: 'Preview', icon: 'fas fa-eye', onClick: () => { onPreview(text_template) } },
        { text: 'Edit', icon: 'fas fa-pencil', onClick: () => { onEdit(text_template) } },
        { text: 'Duplicate', icon: 'fas fa-copy', onClick: () => { onCopy(text_template) } },
        { text: 'Delete', icon: 'fas fa-trash-alt', generatePopup: true, popupProps: { header: 'Are you sure?', onClick: () => { onDelete(text_template.id) } } }
    ];

    const EllipsisOption = ({ icon, text, generatePopup, popupProps, otherProps }) => {
        if (generatePopup === true) {
            return (
                <ControlledPopup
                    header={popupProps.header}
                    trigger={
                        <Dropdown.Item text={<React.Fragment>
                            <Icon name={icon} />
                            {text}
                        </React.Fragment>}
                            style={{ cursor: 'pointer' }}
                            {...otherProps}
                        />
                    }
                    onConfirm={popupProps.onClick}
                />
            );
        } else {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }
    }

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell width={1}>
                    <Icon link name={`star${text_template.isFavorite ? '' : ' outline'}`} color='blue' onClick={() => { onFavorite(text_template.id) }} />
                </Table.Cell>
                <Table.Cell width={3}>
                    {text_template.name}
                </Table.Cell>
                <Table.Cell width={8}>
                    <div style={{ display: 'flex' }}>
                        <div className='TextTemplate__text'>
                            {text_template.text}
                        </div>
                        {text_template.has_attachments &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon name='fas fa-file-image' size='large' />
                            </div>
                        }
                    </div>
                </Table.Cell>
                <Table.Cell width={3}>
                    {text_template.created}
                </Table.Cell>
                <Table.Cell width={1}>
                    <EllipsisDropdown>
                        {ELLIPSIS_OPTIONS.map(({ text, icon, generatePopup, popupProps, ...otherProps }) => {
                            return (
                                <EllipsisOption
                                    text={text}
                                    icon={icon}
                                    generatePopup={generatePopup}
                                    popupProps={popupProps}
                                    otherProps={otherProps}
                                />
                            );
                        })}
                    </EllipsisDropdown>
                </Table.Cell>
            </Table.Row>
        </React.Fragment>

    )
}

export default Row;

import React from 'react';
import { Header, Grid } from 'semantic-ui-react';

import { ToggleButton } from 'dyl-components';

import './ToggleCategory.scss';

const ToggleCategory = ({ header = '', selections, handleClick }) => (
    <React.Fragment>
        {header !== '' && (<Header as='h5' textAlign='center'>{header}</Header>)}
        <Grid className='ToggleCategory' columns={3} centered={true}>
            {selections.map(selection => (
                <Grid.Column textAlign='center'>
                    <ToggleButton
                        className='ToggleCategory__ToggleButton'
                        icon={selection.icon}
                        text={selection.text}
                        active={selection.active}
                        value={selection.key}
                        handleClick={handleClick}
                    />
                </Grid.Column>
            ))}
        </Grid>
    </React.Fragment>
)

export default ToggleCategory;

import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { DividingHeader, MultipleField } from 'dyl-components';

const AddContactForm = ({
    first_name,
    last_name,

    industry,
    industry_options,

    department,
    birthdayField,
    onChange,

    phones,
    emails,
    onChangeItem,
    onToggleMainItem,
    onRemoveItem,
    onAddItem,

    websites,

    addressForm,

    current_customer,
    add_to_hotlist,

    labelsField,
    accountFieldComponent
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                name="first_name"
                value={first_name}
                onChange={onChange}
                label="First Name"
                placeholder="Enter first name"
            />
            <Form.Input
                name="last_name"
                value={last_name}
                onChange={onChange}
                label="Last Name"
                placeholder="Enter last name"
            />
            {accountFieldComponent}
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Select
                name="industry"
                value={industry}
                onChange={onChange}
                label="Industry"
                selectOnBlur={false}
                options={industry_options}
                placeholder="Select industry"
            />
            <Form.Input
                name="department"
                value={department}
                onChange={onChange}
                label="Department"
                placeholder="Enter department"
            />
            {birthdayField}
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Field
                control={MultipleField}
                items={phones}
                name="phones"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add phone"
                hasMain
                label="Phone"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={emails}
                name="emails"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add email"
                hasMain
                label="Email"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={websites}
                name="websites"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add website"
                label="Website"
                fluid
            />
        </Form.Group>
        <DividingHeader padded content="Address" />
        {addressForm}
        <Form.Group widths="equal">
            <Form.Field>
                <label>Current Customer</label>
                <Checkbox
                    name="current_customer"
                    checked={current_customer}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            <Form.Field>
                <label>Add to Hotlist</label>
                <Checkbox
                    control
                    name="add_to_hotlist"
                    checked={add_to_hotlist}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            {labelsField}
        </Form.Group>
    </React.Fragment>
);

export default AddContactForm;

import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const TableWithTools = ({
    pagination,
    toolbar,
    table
}) => (
    <Segment.Group className='TableWithTools'>
        <Segment className='TableWithTools__pagination'>
            {pagination}
        </Segment>
        <Segment className='TableWithTools__toolbar'>
            {toolbar}
        </Segment>
        <Segment className='TableWithTools__table'>
            {table}
        </Segment>
    </Segment.Group>
)

export default TableWithTools;

import React, { useState } from 'react';
import { Button, Grid, Icon, Dropdown, Form } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { generateResolver, yup } from 'dyl-components/atoms/ValidationUtils';

import './index.scss';
import { ClippedContent } from 'dyl-components/atoms/ClippedContent';

const validator = yup.string().minlength(2).maxlength(64).simple_alphanumeric_w_dividers().no_excessive_whitespaces().no_whitespace_only();

const Category = ({
    isEditingCategory,
    category,
    onEditCategoryName,

    areCategoriesEditable,
    categoriesFieldName,
    index,

    onChangeActiveCategory,
    isActiveCategory,
    control,
    removeCategory,

    duplicateCheck,
    categories
}) => {
    const { formState: { isDirty: isEditCategoryFormDirty, isValid: isEditCategoryFormValid }, control: editCategoryControl, reset: categoryEditReset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: category.name
        },
        resolver: generateResolver({
            name: validator.test('no_repeating_category', 'Parent value already in the list', duplicateCheck('name', categories, async (value) => {
                return value === '' || value === category.name || !(await (validator.isValid(value)))
            }))
        })
    });
    return (
        <Controller
            name={`${categoriesFieldName}[${index}].name`}
            control={control}
            render={({ field: { name, value, onChange: onConfirmEditCategoryName } }) => (
                <Controller
                    name='name'
                    control={editCategoryControl}
                    defaultValue={value}
                    render={({ field: { name: categoryEditFieldName, value: categoryEditFieldValue, onChange: categoryEditOnChange }, fieldState: { error } }) => (
                        <Dropdown.Item selected={isActiveCategory} onClick={(e) => { e.stopPropagation(); onChangeActiveCategory(category.id); }}>
                            <Grid columns='equal' verticalAlign='middle'>
                                <Grid.Column>
                                    {isEditingCategory ? (
                                        <Form.Input
                                            value={categoryEditFieldValue}
                                            onChange={(_, { value }) => { categoryEditOnChange({ target: { name: categoryEditFieldName, value } }) }}
                                            onClick={(e) => { e.stopPropagation(); }}
                                            error={error?.message}
                                            onKeyDown={e => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    ) : (
                                        <ClippedContent>
                                            {value}
                                        </ClippedContent>
                                    )}
                                </Grid.Column>
                                {areCategoriesEditable && (
                                    <Grid.Column textAlign='right'>
                                        {!isEditingCategory && <Icon onClick={(e) => { e.stopPropagation(); onEditCategoryName(category.id); }} className='fas fa-pencil EditableCategorizedOptions__edit-control' link color='blue' />}
                                        {isEditingCategory && (
                                            <React.Fragment>
                                                <Icon onClick={(e) => { e.stopPropagation(); categoryEditReset({ name: value }); onEditCategoryName(null) }} color='red' className='fas fa-times EditableCategorizedOptions__edit-control' link />
                                                <Icon disabled={!isEditCategoryFormDirty || !isEditCategoryFormValid} onClick={(e) => {
                                                    e.stopPropagation();
                                                    onConfirmEditCategoryName({ target: { name, value: categoryEditFieldValue } });
                                                    categoryEditReset({ name: categoryEditFieldValue });
                                                    onEditCategoryName(null);
                                                }} color='blue' className='fas fa-check EditableCategorizedOptions__edit-control' link />
                                            </React.Fragment>
                                        )}
                                        {!isEditingCategory && <Icon onClick={(e) => {
                                            e.stopPropagation();
                                            removeCategory(index);
                                            if (isActiveCategory) {
                                                onChangeActiveCategory(null)
                                            }
                                        }} color='red' className='fas fa-trash EditableCategorizedOptions__edit-control' link />}
                                    </Grid.Column>
                                )}
                                {!isEditingCategory && (
                                    <Grid.Column width={1}>
                                        <Icon name='dropdown' />
                                    </Grid.Column>
                                )}
                            </Grid>
                        </Dropdown.Item>
                    )}
                />
            )}
        />
    )
}

const CategoryOption = ({
    isEditingOption,
    option,
    onEditOptionName,
    options,
    name,

    onChangeOptions,
    duplicateCheck
}) => {
    const { formState: { isDirty: isEditOptionFormDirty, isValid: isEditOptionFormValid }, control: editOptionControl, reset: optionEditReset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: option.name
        },
        resolver: generateResolver({
            name: validator.test('no_repeating_option', 'Child value already in the list', duplicateCheck('name', options || [], async (value) => {
                return value === '' || value === option.name || !(await (validator.isValid(value)))
            }))
        })
    });
    return (
        <Controller
            name='name'
            control={editOptionControl}
            defaultValue={option.name}
            render={({ field: { name: optionEditFieldName, value: optionEditFieldValue, onChange: optionEditOnChange }, fieldState: { error } }) => (
                <Dropdown.Item key={option.id} onClick={(e) => { e.stopPropagation() }}>
                    <Grid columns='equal' verticalAlign='middle'>
                        <Grid.Column>
                            {isEditingOption ? (
                                <Form.Input
                                    value={optionEditFieldValue}
                                    onChange={(_, { value }) => { optionEditOnChange({ target: { name: optionEditFieldName, value } }) }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                    onKeyDown={e => {
                                        e.stopPropagation();
                                    }}
                                    error={error?.message}
                                />
                            ) : (
                                <ClippedContent>
                                    {option.name}
                                </ClippedContent>
                            )}
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            {!isEditingOption && <Icon link onClick={() => { onEditOptionName(option.id); }} color='blue' className='fas fa-pencil EditableCategorizedOptions__edit-control' />}
                            {isEditingOption && (
                                <React.Fragment>
                                    <Icon onClick={(e) => { e.stopPropagation(); optionEditReset({ name: option.name }); onEditOptionName(null); }} color='red' className='fas fa-times EditableCategorizedOptions__edit-control' link />
                                    <Icon disabled={!isEditOptionFormDirty || !isEditOptionFormValid} onClick={(e) => {
                                        e.stopPropagation();
                                        const updatedOptions = options.slice(0);
                                        const indexOfOptionToUpdate = updatedOptions.findIndex(updatedOption => updatedOption.id === option.id);
                                        if (indexOfOptionToUpdate !== -1) {
                                            updatedOptions[indexOfOptionToUpdate] = { id: option.id, name: optionEditFieldValue }
                                            onChangeOptions({ target: { name, value: updatedOptions } });
                                            optionEditReset({ name: optionEditFieldValue });
                                            onEditOptionName(null);
                                        }
                                    }} color='blue' className='fas fa-check EditableCategorizedOptions__edit-control' link />
                                </React.Fragment>
                            )}
                            {!isEditingOption && (
                                <Icon disabled={isEditingOption} onClick={() => {
                                    onChangeOptions({ target: { name, value: options.filter((updatedOption) => updatedOption.id !== option.id) } });
                                }} color='red' className='fas fa-trash EditableCategorizedOptions__edit-control' />
                            )}
                        </Grid.Column>
                    </Grid>
                </Dropdown.Item>
            )}
        />
    )
}

const EditableCategorizedOptions = ({
    className,

    areCategoriesEditable,

    control,
    categoriesFieldName,

    categoriesToEdit,
    addCategory,
    removeCategory,
    error
}) => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [categoryBeingEdited, setCategoryBeingEdited] = useState(null);
    const [optionBeingEdited, setOptionBeingEdited] = useState(null);

    const onChangeActiveCategory = (id) => {
        if (!categoryBeingEdited && !optionBeingEdited) {
            setActiveCategory(id);
        }
    }

    const isActiveCategory = id => activeCategory === id;

    const onEditCategoryName = (id) => {
        setCategoryBeingEdited(id);
        setActiveCategory(null);
    }

    const duplicateCheck = (property, list, checkIfExempted) => async function (value) {
        const uniqueData = Array.from(
            new Set(([...list, { [property]: value }]).map(item => item[property].toLowerCase()))
        );
        const isUnique = list.length + 1 === uniqueData.length;
        if (isUnique) {
            return true;
        }
        if (checkIfExempted && await checkIfExempted(value)) {
            return true;
        }
        return this.createError({
            path: property,
            message: 'Already in the list'
        });
    }

    const { formState: { isDirty: isAddCategoryFormDirty, isValid: isAddCategoryFormValid }, control: addCategoryControl, reset: addCategoryReset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: ''
        },
        resolver: generateResolver({
            name: validator.test('no_new_repeating_category', 'Parent value already in the list', duplicateCheck('name', categoriesToEdit, async (value) => {
                return value === '' || !(await (validator.isValid(value)))
            }))
        })
    });

    const onAddCategory = (value) => {
        addCategory({ id: Math.random(), name: value, options: [], new: true });
        addCategoryReset({ name: '' });
    }

    const activeCategoryIndex = categoriesToEdit.findIndex(category => isActiveCategory(category.id));

    const onEditOptionName = (id) => {
        setOptionBeingEdited(id);
    }

    const { formState: { isDirty: isAddOptionFormDirty, isValid: isAddOptionFormValid }, control: addOptionControl, reset: addOptionReset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: ''
        },
        resolver: generateResolver({
            name: validator.test('no_new_repeating_option', 'Child value already in the list', duplicateCheck('name', categoriesToEdit[activeCategoryIndex]?.options || [], async (value) => {
                return value === '' || !(await (validator.isValid(value)))
            }))
        })
    });

    return (
        <Form.Group widths={'equal'}>
            <Form.Dropdown {...(className && { className })} error={error} {...(activeCategory ? { text: categoriesToEdit.find(category => category.id === activeCategory).name, open: true } : {})} placeholder='Enter parent values' selection fluid label='Parent Values'>
                <Dropdown.Menu>
                    {areCategoriesEditable && (
                        <Dropdown.Item onClick={(e) => { e.stopPropagation(); }}>
                            <Controller
                                name='name'
                                control={addCategoryControl}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        placeholder="Enter name"
                                        basic
                                        icon={<Button disabled={!isAddCategoryFormValid || !isAddCategoryFormDirty} icon={<Icon color='blue' className='fas fa-plus-circle' />} onClick={() => {
                                            onAddCategory(value);
                                        }} basic />}
                                        iconPosition='right'
                                        value={value}
                                        error={error?.message}
                                        disabled={categoryBeingEdited || optionBeingEdited}
                                        onKeyDown={e => {
                                            e.stopPropagation();
                                        }}
                                    />
                                )}
                            />
                        </Dropdown.Item>
                    )}
                    {categoriesToEdit.map((category, index) => (
                        <Category
                            areCategoriesEditable={areCategoriesEditable}
                            categoriesFieldName={categoriesFieldName}
                            category={category}
                            index={index}
                            isActiveCategory={isActiveCategory(category.id)}
                            isEditingCategory={categoryBeingEdited === category.id}
                            onChangeActiveCategory={onChangeActiveCategory}
                            onEditCategoryName={onEditCategoryName}
                            control={control}
                            removeCategory={removeCategory}
                            key={category.id}
                            duplicateCheck={duplicateCheck}
                            categories={categoriesToEdit}
                        />
                    ))}
                </Dropdown.Menu>
            </Form.Dropdown>
            <Form.Dropdown onClose={() => {setActiveCategory(null)}} open={Boolean(activeCategory)} {...(className && { className })} label='Child Values' placeholder='Enter child values' selection fluid disabled={activeCategory === null}>
                <Dropdown.Menu>
                    <Controller
                        name={`${categoriesFieldName}[${activeCategoryIndex}].options`}
                        control={control}
                        render={({ field: { name, onChange: onChangeOptions } }) => {
                            const options = categoriesToEdit[activeCategoryIndex]?.options || [];
                            return (
                                [
                                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); }}>
                                        <Controller
                                            name='name'
                                            control={addOptionControl}
                                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                                <Form.Input
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                    placeholder="Enter name"
                                                    basic
                                                    icon={<Button disabled={!isAddOptionFormValid || !isAddOptionFormDirty} icon={<Icon color='blue' className='fas fa-plus-circle' />} onClick={() => {
                                                        onChangeOptions({ target: { name, value: [...options, { id: Math.random(), name: value, new: true }] } });
                                                        addOptionReset({ name: '' });
                                                    }} basic />}
                                                    iconPosition='right'
                                                    value={value}
                                                    error={error?.message}
                                                    disabled={categoryBeingEdited || optionBeingEdited}
                                                    onKeyDown={e => {
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            )}
                                        />
                                    </Dropdown.Item>,
                                    ...(options.map((option => (
                                        <CategoryOption
                                            isEditingOption={optionBeingEdited === option.id}
                                            name={name}
                                            onChangeOptions={onChangeOptions}
                                            onEditOptionName={onEditOptionName}
                                            option={option}
                                            options={options}
                                            key={option.id}
                                            duplicateCheck={duplicateCheck}
                                        />
                                    ))))
                                ]
                            )
                        }}
                    />
                </Dropdown.Menu>
            </Form.Dropdown>
        </Form.Group>
    )
}

export default EditableCategorizedOptions;

import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from '@fullcalendar/multimonth'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment';
import { Icon, Header, Popup, Menu } from 'semantic-ui-react'
import { ClippedContent } from 'dyl-components/atoms/ClippedContent';
import "./index.scss"
import { DateTimeUtils } from "dyl-components";

const CalendarGridView = ({calendarRef, goToDay, viewMode, events, setAddActivityAction, onEventClick, resetEventFormValues, resetTaskFormValues}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [showAllDay, setShowAllDay] = useState(false);
    const [showExpandAllDay, setShowExpandAllDay] = useState(false);
    const [isAllDayOn, setIsAllDayOn] = useState(false);

    useEffect(() => {
        if(viewMode === "day" || viewMode === "week") {
            const lines = document.getElementsByClassName("fc-timegrid-now-indicator-line");
            if (lines.length > 0) {
                const nowIndicator = lines[0];
                const circle = document.createElement("div");
                circle.classList.add("circle");
                nowIndicator.appendChild(circle);
            }
        }
    })

    useEffect(() => {
        setDisplayEvents(events);
    }, [events])

    useEffect(() => {
        const isWeekOrDayView = (viewMode === "day" || viewMode === "week")
        const allDayFound = displayEvents.find((displayEvent) => displayEvent.allDay)
        let tooManyAllDay = false;
        if (allDayFound && isWeekOrDayView) {
            let counts = {}
            displayEvents.
                filter(e => e.allDay)
                .every(e => {
                    counts[e.date] = (counts[e.date] || 0) + 1;
                    if (counts[e.date] > 2) {
                        tooManyAllDay = true; // more than 2 allDay events on a single day
                        return false;
                    }
                return true;
            });

        }
        setShowExpandAllDay(tooManyAllDay);
        setShowAllDay(isWeekOrDayView && allDayFound);
    }, [displayEvents])

    const getViewClass = () => {
        const views = {
            day: "DayView",
            week: "WeekView",
            month: "MonthView",
            year: "YearView",
        }
        return views[viewMode];
    }

    const getIcon = (type) => {
        switch (type) {
            case "task":
                return "fa-solid fa-clipboard-check"
            case "event":
            case "unconfirmedEvent":
                return "fa-solid fa-calendar-day"
            case "sequence":
                return "fa-sharp fa-solid fa-sitemap"
        }
        return "";
    }

    const addPlaceholderEvent = (info) => {
        let eventsCopy = [{
            title: "New Activity",
            date: info.date,
            extendedProps: {
                blueBox: true
            }
        }, ...events]
        setDisplayEvents(eventsCopy);
    }

    const removePlaceholderEvent = () => {
        setDisplayEvents(events);
    }

    const generateEventContent = (arg) => {
        let { timeText } = arg;
        timeText = timeText.replace(/pm/g, " pm");
        timeText = timeText.replace(/am/g, " am");
        const { title, extendedProps } = arg.event?._def;
        const { start: date } = arg.event?._instance?.range;
        return extendedProps.blueBox ? (
            <Popup
                basic
                size="mini"
                open={isPopupOpen}
                on="click"
                onOpen={() => {}}
                onClose={() => removePlaceholderEvent()}
                style={{padding: 0}}
                trigger={
                    <div className="EventBlock__newActivity">
                        <Header as={"h5"}>{title}</Header>
                    </div>
                }
                position="bottom center"
                content={
                    <Menu secondary vertical className="Calendar__menu">
                         <Menu.Item 
                            className="Calendar__popupItem"
                            onClick={() => {
                                setAddActivityAction("task");
                                setIsPopupOpen(false);
                                removePlaceholderEvent();
                                resetTaskFormValues({date})
                            }}
                        >
                            <Icon className="fa-solid fa-clipboard-check Calendar__popupIcon"/>
                            Task
                        </Menu.Item>
                        <Menu.Item
                            className="Calendar__popupItem"
                            onClick={() => {
                                setAddActivityAction("event");
                                setIsPopupOpen(false);
                                removePlaceholderEvent();
                                resetEventFormValues({date})
                            }}
                        >
                            <Icon className="fa-solid fa-calendar-day Calendar__popupIcon"/>
                            Event
                        </Menu.Item>
                    </Menu>
                }
            />
        ) : (
            <div className="EventBlock">
                    <div className="EventBlock__headerContainer">
                        <Icon className={`EventBlock__icon ${getIcon(extendedProps.activity_type)}`}/>
                        <ClippedContent>
                            <Header as={"h5"} className="EventBlock__header">
                                { extendedProps.activity_type === "task" ? `${extendedProps.task_type}: ${title}` : title }
                            </Header>
                        </ClippedContent>
                    </div>
                    <ClippedContent>
                        {extendedProps.activity_type !== "task" && <span>{timeText}</span>}
                    </ClippedContent>
            </div>
        )
    }

    const generateMonthContent = (arg) => {
        const { title, extendedProps, allDay } = arg.event?._def;
        const { start: date } = arg.event?._instance?.range;
        return extendedProps.blueBox ? (
            <Popup
                basic
                size="mini"
                open={isPopupOpen}
                on="click"
                onOpen={() => {}}
                onClose={() => removePlaceholderEvent()}
                style={{padding: 0}}
                trigger={
                    <div className="EventBlock__newMonthActivity">
                        <Header as={"h5"}>{title}</Header>
                    </div>
                }
                position="bottom center"
                content={
                    <Menu secondary vertical className="Calendar__menu">
                         <Menu.Item
                            className="Calendar__popupItem"
                            onClick={() => {
                                setAddActivityAction("task");
                                setIsPopupOpen(false);
                                removePlaceholderEvent();
                                resetTaskFormValues({date, isMonth: true})
                            }}
                        >
                            <Icon className="fa-solid fa-clipboard-check Calendar__popupIcon"/>
                            Task
                        </Menu.Item>
                        <Menu.Item
                            className="Calendar__popupItem"
                            onClick={() => {
                                setAddActivityAction("event");
                                setIsPopupOpen(false);
                                removePlaceholderEvent();
                                resetEventFormValues({date, isMonth: true})
                            }}
                        >
                            <Icon className="fa-solid fa-calendar-day Calendar__popupIcon"/>
                            Event
                        </Menu.Item>
                    </Menu>
                }
            />
        ) : (
            allDay ? (
                <div className="EventBlock__month">
                        <div className="EventBlock__headerContainer">
                            <Icon className={`EventBlock__icon ${getIcon(extendedProps.activity_type)}`}/>
                            <ClippedContent>
                                <Header as={"h6"} className="EventBlock__header">
                                    { extendedProps.activity_type === "task" ? `${extendedProps.task_type}: ${title}` : title }
                                </Header>
                            </ClippedContent>
                        </div>
                </div>
            ) : (
                <div className={extendedProps.activity_type === "event" ? `EventMonth` : "TaskMonth"}>
                    <div className={extendedProps.activity_type === "event" ? `EventMonth__circle` : "TaskMonth__circle"}/>
                    <ClippedContent>
                        <Header as={"h6"}>
                            {title}
                        </Header>
                    </ClippedContent>
                </div>
            )
        )
    }

    const getClassName = (arg) => {
        const { allDay } = arg.event?._def;
        const { activity_type } = arg.event?._def?.extendedProps
        return activity_type ? [`${activity_type.charAt(0).toUpperCase() + activity_type.slice(1)}${viewMode === "month" && !allDay ? "__Month" : ""}`, "EventBlock"] : "NewActivity";
    }

    const handleAllDayContent = (info) => {
        const {text} = info;
        return (
            <div>
                <div className="textContainer">
                    <span>{text}</span>
                </div>
                {
                showExpandAllDay ? 
                    <div className="iconContainer">
                        <Icon
                            onClick={() => setIsAllDayOn(!isAllDayOn)}
                            link
                            size="large"
                            className={`fas fa-circle-chevron-${isAllDayOn ? 'up' : 'down'} ExpandAllDay`}
                        />
                    </div> : null 
                }
            </div>
        )
    }

    const handleMoreLinkContent = ({num}) => {
        return viewMode === "month" ? (
            <div className="MoreButton">
                <span>{`${num} more`}</span>
                <Icon name="chevron right" />
            </div>
        ) : (
            <div className="MoreButton">
                <span>{`${num} more`}</span>
            </div>
        )
    }

    const handleDayClick = (info) => {
        setIsPopupOpen(true);
        addPlaceholderEvent(info);
    }

    return (
        <div className={`${getViewClass()} `}>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, interactionPlugin, momentTimezonePlugin]}
                initialView="timeGridDay" //timeGridWeek timeGridDay, multiMonthYear, dayGridMonth
                headerToolbar = {{
                    start: '',
                    end: ''
                }}
                eventDisplay={viewMode === "year" ? "none" : "auto"}
                events={displayEvents}
                eventContent={viewMode !== 'month' ? generateEventContent : generateMonthContent}
                dayHeaderFormat={viewMode === "year" && (({date}) => {
                    const day = moment(date).format('ddd');
                    return day.charAt(0);
                })}
                eventClassNames={getClassName}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short'
                }}
                dayMaxEvents={viewMode === "month" ? 3 : (isAllDayOn ? false : 2)}
                moreLinkClick={viewMode === "month" ? "popover" : () => setIsAllDayOn(!isAllDayOn)}
                defaultTimedEventDuration={'00:30'}
                contentHeight={750}
                columnHeader={false}
                nowIndicator={true}
                allDaySlot={showAllDay}
                navLinks={true}
                navLinkDayClick={goToDay}
                eventOverlap={false}
                selectOverlap={false}
                slotEventOverlap={false}
                timeZone={DateTimeUtils.getTimeZone()}
                allDayText="All day"
                dateClick={handleDayClick}
                allDayContent={handleAllDayContent}
                eventClick={onEventClick}
                moreLinkContent={handleMoreLinkContent}
            />
        </div>
    );
}

export default CalendarGridView;
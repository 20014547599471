import React from 'react';
import ReactQuill from 'react-quill-with-table/dist/react-quill';
import 'react-quill-with-table/dist/quill.bubble.css';

import './index.scss';

const HTMLPreview = ({ content, onMouseEnter, onMouseLeave, full, padded = true }) => (
    <div className={`HTMLPreview HTMLPreview${full ? '--full' : ''} HTMLPreview${padded ? '--padded' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
        <ReactQuill
            value={content}
            disabled
            theme="bubble"
            readOnly
        />
    </div>
);

export default HTMLPreview;

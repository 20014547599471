import React from 'react';
import { Menu } from 'semantic-ui-react';

import './index.scss';

const StyledMenu = props => (
    <Menu className={`Menu ${props.color ? `Menu--${props.color}` : ''}`} {...props} />
)

StyledMenu.Item = props => (
    <Menu.Item {...props} className={`Menu__item`} style={{color: `${props.inverted  ? 'white' : ''}`}} />
)

StyledMenu.Menu = props => (
    <Menu.Menu {...props} />
)

StyledMenu.Logo = props => (
    <Menu.Item {...props} className={`Menu__logo`} style={{color: `${props.inverted  ? 'white' : ''}`}} />
)

export default StyledMenu;

import React from 'react';
import { Form } from 'semantic-ui-react';
import './index.scss';

const TopMarginedFilterButton = ({ onSubmit }) => (
    <Form.Button onClick={onSubmit} primary floated='right' className='TopMarginedFilterButton'>
        Filter
    </Form.Button>
);

export default TopMarginedFilterButton;

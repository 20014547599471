import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "semantic-ui-react";

export const DateRangePowerSelector = ({ label, name }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={`filters[${name}]`}
            render={({ field: { name, value, onChange } }) => (
                <Dropdown
                    style={{ marginBottom: "1em" }}
                    text={label}
                    button
                    options={[
                        {
                            key: "today",
                            value: "today",
                            text: "Today",
                        },
                        {
                            key: "this_week",
                            value: "this_week",
                            text: "This Week",
                        },
                        {
                            key: "this_month",
                            value: "this_month",
                            text: "This Month",
                        },
                        {
                            key: "all_time",
                            value: "all_time",
                            text: "All Time",
                        },
                    ]}
                    selectOnBlur={false}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } });
                    }}
                />
            )}
        />
    );
};

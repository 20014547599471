import React from 'react';
import { Button } from 'semantic-ui-react';

import './index.scss';

const TimeUnitToggler = ({ content, onClick, style, active = false }) => (
    <Button fluid content={content} onClick={onClick} size='mini' className='TimeUnitToggler' color={active ? 'blue' : ''} basic={!active} style={{ ...style }} />
)

export default TimeUnitToggler;

import React from 'react';

import { Form, TextArea } from 'semantic-ui-react';

import './index.scss';

export class TextAreaCharCount extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            charCount: props.maxChar,
            textAreaValue: props.value
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.value !== this.props.value){
            this.updateValues(this.props.value);
        }
    }

    onTextAreaInput = (e, {value}) => {
        this.updateValues(value);
    }

    updateValues = (value) => {
        const { maxChar } = this.props;

        if(value.length <= maxChar){
            this.setState({
                textAreaValue: value,
                charCount: maxChar - value.length
            });   
        }
    }

    render(){
        const { label, textAreaPlaceholder, rows } = this.props;
        const { charCount, textAreaValue } = this.state;

        return(
            <React.Fragment>
                <div className='TextAreaCharCount'>
                    <div>{label}</div>
                    <div>
                        <Form>
                            <TextArea 
                                placeholder={textAreaPlaceholder}
                                onInput={this.onTextAreaInput}
                                value={textAreaValue}
                                rows={rows} />
                        </Form>
                    </div>
                    <div className='TextAreaCharCount__char-remaining'>
                        Characters Remaining: {charCount}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

/**
 * This file was taken from the  **/

export const TimezoneList = [
    {
        text: 'Indian/Mauritius',
        value: 'Indian/Mauritius',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '1',
        short_list: false,
    },
    {
        text: 'Indian/Chagos',
        value: 'Indian/Chagos',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '2',
        short_list: false,
    },
    {
        text: 'Indian/Mayotte',
        value: 'Indian/Mayotte',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '3',
        short_list: false,
    },
    {
        text: 'Indian/Christmas',
        value: 'Indian/Christmas',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '4',
        short_list: false,
    },
    {
        text: 'Indian/Cocos',
        value: 'Indian/Cocos',
        abbrev: '630',
        utc_offset: '06:30:00',
        is_dst: false,
        key: '5',
        short_list: false,
    },
    {
        text: 'Indian/Maldives',
        value: 'Indian/Maldives',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '6',
        short_list: false,
    },
    {
        text: 'Indian/Comoro',
        value: 'Indian/Comoro',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '7',
        short_list: false,
    },
    {
        text: 'Indian/Reunion',
        value: 'Indian/Reunion',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '8',
        short_list: false,
    },
    {
        text: 'Indian/Mahe',
        value: 'Indian/Mahe',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '9',
        short_list: false,
    },
    {
        text: 'Indian/Kerguelen',
        value: 'Indian/Kerguelen',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '10',
        short_list: false,
    },
    {
        text: 'Indian/Antananarivo',
        value: 'Indian/Antananarivo',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '11',
        short_list: false,
    },
    {
        text: 'Atlantic/Faroe',
        value: 'Atlantic/Faroe',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '12',
        short_list: false,
    },
    {
        text: 'Atlantic/Canary',
        value: 'Atlantic/Canary',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '13',
        short_list: false,
    },
    {
        text: 'Atlantic/Stanley',
        value: 'Atlantic/Stanley',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '14',
        short_list: false,
    },
    {
        text: 'Atlantic/Bermuda',
        value: 'Atlantic/Bermuda',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '15',
        short_list: false,
    },
    {
        text: 'Atlantic/South_Georgia',
        value: 'Atlantic/South_Georgia',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: false,
        key: '16',
        short_list: false,
    },
    {
        text: 'Atlantic/St_Helena',
        value: 'Atlantic/St_Helena',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '17',
        short_list: false,
    },
    {
        text: 'Atlantic/Jan_Mayen',
        value: 'Atlantic/Jan_Mayen',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '18',
        short_list: false,
    },
    {
        text: 'Atlantic/Faeroe',
        value: 'Atlantic/Faeroe',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '19',
        short_list: false,
    },
    {
        text: 'Atlantic/Reykjavik',
        value: 'Atlantic/Reykjavik',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '20',
        short_list: false,
    },
    {
        text: 'Atlantic/Cape_Verde',
        value: 'Atlantic/Cape_Verde',
        abbrev: '-1',
        utc_offset: '-01:00:00',
        is_dst: false,
        key: '21',
        short_list: false,
    },
    {
        text: 'Atlantic/Azores',
        value: 'Atlantic/Azores',
        abbrev: '0',
        utc_offset: '00:00:00',
        is_dst: true,
        key: '22',
        short_list: false,
    },
    {
        text: 'Atlantic/Madeira',
        value: 'Atlantic/Madeira',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '23',
        short_list: false,
    },
    {
        text: 'CST6CDT',
        value: 'CST6CDT',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '24',
        short_list: false,
    },
    {
        text: 'Poland',
        value: 'Poland',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '25',
        short_list: false,
    },
    {
        text: 'US/Alaska',
        value: 'US/Alaska',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '26',
        short_list: false,
    },
    {
        text: 'US/Pacific',
        value: 'US/Pacific',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '27',
        short_list: false,
    },
    {
        text: 'US/Eastern',
        value: 'US/Eastern',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '28',
        short_list: false,
    },
    {
        text: 'US/Michigan',
        value: 'US/Michigan',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '29',
        short_list: false,
    },
    {
        text: 'US/Arizona',
        value: 'US/Arizona',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '30',
        short_list: false,
    },
    {
        text: 'US/Indiana-Starke',
        value: 'US/Indiana-Starke',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '31',
        short_list: false,
    },
    {
        text: 'US/Aleutian',
        value: 'US/Aleutian',
        abbrev: 'HDT',
        utc_offset: '-09:00:00',
        is_dst: true,
        key: '32',
        short_list: false,
    },
    {
        text: 'US/Hawaii',
        value: 'US/Hawaii',
        abbrev: 'HST',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '33',
        short_list: false,
    },
    {
        text: 'US/East-Indiana',
        value: 'US/East-Indiana',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '34',
        short_list: false,
    },
    {
        text: 'US/Central',
        value: 'US/Central',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '35',
        short_list: false,
    },
    {
        text: 'US/Mountain',
        value: 'US/Mountain',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '36',
        short_list: false,
    },
    {
        text: 'US/Samoa',
        value: 'US/Samoa',
        abbrev: 'SST',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '37',
        short_list: false,
    },
    {
        text: 'Kwajalein',
        value: 'Kwajalein',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '38',
        short_list: false,
    },
    {
        text: 'Brazil/DeNoronha',
        value: 'Brazil/DeNoronha',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: false,
        key: '39',
        short_list: false,
    },
    {
        text: 'Brazil/Acre',
        value: 'Brazil/Acre',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '40',
        short_list: false,
    },
    {
        text: 'Brazil/East',
        value: 'Brazil/East',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '41',
        short_list: false,
    },
    {
        text: 'Brazil/West',
        value: 'Brazil/West',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '42',
        short_list: false,
    },
    {
        text: 'Pacific/Port_Moresby',
        value: 'Pacific/Port_Moresby',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '43',
        short_list: false,
    },
    {
        text: 'Pacific/Chuuk',
        value: 'Pacific/Chuuk',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '44',
        short_list: false,
    },
    {
        text: 'Pacific/Easter',
        value: 'Pacific/Easter',
        abbrev: '-6',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '45',
        short_list: false,
    },
    {
        text: 'Pacific/Kwajalein',
        value: 'Pacific/Kwajalein',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '46',
        short_list: false,
    },
    {
        text: 'Pacific/Tongatapu',
        value: 'Pacific/Tongatapu',
        abbrev: '13',
        utc_offset: '13:00:00',
        is_dst: false,
        key: '47',
        short_list: false,
    },
    {
        text: 'Pacific/Yap',
        value: 'Pacific/Yap',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '48',
        short_list: false,
    },
    {
        text: 'Pacific/Wallis',
        value: 'Pacific/Wallis',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '49',
        short_list: false,
    },
    {
        text: 'Pacific/Apia',
        value: 'Pacific/Apia',
        abbrev: '13',
        utc_offset: '13:00:00',
        is_dst: false,
        key: '50',
        short_list: false,
    },
    {
        text: 'Pacific/Norfolk',
        value: 'Pacific/Norfolk',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '51',
        short_list: false,
    },
    {
        text: 'Pacific/Efate',
        value: 'Pacific/Efate',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '52',
        short_list: false,
    },
    {
        text: 'Pacific/Fiji',
        value: 'Pacific/Fiji',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '53',
        short_list: false,
    },
    {
        text: 'Pacific/Funafuti',
        value: 'Pacific/Funafuti',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '54',
        short_list: false,
    },
    {
        text: 'Pacific/Palau',
        value: 'Pacific/Palau',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '55',
        short_list: false,
    },
    {
        text: 'Pacific/Guam',
        value: 'Pacific/Guam',
        abbrev: 'ChST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '56',
        short_list: false,
    },
    {
        text: 'Pacific/Saipan',
        value: 'Pacific/Saipan',
        abbrev: 'ChST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '57',
        short_list: false,
    },
    {
        text: 'Pacific/Kosrae',
        value: 'Pacific/Kosrae',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '58',
        short_list: false,
    },
    {
        text: 'Pacific/Niue',
        value: 'Pacific/Niue',
        abbrev: '-11',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '59',
        short_list: false,
    },
    {
        text: 'Pacific/Ponape',
        value: 'Pacific/Ponape',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '60',
        short_list: false,
    },
    {
        text: 'Pacific/Wake',
        value: 'Pacific/Wake',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '61',
        short_list: false,
    },
    {
        text: 'Pacific/Galapagos',
        value: 'Pacific/Galapagos',
        abbrev: '-6',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '62',
        short_list: false,
    },
    {
        text: 'Pacific/Johnston',
        value: 'Pacific/Johnston',
        abbrev: 'HST',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '63',
        short_list: false,
    },
    {
        text: 'Pacific/Midway',
        value: 'Pacific/Midway',
        abbrev: 'SST',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '64',
        short_list: false,
    },
    {
        text: 'Pacific/Nauru',
        value: 'Pacific/Nauru',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '65',
        short_list: false,
    },
    {
        text: 'Pacific/Guadalcanal',
        value: 'Pacific/Guadalcanal',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '66',
        short_list: false,
    },
    {
        text: 'Pacific/Chatham',
        value: 'Pacific/Chatham',
        abbrev: '1245',
        utc_offset: '12:45:00',
        is_dst: false,
        key: '67',
        short_list: false,
    },
    {
        text: 'Pacific/Auckland',
        value: 'Pacific/Auckland',
        abbrev: 'NZST',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '68',
        short_list: false,
    },
    {
        text: 'Pacific/Noumea',
        value: 'Pacific/Noumea',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '69',
        short_list: false,
    },
    {
        text: 'Pacific/Fakaofo',
        value: 'Pacific/Fakaofo',
        abbrev: '13',
        utc_offset: '13:00:00',
        is_dst: false,
        key: '70',
        short_list: false,
    },
    {
        text: 'Pacific/Tahiti',
        value: 'Pacific/Tahiti',
        abbrev: '-10',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '71',
        short_list: false,
    },
    {
        text: 'Pacific/Gambier',
        value: 'Pacific/Gambier',
        abbrev: '-9',
        utc_offset: '-09:00:00',
        is_dst: false,
        key: '72',
        short_list: false,
    },
    {
        text: 'Pacific/Majuro',
        value: 'Pacific/Majuro',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '73',
        short_list: false,
    },
    {
        text: 'Pacific/Honolulu',
        value: 'Pacific/Honolulu',
        abbrev: 'HST',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '74',
        short_list: true,
    },
    {
        text: 'Pacific/Pohnpei',
        value: 'Pacific/Pohnpei',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '75',
        short_list: false,
    },
    {
        text: 'Pacific/Pago_Pago',
        value: 'Pacific/Pago_Pago',
        abbrev: 'SST',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '76',
        short_list: false,
    },
    {
        text: 'Pacific/Truk',
        value: 'Pacific/Truk',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '77',
        short_list: false,
    },
    {
        text: 'Pacific/Pitcairn',
        value: 'Pacific/Pitcairn',
        abbrev: '-8',
        utc_offset: '-08:00:00',
        is_dst: false,
        key: '78',
        short_list: false,
    },
    {
        text: 'Pacific/Marquesas',
        value: 'Pacific/Marquesas',
        abbrev: '-930',
        utc_offset: '-09:30:00',
        is_dst: false,
        key: '79',
        short_list: false,
    },
    {
        text: 'Pacific/Bougainville',
        value: 'Pacific/Bougainville',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '80',
        short_list: false,
    },
    {
        text: 'Pacific/Tarawa',
        value: 'Pacific/Tarawa',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '81',
        short_list: false,
    },
    {
        text: 'Pacific/Rarotonga',
        value: 'Pacific/Rarotonga',
        abbrev: '-10',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '82',
        short_list: false,
    },
    {
        text: 'Pacific/Samoa',
        value: 'Pacific/Samoa',
        abbrev: 'SST',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '83',
        short_list: false,
    },
    {
        text: 'Pacific/Kiritimati',
        value: 'Pacific/Kiritimati',
        abbrev: '14',
        utc_offset: '14:00:00',
        is_dst: false,
        key: '84',
        short_list: false,
    },
    {
        text: 'Pacific/Enderbury',
        value: 'Pacific/Enderbury',
        abbrev: '13',
        utc_offset: '13:00:00',
        is_dst: false,
        key: '85',
        short_list: false,
    },
    {
        text: 'MST',
        value: 'MST',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '86',
        short_list: false,
    },
    {
        text: 'NZ',
        value: 'NZ',
        abbrev: 'NZST',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '87',
        short_list: false,
    },
    {
        text: 'Arctic/Longyearbyen',
        value: 'Arctic/Longyearbyen',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '88',
        short_list: false,
    },
    {
        text: 'Universal',
        value: 'Universal',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '89',
        short_list: false,
    },
    {
        text: 'Libya',
        value: 'Libya',
        abbrev: 'EET',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '90',
        short_list: false,
    },
    {
        text: 'Turkey',
        value: 'Turkey',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '91',
        short_list: false,
    },
    {
        text: 'EST5EDT',
        value: 'EST5EDT',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '92',
        short_list: false,
    },
    {
        text: 'Greenwich',
        value: 'Greenwich',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '93',
        short_list: false,
    },
    {
        text: 'America/Puerto_Rico',
        value: 'America/Puerto_Rico',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '94',
        short_list: false,
    },
    {
        text: 'America/Recife',
        value: 'America/Recife',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '95',
        short_list: false,
    },
    {
        text: 'America/Resolute',
        value: 'America/Resolute',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '96',
        short_list: false,
    },
    {
        text: 'America/Manaus',
        value: 'America/Manaus',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '97',
        short_list: false,
    },
    {
        text: 'America/New_York',
        value: 'America/New_York',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '98',
        short_list: true,
    },
    {
        text: 'America/Rankin_Inlet',
        value: 'America/Rankin_Inlet',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '99',
        short_list: false,
    },
    {
        text: 'America/Lima',
        value: 'America/Lima',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '100',
        short_list: false,
    },
    {
        text: 'America/St_Barthelemy',
        value: 'America/St_Barthelemy',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '101',
        short_list: false,
    },
    {
        text: 'America/Santo_Domingo',
        value: 'America/Santo_Domingo',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '102',
        short_list: false,
    },
    {
        text: 'America/Detroit',
        value: 'America/Detroit',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '103',
        short_list: false,
    },
    {
        text: 'America/Paramaribo',
        value: 'America/Paramaribo',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '104',
        short_list: false,
    },
    {
        text: 'America/Yakutat',
        value: 'America/Yakutat',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '105',
        short_list: false,
    },
    {
        text: 'America/Santarem',
        value: 'America/Santarem',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '106',
        short_list: false,
    },
    {
        text: 'America/Punta_Arenas',
        value: 'America/Punta_Arenas',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '107',
        short_list: false,
    },
    {
        text: 'America/Scoresbysund',
        value: 'America/Scoresbysund',
        abbrev: '0',
        utc_offset: '00:00:00',
        is_dst: true,
        key: '108',
        short_list: false,
    },
    {
        text: 'America/Santiago',
        value: 'America/Santiago',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '109',
        short_list: false,
    },
    {
        text: 'America/Guyana',
        value: 'America/Guyana',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '110',
        short_list: false,
    },
    {
        text: 'America/Coral_Harbour',
        value: 'America/Coral_Harbour',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '111',
        short_list: false,
    },
    {
        text: 'America/Rio_Branco',
        value: 'America/Rio_Branco',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '112',
        short_list: false,
    },
    {
        text: 'America/Porto_Acre',
        value: 'America/Porto_Acre',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '113',
        short_list: false,
    },
    {
        text: 'America/Nipigon',
        value: 'America/Nipigon',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '114',
        short_list: false,
    },
    {
        text: 'America/Edmonton',
        value: 'America/Edmonton',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '115',
        short_list: false,
    },
    {
        text: 'America/Port_of_Spain',
        value: 'America/Port_of_Spain',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '116',
        short_list: false,
    },
    {
        text: 'America/Lower_Princes',
        value: 'America/Lower_Princes',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '117',
        short_list: false,
    },
    {
        text: 'America/St_Thomas',
        value: 'America/St_Thomas',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '118',
        short_list: false,
    },
    {
        text: 'America/Guatemala',
        value: 'America/Guatemala',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '119',
        short_list: false,
    },
    {
        text: 'America/Catamarca',
        value: 'America/Catamarca',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '120',
        short_list: false,
    },
    {
        text: 'America/Antigua',
        value: 'America/Antigua',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '121',
        short_list: false,
    },
    {
        text: 'America/Porto_Velho',
        value: 'America/Porto_Velho',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '122',
        short_list: false,
    },
    {
        text: 'America/Rosario',
        value: 'America/Rosario',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '123',
        short_list: false,
    },
    {
        text: 'America/Chicago',
        value: 'America/Chicago',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '124',
        short_list: true,
    },
    {
        text: 'America/Creston',
        value: 'America/Creston',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '125',
        short_list: false,
    },
    {
        text: 'America/Managua',
        value: 'America/Managua',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '126',
        short_list: false,
    },
    {
        text: 'America/Nassau',
        value: 'America/Nassau',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '127',
        short_list: false,
    },
    {
        text: 'America/Bogota',
        value: 'America/Bogota',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '128',
        short_list: false,
    },
    {
        text: 'America/Cancun',
        value: 'America/Cancun',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '129',
        short_list: false,
    },
    {
        text: 'America/Chihuahua',
        value: 'America/Chihuahua',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '130',
        short_list: false,
    },
    {
        text: 'America/Campo_Grande',
        value: 'America/Campo_Grande',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '131',
        short_list: false,
    },
    {
        text: 'America/Halifax',
        value: 'America/Halifax',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '132',
        short_list: false,
    },
    {
        text: 'America/Boise',
        value: 'America/Boise',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '133',
        short_list: false,
    },
    {
        text: 'America/Montreal',
        value: 'America/Montreal',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '134',
        short_list: false,
    },
    {
        text: 'America/Goose_Bay',
        value: 'America/Goose_Bay',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '135',
        short_list: false,
    },
    {
        text: 'America/Sao_Paulo',
        value: 'America/Sao_Paulo',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '136',
        short_list: false,
    },
    {
        text: 'America/Blanc-Sablon',
        value: 'America/Blanc-Sablon',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '137',
        short_list: false,
    },
    {
        text: 'America/Phoenix',
        value: 'America/Phoenix',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '138',
        short_list: true,
    },
    {
        text: 'America/Atikokan',
        value: 'America/Atikokan',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '139',
        short_list: false,
    },
    {
        text: 'America/Cayenne',
        value: 'America/Cayenne',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '140',
        short_list: false,
    },
    {
        text: 'America/Nuuk',
        value: 'America/Nuuk',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: true,
        key: '141',
        short_list: false,
    },
    {
        text: 'America/Santa_Isabel',
        value: 'America/Santa_Isabel',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '142',
        short_list: false,
    },
    {
        text: 'America/Boa_Vista',
        value: 'America/Boa_Vista',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '143',
        short_list: false,
    },
    {
        text: 'America/Bahia_Banderas',
        value: 'America/Bahia_Banderas',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '144',
        short_list: false,
    },
    {
        text: 'America/Indiana/Vevay',
        value: 'America/Indiana/Vevay',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '145',
        short_list: false,
    },
    {
        text: 'America/Indiana/Indianapolis',
        value: 'America/Indiana/Indianapolis',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '146',
        short_list: false,
    },
    {
        text: 'America/Indiana/Winamac',
        value: 'America/Indiana/Winamac',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '147',
        short_list: false,
    },
    {
        text: 'America/Indiana/Tell_City',
        value: 'America/Indiana/Tell_City',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '148',
        short_list: false,
    },
    {
        text: 'America/Indiana/Petersburg',
        value: 'America/Indiana/Petersburg',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '149',
        short_list: false,
    },
    {
        text: 'America/Indiana/Vincennes',
        value: 'America/Indiana/Vincennes',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '150',
        short_list: false,
    },
    {
        text: 'America/Indiana/Knox',
        value: 'America/Indiana/Knox',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '151',
        short_list: false,
    },
    {
        text: 'America/Indiana/Marengo',
        value: 'America/Indiana/Marengo',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '152',
        short_list: false,
    },
    {
        text: 'America/Indianapolis',
        value: 'America/Indianapolis',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '153',
        short_list: false,
    },
    {
        text: 'America/Dominica',
        value: 'America/Dominica',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '154',
        short_list: false,
    },
    {
        text: 'America/Argentina/Salta',
        value: 'America/Argentina/Salta',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '155',
        short_list: false,
    },
    {
        text: 'America/Argentina/Ushuaia',
        value: 'America/Argentina/Ushuaia',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '156',
        short_list: false,
    },
    {
        text: 'America/Argentina/Catamarca',
        value: 'America/Argentina/Catamarca',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '157',
        short_list: false,
    },
    {
        text: 'America/Argentina/ComodRivadavia',
        value: 'America/Argentina/ComodRivadavia',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '158',
        short_list: false,
    },
    {
        text: 'America/Argentina/San_Juan',
        value: 'America/Argentina/San_Juan',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '159',
        short_list: false,
    },
    {
        text: 'America/Argentina/San_Luis',
        value: 'America/Argentina/San_Luis',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '160',
        short_list: false,
    },
    {
        text: 'America/Argentina/Rio_Gallegos',
        value: 'America/Argentina/Rio_Gallegos',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '161',
        short_list: false,
    },
    {
        text: 'America/Argentina/Jujuy',
        value: 'America/Argentina/Jujuy',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '162',
        short_list: false,
    },
    {
        text: 'America/Argentina/Tucuman',
        value: 'America/Argentina/Tucuman',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '163',
        short_list: false,
    },
    {
        text: 'America/Argentina/Buenos_Aires',
        value: 'America/Argentina/Buenos_Aires',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '164',
        short_list: false,
    },
    {
        text: 'America/Argentina/Cordoba',
        value: 'America/Argentina/Cordoba',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '165',
        short_list: false,
    },
    {
        text: 'America/Argentina/La_Rioja',
        value: 'America/Argentina/La_Rioja',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '166',
        short_list: false,
    },
    {
        text: 'America/Argentina/Mendoza',
        value: 'America/Argentina/Mendoza',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '167',
        short_list: false,
    },
    {
        text: 'America/La_Paz',
        value: 'America/La_Paz',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '168',
        short_list: false,
    },
    {
        text: 'America/Dawson',
        value: 'America/Dawson',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '169',
        short_list: false,
    },
    {
        text: 'America/Moncton',
        value: 'America/Moncton',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '170',
        short_list: false,
    },
    {
        text: 'America/Matamoros',
        value: 'America/Matamoros',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '171',
        short_list: false,
    },
    {
        text: 'America/St_Vincent',
        value: 'America/St_Vincent',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '172',
        short_list: false,
    },
    {
        text: 'America/Regina',
        value: 'America/Regina',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '173',
        short_list: false,
    },
    {
        text: 'America/Yellowknife',
        value: 'America/Yellowknife',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '174',
        short_list: false,
    },
    {
        text: 'America/Rainy_River',
        value: 'America/Rainy_River',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '175',
        short_list: false,
    },
    {
        text: 'America/Kralendijk',
        value: 'America/Kralendijk',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '176',
        short_list: false,
    },
    {
        text: 'America/Monterrey',
        value: 'America/Monterrey',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '177',
        short_list: false,
    },
    {
        text: 'America/Jamaica',
        value: 'America/Jamaica',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '178',
        short_list: false,
    },
    {
        text: 'America/Havana',
        value: 'America/Havana',
        abbrev: 'CDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '179',
        short_list: false,
    },
    {
        text: 'America/Tegucigalpa',
        value: 'America/Tegucigalpa',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '180',
        short_list: false,
    },
    {
        text: 'America/Guayaquil',
        value: 'America/Guayaquil',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '181',
        short_list: false,
    },
    {
        text: 'America/Metlakatla',
        value: 'America/Metlakatla',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '182',
        short_list: false,
    },
    {
        text: 'America/Mazatlan',
        value: 'America/Mazatlan',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '183',
        short_list: false,
    },
    {
        text: 'America/Belize',
        value: 'America/Belize',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '184',
        short_list: false,
    },
    {
        text: 'America/Knox_IN',
        value: 'America/Knox_IN',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '185',
        short_list: false,
    },
    {
        text: 'America/Cuiaba',
        value: 'America/Cuiaba',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '186',
        short_list: false,
    },
    {
        text: 'America/Merida',
        value: 'America/Merida',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '187',
        short_list: false,
    },
    {
        text: 'America/Jujuy',
        value: 'America/Jujuy',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '188',
        short_list: false,
    },
    {
        text: 'America/Cayman',
        value: 'America/Cayman',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '189',
        short_list: false,
    },
    {
        text: 'America/Belem',
        value: 'America/Belem',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '190',
        short_list: false,
    },
    {
        text: 'America/Eirunepe',
        value: 'America/Eirunepe',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '191',
        short_list: false,
    },
    {
        text: 'America/St_Lucia',
        value: 'America/St_Lucia',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '192',
        short_list: false,
    },
    {
        text: 'America/Bahia',
        value: 'America/Bahia',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '193',
        short_list: false,
    },
    {
        text: 'America/Whitehorse',
        value: 'America/Whitehorse',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '194',
        short_list: false,
    },
    {
        text: 'America/Tortola',
        value: 'America/Tortola',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '195',
        short_list: false,
    },
    {
        text: 'America/Vancouver',
        value: 'America/Vancouver',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '196',
        short_list: false,
    },
    {
        text: 'America/Inuvik',
        value: 'America/Inuvik',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '197',
        short_list: false,
    },
    {
        text: 'America/Port-au-Prince',
        value: 'America/Port-au-Prince',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '198',
        short_list: false,
    },
    {
        text: 'America/Fortaleza',
        value: 'America/Fortaleza',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '199',
        short_list: false,
    },
    {
        text: 'America/Noronha',
        value: 'America/Noronha',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: false,
        key: '200',
        short_list: false,
    },
    {
        text: 'America/Buenos_Aires',
        value: 'America/Buenos_Aires',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '201',
        short_list: false,
    },
    {
        text: 'America/Los_Angeles',
        value: 'America/Los_Angeles',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '202',
        short_list: true,
    },
    {
        text: 'America/El_Salvador',
        value: 'America/El_Salvador',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '203',
        short_list: false,
    },
    {
        text: 'America/Denver',
        value: 'America/Denver',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '204',
        short_list: true,
    },
    {
        text: 'America/Fort_Wayne',
        value: 'America/Fort_Wayne',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '205',
        short_list: false,
    },
    {
        text: 'America/Kentucky/Louisville',
        value: 'America/Kentucky/Louisville',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '206',
        short_list: false,
    },
    {
        text: 'America/Kentucky/Monticello',
        value: 'America/Kentucky/Monticello',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '207',
        short_list: false,
    },
    {
        text: 'America/North_Dakota/New_Salem',
        value: 'America/North_Dakota/New_Salem',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '208',
        short_list: false,
    },
    {
        text: 'America/North_Dakota/Center',
        value: 'America/North_Dakota/Center',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '209',
        short_list: false,
    },
    {
        text: 'America/North_Dakota/Beulah',
        value: 'America/North_Dakota/Beulah',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '210',
        short_list: false,
    },
    {
        text: 'America/Glace_Bay',
        value: 'America/Glace_Bay',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '211',
        short_list: false,
    },
    {
        text: 'America/Montserrat',
        value: 'America/Montserrat',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '212',
        short_list: false,
    },
    {
        text: 'America/Toronto',
        value: 'America/Toronto',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '213',
        short_list: false,
    },
    {
        text: 'America/Panama',
        value: 'America/Panama',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '214',
        short_list: false,
    },
    {
        text: 'America/Cordoba',
        value: 'America/Cordoba',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '215',
        short_list: false,
    },
    {
        text: 'America/Louisville',
        value: 'America/Louisville',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '216',
        short_list: false,
    },
    {
        text: 'America/Ensenada',
        value: 'America/Ensenada',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '217',
        short_list: false,
    },
    {
        text: 'America/Shiprock',
        value: 'America/Shiprock',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '218',
        short_list: false,
    },
    {
        text: 'America/Ojinaga',
        value: 'America/Ojinaga',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '219',
        short_list: false,
    },
    {
        text: 'America/Thule',
        value: 'America/Thule',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '220',
        short_list: false,
    },
    {
        text: 'America/Caracas',
        value: 'America/Caracas',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '221',
        short_list: false,
    },
    {
        text: 'America/Araguaina',
        value: 'America/Araguaina',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '222',
        short_list: false,
    },
    {
        text: 'America/Cambridge_Bay',
        value: 'America/Cambridge_Bay',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '223',
        short_list: false,
    },
    {
        text: 'America/Winnipeg',
        value: 'America/Winnipeg',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '224',
        short_list: false,
    },
    {
        text: 'America/Grand_Turk',
        value: 'America/Grand_Turk',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '225',
        short_list: false,
    },
    {
        text: 'America/Virgin',
        value: 'America/Virgin',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '226',
        short_list: false,
    },
    {
        text: 'America/Anchorage',
        value: 'America/Anchorage',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '227',
        short_list: true,
    },
    {
        text: 'America/Costa_Rica',
        value: 'America/Costa_Rica',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '228',
        short_list: false,
    },
    {
        text: 'America/Nome',
        value: 'America/Nome',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '229',
        short_list: false,
    },
    {
        text: 'America/Grenada',
        value: 'America/Grenada',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '230',
        short_list: false,
    },
    {
        text: 'America/St_Johns',
        value: 'America/St_Johns',
        abbrev: 'NDT',
        utc_offset: '-02:30:00',
        is_dst: true,
        key: '231',
        short_list: false,
    },
    {
        text: 'America/Atka',
        value: 'America/Atka',
        abbrev: 'HDT',
        utc_offset: '-09:00:00',
        is_dst: true,
        key: '232',
        short_list: false,
    },
    {
        text: 'America/Asuncion',
        value: 'America/Asuncion',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '233',
        short_list: false,
    },
    {
        text: 'America/Hermosillo',
        value: 'America/Hermosillo',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '234',
        short_list: false,
    },
    {
        text: 'America/Tijuana',
        value: 'America/Tijuana',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '235',
        short_list: false,
    },
    {
        text: 'America/Marigot',
        value: 'America/Marigot',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '236',
        short_list: false,
    },
    {
        text: 'America/Juneau',
        value: 'America/Juneau',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '237',
        short_list: false,
    },
    {
        text: 'America/Montevideo',
        value: 'America/Montevideo',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '238',
        short_list: false,
    },
    {
        text: 'America/Godthab',
        value: 'America/Godthab',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: true,
        key: '239',
        short_list: false,
    },
    {
        text: 'America/Guadeloupe',
        value: 'America/Guadeloupe',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '240',
        short_list: false,
    },
    {
        text: 'America/Maceio',
        value: 'America/Maceio',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '241',
        short_list: false,
    },
    {
        text: 'America/Pangnirtung',
        value: 'America/Pangnirtung',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '242',
        short_list: false,
    },
    {
        text: 'America/St_Kitts',
        value: 'America/St_Kitts',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '243',
        short_list: false,
    },
    {
        text: 'America/Barbados',
        value: 'America/Barbados',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '244',
        short_list: false,
    },
    {
        text: 'America/Iqaluit',
        value: 'America/Iqaluit',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '245',
        short_list: false,
    },
    {
        text: 'America/Menominee',
        value: 'America/Menominee',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '246',
        short_list: false,
    },
    {
        text: 'America/Martinique',
        value: 'America/Martinique',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '247',
        short_list: false,
    },
    {
        text: 'America/Mexico_City',
        value: 'America/Mexico_City',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '248',
        short_list: false,
    },
    {
        text: 'America/Swift_Current',
        value: 'America/Swift_Current',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '249',
        short_list: false,
    },
    {
        text: 'America/Miquelon',
        value: 'America/Miquelon',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: true,
        key: '250',
        short_list: false,
    },
    {
        text: 'America/Curacao',
        value: 'America/Curacao',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '251',
        short_list: false,
    },
    {
        text: 'America/Dawson_Creek',
        value: 'America/Dawson_Creek',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '252',
        short_list: false,
    },
    {
        text: 'America/Mendoza',
        value: 'America/Mendoza',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '253',
        short_list: false,
    },
    {
        text: 'America/Adak',
        value: 'America/Adak',
        abbrev: 'HDT',
        utc_offset: '-09:00:00',
        is_dst: true,
        key: '254',
        short_list: false,
    },
    {
        text: 'America/Thunder_Bay',
        value: 'America/Thunder_Bay',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '255',
        short_list: false,
    },
    {
        text: 'America/Aruba',
        value: 'America/Aruba',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '256',
        short_list: false,
    },
    {
        text: 'America/Fort_Nelson',
        value: 'America/Fort_Nelson',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '257',
        short_list: false,
    },
    {
        text: 'America/Sitka',
        value: 'America/Sitka',
        abbrev: 'AKDT',
        utc_offset: '-08:00:00',
        is_dst: true,
        key: '258',
        short_list: false,
    },
    {
        text: 'America/Anguilla',
        value: 'America/Anguilla',
        abbrev: 'AST',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '259',
        short_list: false,
    },
    {
        text: 'America/Danmarkshavn',
        value: 'America/Danmarkshavn',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '260',
        short_list: false,
    },
    {
        text: 'Australia/Melbourne',
        value: 'Australia/Melbourne',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '261',
        short_list: false,
    },
    {
        text: 'Australia/Queensland',
        value: 'Australia/Queensland',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '262',
        short_list: false,
    },
    {
        text: 'Australia/North',
        value: 'Australia/North',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '263',
        short_list: false,
    },
    {
        text: 'Australia/Lord_Howe',
        value: 'Australia/Lord_Howe',
        abbrev: '1030',
        utc_offset: '10:30:00',
        is_dst: false,
        key: '264',
        short_list: false,
    },
    {
        text: 'Australia/Adelaide',
        value: 'Australia/Adelaide',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '265',
        short_list: false,
    },
    {
        text: 'Australia/Yancowinna',
        value: 'Australia/Yancowinna',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '266',
        short_list: false,
    },
    {
        text: 'Australia/Victoria',
        value: 'Australia/Victoria',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '267',
        short_list: false,
    },
    {
        text: 'Australia/Canberra',
        value: 'Australia/Canberra',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '268',
        short_list: false,
    },
    {
        text: 'Australia/Sydney',
        value: 'Australia/Sydney',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '269',
        short_list: false,
    },
    {
        text: 'Australia/ACT',
        value: 'Australia/ACT',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '270',
        short_list: false,
    },
    {
        text: 'Australia/Eucla',
        value: 'Australia/Eucla',
        abbrev: '845',
        utc_offset: '08:45:00',
        is_dst: false,
        key: '271',
        short_list: false,
    },
    {
        text: 'Australia/Brisbane',
        value: 'Australia/Brisbane',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '272',
        short_list: false,
    },
    {
        text: 'Australia/Tasmania',
        value: 'Australia/Tasmania',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '273',
        short_list: false,
    },
    {
        text: 'Australia/Hobart',
        value: 'Australia/Hobart',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '274',
        short_list: false,
    },
    {
        text: 'Australia/Perth',
        value: 'Australia/Perth',
        abbrev: 'AWST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '275',
        short_list: false,
    },
    {
        text: 'Australia/South',
        value: 'Australia/South',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '276',
        short_list: false,
    },
    {
        text: 'Australia/Lindeman',
        value: 'Australia/Lindeman',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '277',
        short_list: false,
    },
    {
        text: 'Australia/Darwin',
        value: 'Australia/Darwin',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '278',
        short_list: false,
    },
    {
        text: 'Australia/West',
        value: 'Australia/West',
        abbrev: 'AWST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '279',
        short_list: false,
    },
    {
        text: 'Australia/LHI',
        value: 'Australia/LHI',
        abbrev: '1030',
        utc_offset: '10:30:00',
        is_dst: false,
        key: '280',
        short_list: false,
    },
    {
        text: 'Australia/NSW',
        value: 'Australia/NSW',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '281',
        short_list: false,
    },
    {
        text: 'Australia/Broken_Hill',
        value: 'Australia/Broken_Hill',
        abbrev: 'ACST',
        utc_offset: '09:30:00',
        is_dst: false,
        key: '282',
        short_list: false,
    },
    {
        text: 'Australia/Currie',
        value: 'Australia/Currie',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '283',
        short_list: false,
    },
    {
        text: 'Etc/GMT-10',
        value: 'Etc/GMT-10',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '284',
        short_list: false,
    },
    {
        text: 'Etc/GMT+12',
        value: 'Etc/GMT+12',
        abbrev: '-12',
        utc_offset: '-12:00:00',
        is_dst: false,
        key: '285',
        short_list: false,
    },
    {
        text: 'Etc/GMT-11',
        value: 'Etc/GMT-11',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '286',
        short_list: false,
    },
    {
        text: 'Etc/Universal',
        value: 'Etc/Universal',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '287',
        short_list: false,
    },
    {
        text: 'Etc/Greenwich',
        value: 'Etc/Greenwich',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '288',
        short_list: false,
    },
    {
        text: 'Etc/GMT-6',
        value: 'Etc/GMT-6',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '289',
        short_list: false,
    },
    {
        text: 'Etc/GMT-1',
        value: 'Etc/GMT-1',
        abbrev: '1',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '290',
        short_list: false,
    },
    {
        text: 'Etc/GMT-8',
        value: 'Etc/GMT-8',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '291',
        short_list: false,
    },
    {
        text: 'Etc/GMT+4',
        value: 'Etc/GMT+4',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '292',
        short_list: false,
    },
    {
        text: 'Etc/GMT+3',
        value: 'Etc/GMT+3',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '293',
        short_list: false,
    },
    {
        text: 'Etc/GMT-9',
        value: 'Etc/GMT-9',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '294',
        short_list: false,
    },
    {
        text: 'Etc/GMT-0',
        value: 'Etc/GMT-0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '295',
        short_list: false,
    },
    {
        text: 'Etc/GMT-7',
        value: 'Etc/GMT-7',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '296',
        short_list: false,
    },
    {
        text: 'Etc/GMT+2',
        value: 'Etc/GMT+2',
        abbrev: '-2',
        utc_offset: '-02:00:00',
        is_dst: false,
        key: '297',
        short_list: false,
    },
    {
        text: 'Etc/GMT+5',
        value: 'Etc/GMT+5',
        abbrev: '-5',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '298',
        short_list: false,
    },
    {
        text: 'Etc/GMT',
        value: 'Etc/GMT',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '299',
        short_list: false,
    },
    {
        text: 'Etc/Zulu',
        value: 'Etc/Zulu',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '300',
        short_list: false,
    },
    {
        text: 'Etc/GMT+11',
        value: 'Etc/GMT+11',
        abbrev: '-11',
        utc_offset: '-11:00:00',
        is_dst: false,
        key: '301',
        short_list: false,
    },
    {
        text: 'Etc/GMT-13',
        value: 'Etc/GMT-13',
        abbrev: '13',
        utc_offset: '13:00:00',
        is_dst: false,
        key: '302',
        short_list: false,
    },
    {
        text: 'Etc/GMT-14',
        value: 'Etc/GMT-14',
        abbrev: '14',
        utc_offset: '14:00:00',
        is_dst: false,
        key: '303',
        short_list: false,
    },
    {
        text: 'Etc/GMT+10',
        value: 'Etc/GMT+10',
        abbrev: '-10',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '304',
        short_list: false,
    },
    {
        text: 'Etc/GMT-12',
        value: 'Etc/GMT-12',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '305',
        short_list: false,
    },
    {
        text: 'Etc/GMT0',
        value: 'Etc/GMT0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '306',
        short_list: false,
    },
    {
        text: 'Etc/UCT',
        value: 'Etc/UCT',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '307',
        short_list: false,
    },
    {
        text: 'Etc/GMT+0',
        value: 'Etc/GMT+0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '308',
        short_list: false,
    },
    {
        text: 'Etc/GMT+7',
        value: 'Etc/GMT+7',
        abbrev: '-7',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '309',
        short_list: false,
    },
    {
        text: 'Etc/GMT+9',
        value: 'Etc/GMT+9',
        abbrev: '-9',
        utc_offset: '-09:00:00',
        is_dst: false,
        key: '310',
        short_list: false,
    },
    {
        text: 'Etc/GMT-2',
        value: 'Etc/GMT-2',
        abbrev: '2',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '311',
        short_list: false,
    },
    {
        text: 'Etc/GMT-5',
        value: 'Etc/GMT-5',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '312',
        short_list: false,
    },
    {
        text: 'Etc/GMT+8',
        value: 'Etc/GMT+8',
        abbrev: '-8',
        utc_offset: '-08:00:00',
        is_dst: false,
        key: '313',
        short_list: false,
    },
    {
        text: 'Etc/GMT+6',
        value: 'Etc/GMT+6',
        abbrev: '-6',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '314',
        short_list: false,
    },
    {
        text: 'Etc/GMT+1',
        value: 'Etc/GMT+1',
        abbrev: '-1',
        utc_offset: '-01:00:00',
        is_dst: false,
        key: '315',
        short_list: false,
    },
    {
        text: 'Etc/UTC',
        value: 'Etc/UTC',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '316',
        short_list: false,
    },
    {
        text: 'Etc/GMT-4',
        value: 'Etc/GMT-4',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '317',
        short_list: false,
    },
    {
        text: 'Etc/GMT-3',
        value: 'Etc/GMT-3',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '318',
        short_list: false,
    },
    {
        text: 'NZ-CHAT',
        value: 'NZ-CHAT',
        abbrev: '1245',
        utc_offset: '12:45:00',
        is_dst: false,
        key: '319',
        short_list: false,
    },
    {
        text: 'Asia/Dushanbe',
        value: 'Asia/Dushanbe',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '320',
        short_list: false,
    },
    {
        text: 'Asia/Qostanay',
        value: 'Asia/Qostanay',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '321',
        short_list: false,
    },
    {
        text: 'Asia/Calcutta',
        value: 'Asia/Calcutta',
        abbrev: 'IST',
        utc_offset: '05:30:00',
        is_dst: false,
        key: '322',
        short_list: false,
    },
    {
        text: 'Asia/Urumqi',
        value: 'Asia/Urumqi',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '323',
        short_list: false,
    },
    {
        text: 'Asia/Karachi',
        value: 'Asia/Karachi',
        abbrev: 'PKT',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '324',
        short_list: false,
    },
    {
        text: 'Asia/Khandyga',
        value: 'Asia/Khandyga',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '325',
        short_list: false,
    },
    {
        text: 'Asia/Thimbu',
        value: 'Asia/Thimbu',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '326',
        short_list: false,
    },
    {
        text: 'Asia/Thimphu',
        value: 'Asia/Thimphu',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '327',
        short_list: false,
    },
    {
        text: 'Asia/Vladivostok',
        value: 'Asia/Vladivostok',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '328',
        short_list: false,
    },
    {
        text: 'Asia/Vientiane',
        value: 'Asia/Vientiane',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '329',
        short_list: false,
    },
    {
        text: 'Asia/Shanghai',
        value: 'Asia/Shanghai',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '330',
        short_list: false,
    },
    {
        text: 'Asia/Ulan_Bator',
        value: 'Asia/Ulan_Bator',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '331',
        short_list: false,
    },
    {
        text: 'Asia/Aden',
        value: 'Asia/Aden',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '332',
        short_list: false,
    },
    {
        text: 'Asia/Muscat',
        value: 'Asia/Muscat',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '333',
        short_list: false,
    },
    {
        text: 'Asia/Damascus',
        value: 'Asia/Damascus',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '334',
        short_list: false,
    },
    {
        text: 'Asia/Jerusalem',
        value: 'Asia/Jerusalem',
        abbrev: 'IDT',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '335',
        short_list: false,
    },
    {
        text: 'Asia/Brunei',
        value: 'Asia/Brunei',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '336',
        short_list: false,
    },
    {
        text: 'Asia/Ulaanbaatar',
        value: 'Asia/Ulaanbaatar',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '337',
        short_list: false,
    },
    {
        text: 'Asia/Amman',
        value: 'Asia/Amman',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '338',
        short_list: false,
    },
    {
        text: 'Asia/Kuching',
        value: 'Asia/Kuching',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '339',
        short_list: false,
    },
    {
        text: 'Asia/Tel_Aviv',
        value: 'Asia/Tel_Aviv',
        abbrev: 'IDT',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '340',
        short_list: false,
    },
    {
        text: 'Asia/Seoul',
        value: 'Asia/Seoul',
        abbrev: 'KST',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '341',
        short_list: false,
    },
    {
        text: 'Asia/Atyrau',
        value: 'Asia/Atyrau',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '342',
        short_list: false,
    },
    {
        text: 'Asia/Pyongyang',
        value: 'Asia/Pyongyang',
        abbrev: 'KST',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '343',
        short_list: false,
    },
    {
        text: 'Asia/Hovd',
        value: 'Asia/Hovd',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '344',
        short_list: false,
    },
    {
        text: 'Asia/Hebron',
        value: 'Asia/Hebron',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '345',
        short_list: false,
    },
    {
        text: 'Asia/Kuwait',
        value: 'Asia/Kuwait',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '346',
        short_list: false,
    },
    {
        text: 'Asia/Tomsk',
        value: 'Asia/Tomsk',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '347',
        short_list: false,
    },
    {
        text: 'Asia/Manila',
        value: 'Asia/Manila',
        abbrev: 'PST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '348',
        short_list: false,
    },
    {
        text: 'Asia/Chita',
        value: 'Asia/Chita',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '349',
        short_list: false,
    },
    {
        text: 'Asia/Katmandu',
        value: 'Asia/Katmandu',
        abbrev: '545',
        utc_offset: '05:45:00',
        is_dst: false,
        key: '350',
        short_list: false,
    },
    {
        text: 'Asia/Gaza',
        value: 'Asia/Gaza',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '351',
        short_list: false,
    },
    {
        text: 'Asia/Samarkand',
        value: 'Asia/Samarkand',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '352',
        short_list: false,
    },
    {
        text: 'Asia/Taipei',
        value: 'Asia/Taipei',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '353',
        short_list: false,
    },
    {
        text: 'Asia/Tashkent',
        value: 'Asia/Tashkent',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '354',
        short_list: false,
    },
    {
        text: 'Asia/Yekaterinburg',
        value: 'Asia/Yekaterinburg',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '355',
        short_list: false,
    },
    {
        text: 'Asia/Macau',
        value: 'Asia/Macau',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '356',
        short_list: false,
    },
    {
        text: 'Asia/Qyzylorda',
        value: 'Asia/Qyzylorda',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '357',
        short_list: false,
    },
    {
        text: 'Asia/Macao',
        value: 'Asia/Macao',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '358',
        short_list: false,
    },
    {
        text: 'Asia/Tokyo',
        value: 'Asia/Tokyo',
        abbrev: 'JST',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '359',
        short_list: false,
    },
    {
        text: 'Asia/Baku',
        value: 'Asia/Baku',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '360',
        short_list: false,
    },
    {
        text: 'Asia/Barnaul',
        value: 'Asia/Barnaul',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '361',
        short_list: false,
    },
    {
        text: 'Asia/Istanbul',
        value: 'Asia/Istanbul',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '362',
        short_list: false,
    },
    {
        text: 'Asia/Irkutsk',
        value: 'Asia/Irkutsk',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '363',
        short_list: false,
    },
    {
        text: 'Asia/Qatar',
        value: 'Asia/Qatar',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '364',
        short_list: false,
    },
    {
        text: 'Asia/Bahrain',
        value: 'Asia/Bahrain',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '365',
        short_list: false,
    },
    {
        text: 'Asia/Yangon',
        value: 'Asia/Yangon',
        abbrev: '630',
        utc_offset: '06:30:00',
        is_dst: false,
        key: '366',
        short_list: false,
    },
    {
        text: 'Asia/Yerevan',
        value: 'Asia/Yerevan',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '367',
        short_list: false,
    },
    {
        text: 'Asia/Almaty',
        value: 'Asia/Almaty',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '368',
        short_list: false,
    },
    {
        text: 'Asia/Dili',
        value: 'Asia/Dili',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '369',
        short_list: false,
    },
    {
        text: 'Asia/Dacca',
        value: 'Asia/Dacca',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '370',
        short_list: false,
    },
    {
        text: 'Asia/Chongqing',
        value: 'Asia/Chongqing',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '371',
        short_list: false,
    },
    {
        text: 'Asia/Ust-Nera',
        value: 'Asia/Ust-Nera',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '372',
        short_list: false,
    },
    {
        text: 'Asia/Magadan',
        value: 'Asia/Magadan',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '373',
        short_list: false,
    },
    {
        text: 'Asia/Colombo',
        value: 'Asia/Colombo',
        abbrev: '530',
        utc_offset: '05:30:00',
        is_dst: false,
        key: '374',
        short_list: false,
    },
    {
        text: 'Asia/Krasnoyarsk',
        value: 'Asia/Krasnoyarsk',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '375',
        short_list: false,
    },
    {
        text: 'Asia/Kamchatka',
        value: 'Asia/Kamchatka',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '376',
        short_list: false,
    },
    {
        text: 'Asia/Ujung_Pandang',
        value: 'Asia/Ujung_Pandang',
        abbrev: 'WITA',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '377',
        short_list: false,
    },
    {
        text: 'Asia/Jakarta',
        value: 'Asia/Jakarta',
        abbrev: 'WIB',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '378',
        short_list: false,
    },
    {
        text: 'Asia/Kolkata',
        value: 'Asia/Kolkata',
        abbrev: 'IST',
        utc_offset: '05:30:00',
        is_dst: false,
        key: '379',
        short_list: false,
    },
    {
        text: 'Asia/Kabul',
        value: 'Asia/Kabul',
        abbrev: '430',
        utc_offset: '04:30:00',
        is_dst: false,
        key: '380',
        short_list: false,
    },
    {
        text: 'Asia/Oral',
        value: 'Asia/Oral',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '381',
        short_list: false,
    },
    {
        text: 'Asia/Jayapura',
        value: 'Asia/Jayapura',
        abbrev: 'WIT',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '382',
        short_list: false,
    },
    {
        text: 'Asia/Pontianak',
        value: 'Asia/Pontianak',
        abbrev: 'WIB',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '383',
        short_list: false,
    },
    {
        text: 'Asia/Makassar',
        value: 'Asia/Makassar',
        abbrev: 'WITA',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '384',
        short_list: false,
    },
    {
        text: 'Asia/Tbilisi',
        value: 'Asia/Tbilisi',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '385',
        short_list: false,
    },
    {
        text: 'Asia/Singapore',
        value: 'Asia/Singapore',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '386',
        short_list: false,
    },
    {
        text: 'Asia/Harbin',
        value: 'Asia/Harbin',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '387',
        short_list: false,
    },
    {
        text: 'Asia/Kashgar',
        value: 'Asia/Kashgar',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '388',
        short_list: false,
    },
    {
        text: 'Asia/Dhaka',
        value: 'Asia/Dhaka',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '389',
        short_list: false,
    },
    {
        text: 'Asia/Yakutsk',
        value: 'Asia/Yakutsk',
        abbrev: '9',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '390',
        short_list: false,
    },
    {
        text: 'Asia/Kuala_Lumpur',
        value: 'Asia/Kuala_Lumpur',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '391',
        short_list: false,
    },
    {
        text: 'Asia/Tehran',
        value: 'Asia/Tehran',
        abbrev: '430',
        utc_offset: '04:30:00',
        is_dst: true,
        key: '392',
        short_list: false,
    },
    {
        text: 'Asia/Beirut',
        value: 'Asia/Beirut',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '393',
        short_list: false,
    },
    {
        text: 'Asia/Aqtobe',
        value: 'Asia/Aqtobe',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '394',
        short_list: false,
    },
    {
        text: 'Asia/Anadyr',
        value: 'Asia/Anadyr',
        abbrev: '12',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '395',
        short_list: false,
    },
    {
        text: 'Asia/Bishkek',
        value: 'Asia/Bishkek',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '396',
        short_list: false,
    },
    {
        text: 'Asia/Dubai',
        value: 'Asia/Dubai',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '397',
        short_list: false,
    },
    {
        text: 'Asia/Riyadh',
        value: 'Asia/Riyadh',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '398',
        short_list: false,
    },
    {
        text: 'Asia/Novokuznetsk',
        value: 'Asia/Novokuznetsk',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '399',
        short_list: false,
    },
    {
        text: 'Asia/Aqtau',
        value: 'Asia/Aqtau',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '400',
        short_list: false,
    },
    {
        text: 'Asia/Omsk',
        value: 'Asia/Omsk',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '401',
        short_list: false,
    },
    {
        text: 'Asia/Ashkhabad',
        value: 'Asia/Ashkhabad',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '402',
        short_list: false,
    },
    {
        text: 'Asia/Saigon',
        value: 'Asia/Saigon',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '403',
        short_list: false,
    },
    {
        text: 'Asia/Sakhalin',
        value: 'Asia/Sakhalin',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '404',
        short_list: false,
    },
    {
        text: 'Asia/Hong_Kong',
        value: 'Asia/Hong_Kong',
        abbrev: 'HKT',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '405',
        short_list: false,
    },
    {
        text: 'Asia/Phnom_Penh',
        value: 'Asia/Phnom_Penh',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '406',
        short_list: false,
    },
    {
        text: 'Asia/Nicosia',
        value: 'Asia/Nicosia',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '407',
        short_list: false,
    },
    {
        text: 'Asia/Baghdad',
        value: 'Asia/Baghdad',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '408',
        short_list: false,
    },
    {
        text: 'Asia/Srednekolymsk',
        value: 'Asia/Srednekolymsk',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '409',
        short_list: false,
    },
    {
        text: 'Asia/Ashgabat',
        value: 'Asia/Ashgabat',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '410',
        short_list: false,
    },
    {
        text: 'Asia/Kathmandu',
        value: 'Asia/Kathmandu',
        abbrev: '545',
        utc_offset: '05:45:00',
        is_dst: false,
        key: '411',
        short_list: false,
    },
    {
        text: 'Asia/Choibalsan',
        value: 'Asia/Choibalsan',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '412',
        short_list: false,
    },
    {
        text: 'Asia/Bangkok',
        value: 'Asia/Bangkok',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '413',
        short_list: false,
    },
    {
        text: 'Asia/Chungking',
        value: 'Asia/Chungking',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '414',
        short_list: false,
    },
    {
        text: 'Asia/Novosibirsk',
        value: 'Asia/Novosibirsk',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '415',
        short_list: false,
    },
    {
        text: 'Asia/Famagusta',
        value: 'Asia/Famagusta',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '416',
        short_list: false,
    },
    {
        text: 'Asia/Rangoon',
        value: 'Asia/Rangoon',
        abbrev: '630',
        utc_offset: '06:30:00',
        is_dst: false,
        key: '417',
        short_list: false,
    },
    {
        text: 'Asia/Ho_Chi_Minh',
        value: 'Asia/Ho_Chi_Minh',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '418',
        short_list: false,
    },
    {
        text: 'MET',
        value: 'MET',
        abbrev: 'MEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '419',
        short_list: false,
    },
    {
        text: 'Portugal',
        value: 'Portugal',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '420',
        short_list: false,
    },
    {
        text: 'Antarctica/Palmer',
        value: 'Antarctica/Palmer',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '421',
        short_list: false,
    },
    {
        text: 'Antarctica/Davis',
        value: 'Antarctica/Davis',
        abbrev: '7',
        utc_offset: '07:00:00',
        is_dst: false,
        key: '422',
        short_list: false,
    },
    {
        text: 'Antarctica/Rothera',
        value: 'Antarctica/Rothera',
        abbrev: '-3',
        utc_offset: '-03:00:00',
        is_dst: false,
        key: '423',
        short_list: false,
    },
    {
        text: 'Antarctica/Vostok',
        value: 'Antarctica/Vostok',
        abbrev: '6',
        utc_offset: '06:00:00',
        is_dst: false,
        key: '424',
        short_list: false,
    },
    {
        text: 'Antarctica/Syowa',
        value: 'Antarctica/Syowa',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '425',
        short_list: false,
    },
    {
        text: 'Antarctica/DumontDUrville',
        value: 'Antarctica/DumontDUrville',
        abbrev: '10',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '426',
        short_list: false,
    },
    {
        text: 'Antarctica/McMurdo',
        value: 'Antarctica/McMurdo',
        abbrev: 'NZST',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '427',
        short_list: false,
    },
    {
        text: 'Antarctica/Macquarie',
        value: 'Antarctica/Macquarie',
        abbrev: 'AEST',
        utc_offset: '10:00:00',
        is_dst: false,
        key: '428',
        short_list: false,
    },
    {
        text: 'Antarctica/South_Pole',
        value: 'Antarctica/South_Pole',
        abbrev: 'NZST',
        utc_offset: '12:00:00',
        is_dst: false,
        key: '429',
        short_list: false,
    },
    {
        text: 'Antarctica/Troll',
        value: 'Antarctica/Troll',
        abbrev: '2',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '430',
        short_list: false,
    },
    {
        text: 'Antarctica/Mawson',
        value: 'Antarctica/Mawson',
        abbrev: '5',
        utc_offset: '05:00:00',
        is_dst: false,
        key: '431',
        short_list: false,
    },
    {
        text: 'Antarctica/Casey',
        value: 'Antarctica/Casey',
        abbrev: '11',
        utc_offset: '11:00:00',
        is_dst: false,
        key: '432',
        short_list: false,
    },
    {
        text: 'GMT-0',
        value: 'GMT-0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '433',
        short_list: false,
    },
    {
        text: 'CET',
        value: 'CET',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '434',
        short_list: false,
    },
    {
        text: 'Eire',
        value: 'Eire',
        abbrev: 'IST',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '435',
        short_list: false,
    },
    {
        text: 'PST8PDT',
        value: 'PST8PDT',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '436',
        short_list: false,
    },
    {
        text: 'Jamaica',
        value: 'Jamaica',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '437',
        short_list: false,
    },
    {
        text: 'GMT',
        value: 'GMT',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '438',
        short_list: false,
    },
    {
        text: 'Zulu',
        value: 'Zulu',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '439',
        short_list: false,
    },
    {
        text: 'Japan',
        value: 'Japan',
        abbrev: 'JST',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '440',
        short_list: false,
    },
    {
        text: 'ROC',
        value: 'ROC',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '441',
        short_list: false,
    },
    {
        text: 'GB-Eire',
        value: 'GB-Eire',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '442',
        short_list: false,
    },
    {
        text: 'Europe/Zurich',
        value: 'Europe/Zurich',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '443',
        short_list: false,
    },
    {
        text: 'Europe/Paris',
        value: 'Europe/Paris',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '444',
        short_list: false,
    },
    {
        text: 'Europe/Moscow',
        value: 'Europe/Moscow',
        abbrev: 'MSK',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '445',
        short_list: false,
    },
    {
        text: 'Europe/Luxembourg',
        value: 'Europe/Luxembourg',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '446',
        short_list: false,
    },
    {
        text: 'Europe/Ljubljana',
        value: 'Europe/Ljubljana',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '447',
        short_list: false,
    },
    {
        text: 'Europe/Helsinki',
        value: 'Europe/Helsinki',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '448',
        short_list: false,
    },
    {
        text: 'Europe/Minsk',
        value: 'Europe/Minsk',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '449',
        short_list: false,
    },
    {
        text: 'Europe/Skopje',
        value: 'Europe/Skopje',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '450',
        short_list: false,
    },
    {
        text: 'Europe/Dublin',
        value: 'Europe/Dublin',
        abbrev: 'IST',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '451',
        short_list: false,
    },
    {
        text: 'Europe/Jersey',
        value: 'Europe/Jersey',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '452',
        short_list: false,
    },
    {
        text: 'Europe/San_Marino',
        value: 'Europe/San_Marino',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '453',
        short_list: false,
    },
    {
        text: 'Europe/Gibraltar',
        value: 'Europe/Gibraltar',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '454',
        short_list: false,
    },
    {
        text: 'Europe/Belgrade',
        value: 'Europe/Belgrade',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '455',
        short_list: false,
    },
    {
        text: 'Europe/Guernsey',
        value: 'Europe/Guernsey',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '456',
        short_list: false,
    },
    {
        text: 'Europe/Ulyanovsk',
        value: 'Europe/Ulyanovsk',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '457',
        short_list: false,
    },
    {
        text: 'Europe/Saratov',
        value: 'Europe/Saratov',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '458',
        short_list: false,
    },
    {
        text: 'Europe/Vaduz',
        value: 'Europe/Vaduz',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '459',
        short_list: false,
    },
    {
        text: 'Europe/Istanbul',
        value: 'Europe/Istanbul',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '460',
        short_list: false,
    },
    {
        text: 'Europe/Lisbon',
        value: 'Europe/Lisbon',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '461',
        short_list: false,
    },
    {
        text: 'Europe/Uzhgorod',
        value: 'Europe/Uzhgorod',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '462',
        short_list: false,
    },
    {
        text: 'Europe/Kirov',
        value: 'Europe/Kirov',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '463',
        short_list: false,
    },
    {
        text: 'Europe/Tirane',
        value: 'Europe/Tirane',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '464',
        short_list: false,
    },
    {
        text: 'Europe/Tiraspol',
        value: 'Europe/Tiraspol',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '465',
        short_list: false,
    },
    {
        text: 'Europe/Sarajevo',
        value: 'Europe/Sarajevo',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '466',
        short_list: false,
    },
    {
        text: 'Europe/Madrid',
        value: 'Europe/Madrid',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '467',
        short_list: false,
    },
    {
        text: 'Europe/Podgorica',
        value: 'Europe/Podgorica',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '468',
        short_list: false,
    },
    {
        text: 'Europe/Busingen',
        value: 'Europe/Busingen',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '469',
        short_list: false,
    },
    {
        text: 'Europe/Vatican',
        value: 'Europe/Vatican',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '470',
        short_list: false,
    },
    {
        text: 'Europe/Belfast',
        value: 'Europe/Belfast',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '471',
        short_list: false,
    },
    {
        text: 'Europe/Bratislava',
        value: 'Europe/Bratislava',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '472',
        short_list: false,
    },
    {
        text: 'Europe/Kiev',
        value: 'Europe/Kiev',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '473',
        short_list: false,
    },
    {
        text: 'Europe/Kaliningrad',
        value: 'Europe/Kaliningrad',
        abbrev: 'EET',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '474',
        short_list: false,
    },
    {
        text: 'Europe/Zaporozhye',
        value: 'Europe/Zaporozhye',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '475',
        short_list: false,
    },
    {
        text: 'Europe/Vienna',
        value: 'Europe/Vienna',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '476',
        short_list: false,
    },
    {
        text: 'Europe/Budapest',
        value: 'Europe/Budapest',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '477',
        short_list: false,
    },
    {
        text: 'Europe/Vilnius',
        value: 'Europe/Vilnius',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '478',
        short_list: false,
    },
    {
        text: 'Europe/Monaco',
        value: 'Europe/Monaco',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '479',
        short_list: false,
    },
    {
        text: 'Europe/Oslo',
        value: 'Europe/Oslo',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '480',
        short_list: false,
    },
    {
        text: 'Europe/Astrakhan',
        value: 'Europe/Astrakhan',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '481',
        short_list: false,
    },
    {
        text: 'Europe/Simferopol',
        value: 'Europe/Simferopol',
        abbrev: 'MSK',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '482',
        short_list: false,
    },
    {
        text: 'Europe/Volgograd',
        value: 'Europe/Volgograd',
        abbrev: '3',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '483',
        short_list: false,
    },
    {
        text: 'Europe/Isle_of_Man',
        value: 'Europe/Isle_of_Man',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '484',
        short_list: false,
    },
    {
        text: 'Europe/London',
        value: 'Europe/London',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '485',
        short_list: false,
    },
    {
        text: 'Europe/Riga',
        value: 'Europe/Riga',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '486',
        short_list: false,
    },
    {
        text: 'Europe/Andorra',
        value: 'Europe/Andorra',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '487',
        short_list: false,
    },
    {
        text: 'Europe/Prague',
        value: 'Europe/Prague',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '488',
        short_list: false,
    },
    {
        text: 'Europe/Berlin',
        value: 'Europe/Berlin',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '489',
        short_list: false,
    },
    {
        text: 'Europe/Tallinn',
        value: 'Europe/Tallinn',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '490',
        short_list: false,
    },
    {
        text: 'Europe/Rome',
        value: 'Europe/Rome',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '491',
        short_list: false,
    },
    {
        text: 'Europe/Malta',
        value: 'Europe/Malta',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '492',
        short_list: false,
    },
    {
        text: 'Europe/Zagreb',
        value: 'Europe/Zagreb',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '493',
        short_list: false,
    },
    {
        text: 'Europe/Amsterdam',
        value: 'Europe/Amsterdam',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '494',
        short_list: false,
    },
    {
        text: 'Europe/Nicosia',
        value: 'Europe/Nicosia',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '495',
        short_list: false,
    },
    {
        text: 'Europe/Bucharest',
        value: 'Europe/Bucharest',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '496',
        short_list: false,
    },
    {
        text: 'Europe/Copenhagen',
        value: 'Europe/Copenhagen',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '497',
        short_list: false,
    },
    {
        text: 'Europe/Chisinau',
        value: 'Europe/Chisinau',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '498',
        short_list: false,
    },
    {
        text: 'Europe/Mariehamn',
        value: 'Europe/Mariehamn',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '499',
        short_list: false,
    },
    {
        text: 'Europe/Sofia',
        value: 'Europe/Sofia',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '500',
        short_list: false,
    },
    {
        text: 'Europe/Athens',
        value: 'Europe/Athens',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '501',
        short_list: false,
    },
    {
        text: 'Europe/Stockholm',
        value: 'Europe/Stockholm',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '502',
        short_list: false,
    },
    {
        text: 'Europe/Samara',
        value: 'Europe/Samara',
        abbrev: '4',
        utc_offset: '04:00:00',
        is_dst: false,
        key: '503',
        short_list: false,
    },
    {
        text: 'Europe/Brussels',
        value: 'Europe/Brussels',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '504',
        short_list: false,
    },
    {
        text: 'Europe/Warsaw',
        value: 'Europe/Warsaw',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '505',
        short_list: false,
    },
    {
        text: 'ROK',
        value: 'ROK',
        abbrev: 'KST',
        utc_offset: '09:00:00',
        is_dst: false,
        key: '506',
        short_list: false,
    },
    {
        text: 'Navajo',
        value: 'Navajo',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '507',
        short_list: false,
    },
    {
        text: 'Singapore',
        value: 'Singapore',
        abbrev: '8',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '508',
        short_list: false,
    },
    {
        text: 'posixrules',
        value: 'posixrules',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '509',
        short_list: false,
    },
    {
        text: 'GB',
        value: 'GB',
        abbrev: 'BST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '510',
        short_list: false,
    },
    {
        text: 'Mexico/BajaNorte',
        value: 'Mexico/BajaNorte',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '511',
        short_list: false,
    },
    {
        text: 'Mexico/General',
        value: 'Mexico/General',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '512',
        short_list: false,
    },
    {
        text: 'Mexico/BajaSur',
        value: 'Mexico/BajaSur',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '513',
        short_list: false,
    },
    {
        text: 'EST',
        value: 'EST',
        abbrev: 'EST',
        utc_offset: '-05:00:00',
        is_dst: false,
        key: '514',
        short_list: false,
    },
    {
        text: 'GMT0',
        value: 'GMT0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '515',
        short_list: false,
    },
    {
        text: 'Hongkong',
        value: 'Hongkong',
        abbrev: 'HKT',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '516',
        short_list: false,
    },
    {
        text: 'PRC',
        value: 'PRC',
        abbrev: 'CST',
        utc_offset: '08:00:00',
        is_dst: false,
        key: '517',
        short_list: false,
    },
    {
        text: 'Iran',
        value: 'Iran',
        abbrev: '430',
        utc_offset: '04:30:00',
        is_dst: true,
        key: '518',
        short_list: false,
    },
    {
        text: 'MST7MDT',
        value: 'MST7MDT',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '519',
        short_list: false,
    },
    {
        text: 'WET',
        value: 'WET',
        abbrev: 'WEST',
        utc_offset: '01:00:00',
        is_dst: true,
        key: '520',
        short_list: false,
    },
    {
        text: 'W-SU',
        value: 'W-SU',
        abbrev: 'MSK',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '521',
        short_list: false,
    },
    {
        text: 'UCT',
        value: 'UCT',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '522',
        short_list: false,
    },
    {
        text: 'Cuba',
        value: 'Cuba',
        abbrev: 'CDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '523',
        short_list: false,
    },
    {
        text: 'Egypt',
        value: 'Egypt',
        abbrev: 'EET',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '524',
        short_list: false,
    },
    {
        text: 'GMT+0',
        value: 'GMT+0',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '525',
        short_list: false,
    },
    {
        text: 'EET',
        value: 'EET',
        abbrev: 'EEST',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '526',
        short_list: false,
    },
    {
        text: 'Israel',
        value: 'Israel',
        abbrev: 'IDT',
        utc_offset: '03:00:00',
        is_dst: true,
        key: '527',
        short_list: false,
    },
    {
        text: 'Africa/Sao_Tome',
        value: 'Africa/Sao_Tome',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '528',
        short_list: false,
    },
    {
        text: 'Africa/Conakry',
        value: 'Africa/Conakry',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '529',
        short_list: false,
    },
    {
        text: 'Africa/Dakar',
        value: 'Africa/Dakar',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '530',
        short_list: false,
    },
    {
        text: 'Africa/Ndjamena',
        value: 'Africa/Ndjamena',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '531',
        short_list: false,
    },
    {
        text: 'Africa/Casablanca',
        value: 'Africa/Casablanca',
        abbrev: '1',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '532',
        short_list: false,
    },
    {
        text: 'Africa/Lome',
        value: 'Africa/Lome',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '533',
        short_list: false,
    },
    {
        text: 'Africa/Algiers',
        value: 'Africa/Algiers',
        abbrev: 'CET',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '534',
        short_list: false,
    },
    {
        text: 'Africa/Mogadishu',
        value: 'Africa/Mogadishu',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '535',
        short_list: false,
    },
    {
        text: 'Africa/Lagos',
        value: 'Africa/Lagos',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '536',
        short_list: false,
    },
    {
        text: 'Africa/Brazzaville',
        value: 'Africa/Brazzaville',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '537',
        short_list: false,
    },
    {
        text: 'Africa/Timbuktu',
        value: 'Africa/Timbuktu',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '538',
        short_list: false,
    },
    {
        text: 'Africa/Nouakchott',
        value: 'Africa/Nouakchott',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '539',
        short_list: false,
    },
    {
        text: 'Africa/Maseru',
        value: 'Africa/Maseru',
        abbrev: 'SAST',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '540',
        short_list: false,
    },
    {
        text: 'Africa/Libreville',
        value: 'Africa/Libreville',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '541',
        short_list: false,
    },
    {
        text: 'Africa/Harare',
        value: 'Africa/Harare',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '542',
        short_list: false,
    },
    {
        text: 'Africa/Malabo',
        value: 'Africa/Malabo',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '543',
        short_list: false,
    },
    {
        text: 'Africa/Bangui',
        value: 'Africa/Bangui',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '544',
        short_list: false,
    },
    {
        text: 'Africa/Nairobi',
        value: 'Africa/Nairobi',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '545',
        short_list: false,
    },
    {
        text: 'Africa/Kinshasa',
        value: 'Africa/Kinshasa',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '546',
        short_list: false,
    },
    {
        text: 'Africa/Porto-Novo',
        value: 'Africa/Porto-Novo',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '547',
        short_list: false,
    },
    {
        text: 'Africa/Cairo',
        value: 'Africa/Cairo',
        abbrev: 'EET',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '548',
        short_list: false,
    },
    {
        text: 'Africa/Douala',
        value: 'Africa/Douala',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '549',
        short_list: false,
    },
    {
        text: 'Africa/Juba',
        value: 'Africa/Juba',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '550',
        short_list: false,
    },
    {
        text: 'Africa/Gaborone',
        value: 'Africa/Gaborone',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '551',
        short_list: false,
    },
    {
        text: 'Africa/Tunis',
        value: 'Africa/Tunis',
        abbrev: 'CET',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '552',
        short_list: false,
    },
    {
        text: 'Africa/Kampala',
        value: 'Africa/Kampala',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '553',
        short_list: false,
    },
    {
        text: 'Africa/Mbabane',
        value: 'Africa/Mbabane',
        abbrev: 'SAST',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '554',
        short_list: false,
    },
    {
        text: 'Africa/Addis_Ababa',
        value: 'Africa/Addis_Ababa',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '555',
        short_list: false,
    },
    {
        text: 'Africa/Maputo',
        value: 'Africa/Maputo',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '556',
        short_list: false,
    },
    {
        text: 'Africa/Bissau',
        value: 'Africa/Bissau',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '557',
        short_list: false,
    },
    {
        text: 'Africa/Blantyre',
        value: 'Africa/Blantyre',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '558',
        short_list: false,
    },
    {
        text: 'Africa/Niamey',
        value: 'Africa/Niamey',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '559',
        short_list: false,
    },
    {
        text: 'Africa/Banjul',
        value: 'Africa/Banjul',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '560',
        short_list: false,
    },
    {
        text: 'Africa/Abidjan',
        value: 'Africa/Abidjan',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '561',
        short_list: false,
    },
    {
        text: 'Africa/Asmara',
        value: 'Africa/Asmara',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '562',
        short_list: false,
    },
    {
        text: 'Africa/Bamako',
        value: 'Africa/Bamako',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '563',
        short_list: false,
    },
    {
        text: 'Africa/Ouagadougou',
        value: 'Africa/Ouagadougou',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '564',
        short_list: false,
    },
    {
        text: 'Africa/Lusaka',
        value: 'Africa/Lusaka',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '565',
        short_list: false,
    },
    {
        text: 'Africa/Luanda',
        value: 'Africa/Luanda',
        abbrev: 'WAT',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '566',
        short_list: false,
    },
    {
        text: 'Africa/Asmera',
        value: 'Africa/Asmera',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '567',
        short_list: false,
    },
    {
        text: 'Africa/Lubumbashi',
        value: 'Africa/Lubumbashi',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '568',
        short_list: false,
    },
    {
        text: 'Africa/Accra',
        value: 'Africa/Accra',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '569',
        short_list: false,
    },
    {
        text: 'Africa/Khartoum',
        value: 'Africa/Khartoum',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '570',
        short_list: false,
    },
    {
        text: 'Africa/Ceuta',
        value: 'Africa/Ceuta',
        abbrev: 'CEST',
        utc_offset: '02:00:00',
        is_dst: true,
        key: '571',
        short_list: false,
    },
    {
        text: 'Africa/Bujumbura',
        value: 'Africa/Bujumbura',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '572',
        short_list: false,
    },
    {
        text: 'Africa/Windhoek',
        value: 'Africa/Windhoek',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '573',
        short_list: false,
    },
    {
        text: 'Africa/El_Aaiun',
        value: 'Africa/El_Aaiun',
        abbrev: '1',
        utc_offset: '01:00:00',
        is_dst: false,
        key: '574',
        short_list: false,
    },
    {
        text: 'Africa/Tripoli',
        value: 'Africa/Tripoli',
        abbrev: 'EET',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '575',
        short_list: false,
    },
    {
        text: 'Africa/Monrovia',
        value: 'Africa/Monrovia',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '576',
        short_list: false,
    },
    {
        text: 'Africa/Dar_es_Salaam',
        value: 'Africa/Dar_es_Salaam',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '577',
        short_list: false,
    },
    {
        text: 'Africa/Johannesburg',
        value: 'Africa/Johannesburg',
        abbrev: 'SAST',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '578',
        short_list: false,
    },
    {
        text: 'Africa/Kigali',
        value: 'Africa/Kigali',
        abbrev: 'CAT',
        utc_offset: '02:00:00',
        is_dst: false,
        key: '579',
        short_list: false,
    },
    {
        text: 'Africa/Djibouti',
        value: 'Africa/Djibouti',
        abbrev: 'EAT',
        utc_offset: '03:00:00',
        is_dst: false,
        key: '580',
        short_list: false,
    },
    {
        text: 'Africa/Freetown',
        value: 'Africa/Freetown',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '581',
        short_list: false,
    },
    {
        text: 'Factory',
        value: 'Factory',
        abbrev: '0',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '582',
        short_list: false,
    },
    {
        text: 'UTC',
        value: 'UTC',
        abbrev: 'UTC',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '583',
        short_list: false,
    },
    {
        text: 'Chile/EasterIsland',
        value: 'Chile/EasterIsland',
        abbrev: '-6',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '584',
        short_list: false,
    },
    {
        text: 'Chile/Continental',
        value: 'Chile/Continental',
        abbrev: '-4',
        utc_offset: '-04:00:00',
        is_dst: false,
        key: '585',
        short_list: false,
    },
    {
        text: 'HST',
        value: 'HST',
        abbrev: 'HST',
        utc_offset: '-10:00:00',
        is_dst: false,
        key: '586',
        short_list: false,
    },
    {
        text: 'Canada/Atlantic',
        value: 'Canada/Atlantic',
        abbrev: 'ADT',
        utc_offset: '-03:00:00',
        is_dst: true,
        key: '587',
        short_list: false,
    },
    {
        text: 'Canada/Pacific',
        value: 'Canada/Pacific',
        abbrev: 'PDT',
        utc_offset: '-07:00:00',
        is_dst: true,
        key: '588',
        short_list: false,
    },
    {
        text: 'Canada/Eastern',
        value: 'Canada/Eastern',
        abbrev: 'EDT',
        utc_offset: '-04:00:00',
        is_dst: true,
        key: '589',
        short_list: false,
    },
    {
        text: 'Canada/Yukon',
        value: 'Canada/Yukon',
        abbrev: 'MST',
        utc_offset: '-07:00:00',
        is_dst: false,
        key: '590',
        short_list: false,
    },
    {
        text: 'Canada/Saskatchewan',
        value: 'Canada/Saskatchewan',
        abbrev: 'CST',
        utc_offset: '-06:00:00',
        is_dst: false,
        key: '591',
        short_list: false,
    },
    {
        text: 'Canada/Newfoundland',
        value: 'Canada/Newfoundland',
        abbrev: 'NDT',
        utc_offset: '-02:30:00',
        is_dst: true,
        key: '592',
        short_list: false,
    },
    {
        text: 'Canada/Central',
        value: 'Canada/Central',
        abbrev: 'CDT',
        utc_offset: '-05:00:00',
        is_dst: true,
        key: '593',
        short_list: false,
    },
    {
        text: 'Canada/Mountain',
        value: 'Canada/Mountain',
        abbrev: 'MDT',
        utc_offset: '-06:00:00',
        is_dst: true,
        key: '594',
        short_list: false,
    },
    {
        text: 'Iceland',
        value: 'Iceland',
        abbrev: 'GMT',
        utc_offset: '00:00:00',
        is_dst: false,
        key: '595',
        short_list: false,
    },
];

export default TimezoneList;

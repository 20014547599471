import React from 'react';
import { Form } from 'semantic-ui-react';

const AddressForm = ({
    label,
    street_number,
    street,
    city,
    state,
    state_options,
    zip,
    onChange
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                label="Address Label"
                placeholder="Ex. Corporate Headquarters"
                value={label}
                onChange={onChange}
                name="label"
            />
            <Form.Input
                label="Street #"
                placeholder="Enter street #"
                value={street_number}
                onChange={onChange}
                name="street_number"
            />
            <Form.Input
                label="Street"
                placeholder="Enter street"
                value={street}
                onChange={onChange}
                name="street"
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Input
                label="City"
                placeholder="Enter city"
                value={city}
                onChange={onChange}
                name="city"
            />
            <Form.Select
                label="State"
                placeholder="Enter state"
                value={state}
                onChange={onChange}
                name="state"
                options={state_options}
            />
            <Form.Input
                label="Zipcode"
                placeholder="Enter zipcode"
                value={zip}
                onChange={onChange}
                name="zip"
            />
        </Form.Group>
    </React.Fragment>
);

export default AddressForm;

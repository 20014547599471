import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import './index.scss';

const EllipsisDropdown = ({ children, PermissionIndex, isDropdownOpen, toggleEllipsisDropdown }) => {
    return (
        <Dropdown
            className='EllipsisHorizontal'
            index={PermissionIndex}
            open={isDropdownOpen}
            onClick={toggleEllipsisDropdown}
            onBlur={toggleEllipsisDropdown}
            button
            basic
            direction='left'
            icon={null}
            text={<Icon name='ellipsis horizontal' size='large' />}
        >
            <Dropdown.Menu onClick={event => { event.stopPropagation() }}>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default EllipsisDropdown;

import React from 'react';
import { Menu, Label } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import './index.scss';


const TimelineTabHeader = ({ titleCounts, activeItem, onClick }) => {
    return(
    <React.Fragment>
        <div className='TimelineTab__Header'>
            <Menu pointing secondary className='TimelineTab__Menu'>
                {titleCounts.map(tc => (
                    <Menu.Item
                        name={tc.title}
                        active={activeItem === tc.title}
                        onClick={onClick}
                    >
                        {StringUtils.capitalize(tc.title)}
                    {tc.count !== undefined && 
                        <Label className='TimelineTab__Header__Count'>{tc.count}</Label>
                    }
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    </React.Fragment>
)};

export default TimelineTabHeader;

import React from 'react';
import { Segment, Grid, Icon, Transition, Header, Popup } from 'semantic-ui-react';
import './index.scss';

const FilterableList = ({ size = 'tiny', controlsWidth=4, listComponent, controls, header, isCollapsed, onToggleCollapsed }) => (
    <Segment className='FilterableList' size={size}>
        <Grid>
            <Grid.Row columns='equal' verticalAlign='top'>
                <Grid.Column>
                    <Header className='FilterableList__header'>{header}</Header>
                </Grid.Column>
                <Grid.Column width={controlsWidth} textAlign='right'>
                    {controls} <Popup 
                        trigger={<div className='FilterableList__collapsed-arrow-container'>
                            <Icon className='FilterableList__collapsed-arrow' onClick={onToggleCollapsed} link circular name={`chevron ${isCollapsed ? 'right' : 'down'}`} />
                        </div>}
                        content={isCollapsed ? 'Expand' : 'Collapse'}
                        inverted
                        position='top center'
                    />
                </Grid.Column>
            </Grid.Row>
            <Transition visible={!isCollapsed} animation='slide down' duration='150' unmountOnHide>
                <Grid.Row>
                    <Grid.Column>
                        {listComponent}
                    </Grid.Column>
                </Grid.Row>
            </Transition>
        </Grid>
    </Segment>
);

export default FilterableList;

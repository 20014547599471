import React from 'react';
import Avatar from 'react-avatar';
import {Popup} from 'semantic-ui-react';
import '../index.scss';

const DYLAvatar = ({name, email, picture, maxInitials = 2, size = '22px', textSizeRatio = 2, position = 'bottom center', disabled = false }) => {
    return (
        <Popup
            trigger={
                <span style={{marginRight: 5}}>
                <Avatar name={name} maxInitials={maxInitials} className={'sb-avatar'} src={picture} round size={size} textSizeRatio={textSizeRatio} />
                </span>}
            content={
                <div>
                    {name}
                    <br/>
                    {email}
                </div>}
            position={position}
            inverted
            disabled={disabled}
        />
    )
}
export default DYLAvatar;
import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const Panel = ({ header, body, loading, isBodyPadded = true }) => (
    <Segment.Group className='Panel' size='tiny'>
        <Segment className={`Panel__body ${isBodyPadded ? '' : 'Panel__body--not-padded'}` } loading={loading}>
            <Segment className='Panel__header'>
                {header}
            </Segment>
            {body}
        </Segment>
    </Segment.Group>
);

export default Panel;

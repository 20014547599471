import React from 'react';
import { Form } from 'semantic-ui-react';
import MultipleField from '../../molecules/MultipleField';

const MainContactPersonForm = ({
    first_name,
    last_name,
    title,
    role,
    role_options = [
        { key: 'none', value: 'none', text: 'None' },
        { key: 'decision_maker', value: 'decision_maker', text: 'Decision Maker' },
        { key: 'influencer', value: 'influencer', text: 'Influencer' }
    ],

    onChange,

    phones,
    emails,
    faxes,
    onChangeItem,
    onToggleMainItem,
    onRemoveItem,
    onAddItem,
    type
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                label="First Name"
                name="first_name"
                value={first_name}
                onChange={onChange}
                placeholder={`Enter first name`}
            />
            <Form.Input
                label="Last Name"
                name="last_name"
                value={last_name}
                onChange={onChange}
                placeholder={`Enter last name`}
            />
            <Form.Input
                name="title"
                value={title}
                onChange={onChange}
                label="Title"
                placeholder={`Enter ${type.toLowerCase()} title`}
            />
            <Form.Select
                name="role"
                value={role}
                onChange={onChange}
                label="Role of Contact"
                options={role_options}
                placeholder="Select role"
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Field
                control={MultipleField}
                items={phones}
                name="phones"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add phone"
                hasMain
                label="Phone"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={emails}
                name="emails"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add email"
                hasMain
                label="Email"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={faxes}
                name="faxes"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add fax"
                label="Faxes"
                fluid
            />
        </Form.Group>
    </React.Fragment>
);

export default MainContactPersonForm;

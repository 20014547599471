import React from 'react';
import { Select, Dropdown } from 'semantic-ui-react';

const CategorizedDropdown = ({ categories, defaultOption = null, ...otherProps }) => (
    <Select
        options={[
            defaultOption,
            ...categories.map(category => ([
                { key: category.name, value: category.name, text: category.name, as: Dropdown.Header, disabled: true },
                ...category.options
            ])).flat()
        ]}
        {...otherProps}
    />
);

export default CategorizedDropdown;

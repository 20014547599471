import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import AddressForm from '../AddressForm';
import { DividingHeader, MultipleField } from 'dyl-components';
import MainContactPersonForm from '../MainContactPersonForm';

const AddBusinessForm = ({
    business_name,
    business_type,

    industry,
    industry_options,

    onChange,

    phones,
    emails,
    faxes,
    onChangeItem,
    onToggleMainItem,
    onRemoveItem,
    onAddItem,

    websites,

    address = {
        label: '',
        street_number: '',
        street: '',
        city: '',
        state: '',
        zip: ''
    },
    state_options,
    business_contact = {
        name: '',
        emails: [{ id: 1, main: false, value: '' }],
        phones: [{ id: 1, main: false, value: '' }],
        faxes: [{ id: 1, value: '' }],
        title: '',
        role: 'none'
    },
    onChangeBusinessContactDetail,
    onChangeBusinessContactItem,
    onAddBusinessContactItem,
    onRemoveBusinessContactItem,
    onToggleMainBusinessContactItem,
    primaryContactField,

    onChangeAddress,

    current_customer,
    add_to_hotlist,

    labelsField
    
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                name="business_name"
                value={business_name}
                onChange={onChange}
                label="Business Name"
                placeholder="Enter business name"
            />
            <Form.Input
                name="business_type"
                value={business_type}
                onChange={onChange}
                label="Business type"
                placeholder="Enter business type"
            />
            <Form.Select
                name="industry"
                value={industry}
                onChange={onChange}
                label="Industry"
                selectOnBlur={false}
                options={industry_options}
                placeholder="Select industry"
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Field
                control={MultipleField}
                items={phones}
                name="phones"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add phone"
                hasMain
                label="Phone"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={emails}
                name="emails"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add email"
                hasMain
                label="Email"
                fluid
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Field
                control={MultipleField}
                items={websites}
                name="websites"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add website"
                label="Website"
                fluid
            />
            <Form.Field
                control={MultipleField}
                items={faxes}
                name="faxes"
                onChange={onChangeItem}
                onRemove={onRemoveItem}
                onToggleMain={onToggleMainItem}
                onAdd={onAddItem}
                placeholder="Add fax"
                label="Faxes"
                fluid
            />
        </Form.Group>
        <DividingHeader content="Business Address" />
        <AddressForm
            city={address.city}
            label={address.label}
            onChange={onChangeAddress}
            state={address.state}
            street={address.street}
            street_number={address.street_number}
            zip={address.zip}
            state_options={state_options}
        />
        <DividingHeader content="Primary Contact" />
        {primaryContactField}
        <DividingHeader content="OR Add a New Contact" />
        <MainContactPersonForm
            name={business_contact.name}
            role={business_contact.role}
            title={business_contact.title}
            onChange={onChangeBusinessContactDetail}

            emails={business_contact.emails}
            faxes={business_contact.faxes}
            phones={business_contact.phones}
            onAddItem={onAddBusinessContactItem}
            onChangeItem={onChangeBusinessContactItem}
            onRemoveItem={onRemoveBusinessContactItem}
            onToggleMainItem={onToggleMainBusinessContactItem}
            type='Business'
        />
        <Form.Group widths="equal">
            <Form.Field>
                <label>Current Customer</label>
                <Checkbox
                    name="current_customer"
                    checked={current_customer}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            <Form.Field>
                <label>Add to Hotlist</label>
                <Checkbox
                    control
                    name="add_to_hotlist"
                    checked={add_to_hotlist}
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                    toggle
                />
            </Form.Field>
            {labelsField}
        </Form.Group>
    </React.Fragment>
);

export default AddBusinessForm;

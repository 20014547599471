import React from 'react';
import { Grid, Segment, Icon } from 'semantic-ui-react';
import './FormTab.scss';

const FormTab = ({ color, formHeader, formBody, formFooter, maximizable, isMaximized, onMaximize = () => { } }) => (
    <Segment as={Grid} className='FormTab' style={{ borderTopColor: `${color}` }}>
        {maximizable ? (
            <div className="FormTab__ArrowContainer">
                <Icon
                    className='FormTab__Arrow'
                    color="blue"
                    size="large"
                    onClick={onMaximize}
                    name={`chevron circle ${isMaximized ? 'right' : 'left'}`}
                />
            </div>
        ) : null}
        <Grid.Row className='FormTab__Header'>
            {formHeader}
        </Grid.Row>

        {formBody &&
            <Grid.Row columns='equal' className='FormTab__Row'>
                {formBody}
            </Grid.Row>
        }

        {formFooter &&
            <Grid.Row columns='equal' className='FormTab__Row'>
                {formFooter}
            </Grid.Row>
        }
    </Segment>
);

export default FormTab;

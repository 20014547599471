import React from 'react';
import { Segment, Grid, List, Divider, Header, Icon, Image } from 'semantic-ui-react';

import './index.scss';

export const ListTopHeaderBottomContent = ({ topLeft, topRight, bottom }) => {
    return (
        <Segment className="SegmentTopLabelToolsBottomContent">
            <Grid divided='vertically' verticalAlign="middle">
                <Grid.Row columns={2}>
                    <Grid.Column floated='left' className="SegmentTopLabelToolsBottomContent__top-left">
                        <Header as='h2' textAlign="left" className="SegmentHeader">
                            {topLeft}
                        </Header>
                    </Grid.Column>
                    <Grid.Column floated='right' textAlign="right"
                        className="SegmentTopLabelToolsBottomContent__top-right">
                        {topRight}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider></Divider>
            <Grid className="SegmentTopLabelToolsBottomContent__bottom SegmentTopLabelToolsBottomContent__bottom--list-container">
                <Grid.Row>
                    <Grid.Column>
                        {bottom}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export const ListTopHeaderBottomContentNoBorder = ({ style, topLeft, topRight, bottom, topLine = true, bottomListContainer = true, classNameBottom }) => {
    return (
        <Segment style={style} className="SegmentTopLabelToolsBottomContent SegmentTopLabelToolsBottomContent--no-border">
            <Grid divided='vertically' verticalAlign="middle">
                <Grid.Row columns={2}>
                    {topLine && <div className='SegmentTopLabelToolsBottomContent__top-line'></div>}
                    <Grid.Column floated='left' className="SegmentTopLabelToolsBottomContent__top-left">
                        <Header as='h3' textAlign="left" className="SegmentHeader">
                            {topLeft}
                        </Header>
                    </Grid.Column>
                    <Grid.Column floated='right' textAlign="right"
                        className="SegmentTopLabelToolsBottomContent__top-right">
                        {topRight}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider className='SegmentTopLabelToolsBottomContent__divider'></Divider>
            <Grid className={
                "SegmentTopLabelToolsBottomContent__bottom " +
                (bottomListContainer && "SegmentTopLabelToolsBottomContent__bottom--list-container ") +
                (classNameBottom ? classNameBottom : '')}>
                <Grid.Row>
                    <Grid.Column>
                        {bottom}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export const ListItemMiddleContentRightOptions = ({ subheader, text, hasIcon = true }) => {
    return (
        <List.Item className="ListItem">
            {hasIcon &&
                <List.Content floated='right'>
                    <Icon link name='ellipsis vertical' size="large" />
                </List.Content>
            }
            <div className='ListItem__left'>
                <Image avatar src='https://react.semantic-ui.com/images/avatar/small/lena.png' />
            </div>
            <div className='ListItem__middle'>
                <List.Content>
                    <List.Header className='ListItem__middle-header'>{text}</List.Header>
                    {subheader}
                </List.Content>
            </div>
        </List.Item>
    )
}

export const ListItem = ({ circular, children, className, draggableProps }) => (
    <List.Item {...draggableProps} className={`ListItem ${circular ? 'ListItem--circular' : ''} ${className}`}>
        { children}
    </List.Item>
)

ListItem.Content = ({ children, className, floated }) => (
    <List.Content floated={floated} className={className}>
        {children}
    </List.Content>
)

ListItem.Header = ({ children, className }) => (
    <List.Header className={className}>{children}</List.Header>
)

import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import './index.scss';

const GridTable = ({ children }) => (
    <div style={{width: '100%', padding: 0}} className='GridTable'>
        {children}
    </div>
)

GridTable.Header = ({ children }) => (
    <Segment basic className='GridTable__header'>
        {children}
    </Segment>
)

GridTable.Body = ({ children, padded='vertically', className, celled }) => (
    <Grid columns='equal' stackable padded={padded} className={`GridTable__body ${className}`} celled={celled}>
        {children}
    </Grid>
)

GridTable.Row = ({ children, className, style }) => (
    <Grid.Row className={`GridTable__row ${className}`} style={style} verticalAlign='middle'>
        {children}
    </Grid.Row>
)

GridTable.Column = ({ children, className, width, textAlign }) => (
    <Grid.Column className={`GridTable__column ${className}`} width={width} textAlign={textAlign}>
        {children}
    </Grid.Column>
)

export default GridTable;

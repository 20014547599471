import React, { useState } from 'react';
import { Modal } from 'dyl-components';
import { Button } from 'semantic-ui-react';

import { ToggleCategory } from 'dyl-components';

import './index.scss';

const Selection = ({ selectionHeader, categories, setActiveContent, close, hasCancel, hasFirstSelection }) => {
    const getSelectionInitialValue = () => (hasFirstSelection ? categories[0]?.selections[0]?.key || '' : null)

    const [active, setActive] = useState(getSelectionInitialValue());

    const handleClick = (_, { value }) => {
        setActive(value);
    };

    const onSelect = () => {
        setActiveContent(active);
        setActive(getSelectionInitialValue());
    }

    const onModalClose = () => {
        setActive(getSelectionInitialValue());
        close();
    };

    return (
        <React.Fragment>
            <Modal.Header>
                {selectionHeader}
            </Modal.Header>
            <Modal.Content>
                <div className='AddModulesRecordsModal__Selection'>
                    {categories.map(category => (
                        {
                            ...category, selections: category.selections.map(selection => (
                                { ...selection, active: selection.key === active ? true : false }
                            ))
                        }
                    )).map((category, index) => (
                        <ToggleCategory
                            key={index}
                            header={category.header}
                            selections={category.selections}
                            handleClick={handleClick}
                        />
                    ))}
                </div>
            </Modal.Content>
            <Modal.Actions>
                {hasCancel && <Button basic onClick={onModalClose}>Cancel</Button>}
                <Button disabled={active ? false : true} primary onClick={onSelect}>Next</Button>
            </Modal.Actions>
        </React.Fragment>
    );
}

export default Selection;

import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Form, Grid, Header, Icon, Ref, Segment } from 'semantic-ui-react';


const DynamicField = ({ type, hidden, required, states, preview, label, options, changeFieldBeingModified = () => { } }) => {
    const readOnly = !preview && (!hidden);
    switch (type) {
        case 'text':
        case 'phone':
        case 'email':
            return (
                <Form.Input
                    readOnly={readOnly}
                    type={type}
                    label={label}
                    required={preview && required}
                />
            )
        case 'address':
            return (
                <React.Fragment>
                    {preview && (
                        <Form.Field
                            label={label}
                        />
                    )}
                    <Form.Group widths='equal'>
                        <Form.Input
                            placeholder='Street #'
                            fluid
                            readOnly={readOnly}
                        />
                        <Form.Input
                            placeholder='Street'
                            readOnly={readOnly}
                        />
                    </Form.Group>
                    <Form.Input
                        placeholder='City'
                        readOnly={readOnly}
                    />
                    <Form.Group widths='equal'>
                        <Form.Select
                            onFocus={() => { changeFieldBeingModified(); }}
                            placeholder={'State'}
                            header={'State'}
                            options={
                                states.map(({ key, text }) => ({
                                    key, value: key, text
                                }))
                            }
                            readOnly={readOnly}
                            {...(!readOnly ? {} : { open: false })}
                            required={preview && required}
                            search
                        />
                        <Form.Input
                            placeholder='Zip'
                            readOnly={readOnly}
                            required={preview && required}
                        />
                    </Form.Group>
                </React.Fragment>
            );
        case 'dropdown':
        case 'picklist':
            return (
                <Form.Select
                    onFocus={() => { changeFieldBeingModified(); }}
                    readOnly={readOnly}
                    type={type}
                    label={label}
                    required={preview && required}
                    selectOnBlur={false}
                    options={options}
                    multiple={type === 'picklist'}
                    search
                />
            )
        case 'date':
            return <Form.Input
                type="date"
                readOnly={readOnly}
                required={preview && required}
                label={label}
            />
        default:
            return null;
    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const ActiveFields = ({ onChange, activeFields, onToggle, states, fieldBeingModified, setFieldBeingModified, preview }) => {
    const onChangeField = (field_name, field_property, new_value) => {
        const fields = activeFields.slice(0);
        const indexOfFieldToChange = activeFields.findIndex(activeField => activeField.name === field_name);
        if (indexOfFieldToChange !== -1) {
            fields[indexOfFieldToChange] = { ...fields[indexOfFieldToChange], [field_property]: new_value };
            onChange(fields)
        }
    }

    if (preview) {
        return activeFields.map((field) => {
            return (
                !field.hidden && (
                    <DynamicField
                        key={field.name}
                        type={field.type}
                        required={field.required}
                        hidden={field.hidden}
                        states={states}
                        preview
                        label={field.label}
                        options={field.options}
                    />
                )
            )
        })
    }


    const onChangeMultipleFieldProperties = (field_name, properties_values) => {
        const fields = activeFields.slice(0);
        const indexOfFieldToChange = activeFields.findIndex(activeField => activeField.name === field_name);
        if (indexOfFieldToChange !== -1) {
            fields[indexOfFieldToChange] = { ...fields[indexOfFieldToChange], ...properties_values };
            onChange(fields)
        }
    }

    const changeFieldBeingModified = (field) => {
        setFieldBeingModified(field);
    }

    return (
        activeFields.length === 0 ? (
            <Segment className='Webform--empty' basic textAlign='center'>
                <Header as='h2' icon>
                    <Icon className='fas fa-edit' circular size='huge' />
                    No Form Created
                    <Header.Subheader>
                        Add a field by tapping the (+) icon on the left
                    </Header.Subheader>
                </Header>
            </Segment>
        ) : (
            <DragDropContext onDragEnd={(result) => {
                if (!result.destination) {
                    return;
                }

                const fields = reorder(
                    activeFields,
                    result.source.index,
                    result.destination.index
                );

                onChange(fields);
            }}>
                <Droppable droppableId="Webform__fields">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {activeFields.map((field, index) => {
                                const isBeingModified = fieldBeingModified === field.name;
                                return (
                                    <Draggable key={field.name} draggableId={field.name} index={index}>
                                        {(provided, snapshot) => (
                                            <Ref innerRef={provided.innerRef}>
                                                <Segment
                                                    {...provided.draggableProps}
                                                    basic={!snapshot.isDragging}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        ...(!snapshot.isDragging ? { padding: 0, margin: 0 } : {
                                                            left: `calc(${provided.draggableProps.style.left} - 260px)`
                                                        })
                                                    }}
                                                    onClick={() => { changeFieldBeingModified(field.name); }}
                                                >
                                                    <Grid verticalAlign='middle'>
                                                        <Grid.Column width={1}>
                                                            {isBeingModified && (
                                                                <Icon
                                                                    {...provided.dragHandleProps}
                                                                    className='fas fa-arrows-alt'
                                                                    color='grey' />
                                                            )}
                                                        </Grid.Column>
                                                        <Grid.Column width={15}>
                                                            <Form.Group style={{ verticalAlign: 'bottom' }} widths={16}>
                                                                <Form.Input
                                                                    width={4}
                                                                    name={'label'}
                                                                    compact
                                                                    value={field.label}
                                                                    onChange={(_, { value }) => {
                                                                        onChangeField(field.name, 'label', value);
                                                                    }}
                                                                    onBlur={() => {
                                                                        if (field.label?.trim() === '') {
                                                                            onChangeField(field.name, 'label', field.original_label);
                                                                        }
                                                                    }}
                                                                    inverted
                                                                    size='tiny'
                                                                    {...field.icon ? {
                                                                        icon: field.icon,
                                                                        iconPosition: 'left'
                                                                    } : {}} />
                                                                {field.required && (
                                                                    <Form.Field
                                                                        width={15}
                                                                        style={{ textAlign: 'right', marginTop: '0.95em' }}
                                                                    >
                                                                        Required <Icon size='tiny' color='red' className='fas fa-asterisk' />
                                                                    </Form.Field>
                                                                )}
                                                            </Form.Group>
                                                            <DynamicField
                                                                type={field.type}
                                                                required={field.required}
                                                                hidden={field.hidden}
                                                                states={states}
                                                                options={field.options}
                                                                changeFieldBeingModified={() => { changeFieldBeingModified(field.name) }}
                                                            />
                                                            {isBeingModified && (
                                                                <Form.Group className='primary' widths={6}>
                                                                    <Form.Radio
                                                                        label='Required'
                                                                        onClick={() => {
                                                                            if (field.hidden) {
                                                                                onChangeMultipleFieldProperties(field.name, {
                                                                                    required: true,
                                                                                    hidden: false
                                                                                })
                                                                            } else {
                                                                                onChangeField(field.name, 'required', !field.required);
                                                                            }
                                                                        }}
                                                                        checked={field.required} />
                                                                    <Form.Radio
                                                                        label='Hidden'
                                                                        onClick={() => {
                                                                            if (field.required) {
                                                                                onChangeMultipleFieldProperties(field.name, {
                                                                                    required: false,
                                                                                    hidden: true
                                                                                })
                                                                            } else {
                                                                                onChangeField(field.name, 'hidden', !field.hidden);
                                                                            }
                                                                        }}
                                                                        checked={field.hidden} />
                                                                    <Form.Field
                                                                        onClick={() => {
                                                                            onToggle(field);
                                                                        }}
                                                                        style={{ cursor: 'pointer', color: '#CC3366' }}
                                                                    >
                                                                        <Icon className='fas fa-circle-minus' /> Remove
                                                                    </Form.Field>
                                                                </Form.Group>
                                                            )}
                                                        </Grid.Column>
                                                    </Grid>
                                                </Segment>
                                            </Ref>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    )
}

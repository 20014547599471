import React from 'react';
import { generateResolver, yup } from "dyl-components/atoms/ValidationUtils";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Segment } from "semantic-ui-react";

const PowerSelectors = ({ children }) => {
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            filters: {}
        },
        resolver: generateResolver({
            filters: yup.object().test(
                "should_have_at_least_one_filter", "At least one filter should be active", (filters) => {
                    return Object.keys(filters).length > 0;
                }
            )
        })
    });

    const { formState: { isValid, isDirty } } = methods;

    return (
        <Segment.Group>
            <Segment size="mini" padded>
                <FormProvider {...methods}>
                    {children}
                </FormProvider> 
            </Segment>
            <Segment textAlign="right">
                <Button basic color="primary">
                    Clear Filters
                </Button>
                <Divider vertical />
                <Button disabled={!isValid || !isDirty} color="primary">Apply</Button>
            </Segment>
        </Segment.Group>
    );
};

export default PowerSelectors;

import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { NumberedContainer, AndOrToggle } from 'dyl-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './index.scss';

const SegmentNumberedContainer = ({ children, segments = []}) => {
  const [selectedValue, setSelectedValue] = useState('and');

  const handleOptionChange = (newValue) => {
    setSelectedValue(newValue);
  };

  // Define the options array
  const options = [
    { key: 'and', text: 'And', value: 'and'},
    { key: 'or', text: 'Or', value: 'or' },
  ];

  return (

    <Segment className='SegmentNumberedContainer__Wrapper'>
      {Array.from({ length: segments }).map((_, index) => {
        const modifiedOptions = options.map(option => ({
          ...option,
          key: `${index + 1}-${option.value}`,
        }));

        return (
          <React.Fragment key={index}>
            <NumberedContainer count={index}>
              {children}
            </NumberedContainer>
            {index !== Array.from({ length: segments }).length - 1 ? (
              <AndOrToggle
                options={options} value={selectedValue} onChange={handleOptionChange}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        );
      })}
    </Segment>
  );
};

export default SegmentNumberedContainer;

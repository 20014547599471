export {
    default as LogoTextBlue
 } from '../../assets/logos/DYL_logo_blue_text.svg';
 
 export {
     default as LogoBlue
 } from '../../assets/logos/DYL_logo_blue.svg';
 
 export {
     default as LogoTextWhite
 } from '../../assets/logos/DYL_logo_white_text.svg';
 
 export {
     default as LogoWhite
 } from '../../assets/logos/DYL_logo_white.svg';
import React, { useRef, useState } from "react";
import { Dropdown, Portal, Ref } from "semantic-ui-react";

import "./index.scss";

const CategorySuboptions = ({
    category,
    parent_value,
    child_value,
    onChange,
    open,
    setOpen,
    dropdownStyle,
    onClick,
}) => {
    return (
        <Dropdown onClick={onClick} text={category.text} selectOnBlur={false} fluid>
            <Portal open>
                <Dropdown
                    open={open}
                    scrolling
                    selectOnBlur={false}
                    style={dropdownStyle}
                    options={category.options.map((option) => ({
                        key: option.key,
                        value: option.value,
                        text: option.text,
                        active:
                            parent_value === category.value &&
                            child_value === option.value,
                        parent: category.text,
                        selected:
                            parent_value === category.value &&
                            child_value === option.value,
                        onClick: () => {
                            if (parent_value === category.value &&
                                child_value === option.value) {
                                setOpen(false);
                            }
                        }
                    }))}
                    onChange={(e, { value }) => {
                        onChange(e, {
                            parent_value: category.value,
                            child_value: value,
                        });
                    }}
                    value={child_value}
                    className="NestedDropdown__sub-options"
                    upward={false}
                />
            </Portal>
        </Dropdown>
    );
};

function renderCategory({
    category,
    parent_value,
    child_value,
    allowedToSelectParentOnly,
    allowedToSelectParent,
    onChange,
    open,
    setOpen,
    setParentOpen,
    dropdownStyle,
}) {

    const handleClick = (e) => {
        e.preventDefault();
        if (category?.isSelectable || (Boolean(allowedToSelectParentOnly) && category?.options.length === 0) || Boolean(allowedToSelectParent)) {
            setParentOpen(false);
            onChange(e, {
                parent_value: category.value,
                child_value: null,
            });
        }
    }

    return {
        key: category.key,
        value: { parent_value: category.value, child_value: null },
        text: category.text,
        active: parent_value === category.value,
        content: category?.options?.length ? (
            <CategorySuboptions
                category={category}
                child_value={child_value}
                parent_value={parent_value}
                dropdownStyle={dropdownStyle}
                onChange={onChange}
                open={open}
                setOpen={setOpen}
                onClick={handleClick}
            />
        ) : (
            category.text
        ),
        isParent: true,
        onClick: handleClick,
    };
}

function Category({
    category,
    parent_value,
    child_value,
    allowedToSelectParentOnly,
    allowedToSelectParent,
    onChange,
    setParentOpen
}) {
    const ref = useRef();
    const [open, setOpen] = useState(false);

    const onHover = () => {
        setOpen(true);
    };

    const onBlur = () => {
        setOpen(false);
    };

    const option = renderCategory({
        allowedToSelectParent,
        allowedToSelectParentOnly,
        category,
        child_value,
        onChange: (e, data) => {
            setOpen(false);
            onChange(e, data)
        },
        parent_value,
        open,
        setOpen,
        setParentOpen,
        dropdownStyle: (() => {
            if (!ref.current) {
                return {};
            }
            const position = ref.current.getBoundingClientRect();
            return {
                left: position.left + position.width - 20,
                top: position.top - 20 + window.scrollY,
                zIndex: 99999999,
                position: "absolute",
                visibility: "hidden",
            };
        })(),
    });

    return (
        <Ref innerRef={ref}>
            <Dropdown.Item
                onMouseEnter={onHover}
                onMouseLeave={onBlur}
                {...option}
            />
        </Ref>
    );
}

const NestedDropdown = ({
    onChange,
    nested_options,

    parent_value,
    child_value,

    display_parent,

    allowedToSelectParent,
    allowedToSelectParentOnly,

    excluded_parents,

    className,
    ...otherProps
}) => {
    const [parentOpen, setParentOpen] = useState(false);

    const parent = nested_options.find(
        (category) => category.value === parent_value
    );
    const child_text =
        parent?.options?.find((option) => option.value === child_value)?.text ||
        "";

    return (
        <div>
            <Dropdown
                fluid
                {...otherProps}
                className={className || "NestedDropdown"}
                scrolling
                {...(parent_value || child_value
                    ? {
                        text: `${!!display_parent ?
                            `${parent?.text || ""}${parent && child_text && "/"
                            }` : ""
                            }${child_text}`,
                    }
                    : {})}
                clearable
                selection
                value={
                    parent_value
                        ? child_value
                            ? `${parent_value}.${child_value}`
                            : parent_value
                        : null
                }
                selectOnBlur={false}
                onChange={(e) => {
                    onChange(e, { parent_value: null, child_value: null });
                }}
                search
                open={parentOpen}
                onOpen={() => { setParentOpen(true); }}
                onClose={() => { setParentOpen(false); }}
                onBlur={() => { setParentOpen(false); }}
            >
                <Dropdown.Menu>
                    {nested_options.map((category) => {
                       return !excluded_parents?.includes(category.value) && (
                            <Category
                                category={category}
                                parent_value={parent_value}
                                child_value={child_value}
                                allowedToSelectParentOnly={
                                    allowedToSelectParentOnly
                                }
                                allowedToSelectParent={allowedToSelectParent}
                                key={category.key}
                                onChange={onChange}
                                setParentOpen={setParentOpen}
                            />
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default NestedDropdown;

import React from 'react';
import { Icon, List } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

const CollapsibleList = ({ items, toggler, disabled }) => (
    <React.Fragment>
        <List>
            {items.map(item => <List.Item disabled={disabled}>{item}</List.Item>)}
        </List>
        {toggler}
    </React.Fragment>
);

class CollapsibleListContainer extends React.Component {
    state = {
        isShowingMoreItems: false
    }

    onToggle = () => {
        this.setState(prevState => ({
            isShowingMoreItems: !prevState.isShowingMoreItems
        }));
    }

    render() {
        const { isShowingMoreItems } = this.state;
        const { items, minimumNumberOfItemsToShow, disabled } = this.props;
        return (
            <CollapsibleList 
                disabled={disabled}
                isShowingMoreItems={isShowingMoreItems}
                onToggle={this.onToggle}
                items={isShowingMoreItems ? items : items.slice(0, minimumNumberOfItemsToShow)}
                toggler={items.length > minimumNumberOfItemsToShow ? <ButtonLink noPadding onClick={this.onToggle}>{this.props.action || "Show"} {isShowingMoreItems ? 'Less' : 'More'} <Icon className={`fas fa-angle-${isShowingMoreItems ? "up" : "down"}`} /></ButtonLink> : null}
            />
        )
    }
}

export default CollapsibleListContainer;

import React from 'react';
import { Grid } from 'semantic-ui-react';

import NavHeader from './NavHeader';
import Channels from './Channels';
import DirectMessage from './DirectMessage';
import Groups from './Groups';

import './index.scss';

const NavBar = ({info, channelInfo, avatarColors}) => {

    const feed = {
        channels:info.channels
    };

    const groupMessages = info.groupMessages;
    const directMessages = info.directMessage;
    const dmCount = groupMessages.length + directMessages.length; // TODO: update this when we start pulling in state.dummyData
    
    return (
        <Grid.Column className='Chat__LeftNavBar'>
            <NavHeader />
            <Channels
                feed={feed}
                channelInfo={channelInfo}
                
            />
            <DirectMessage
                groups={
                    <Groups
                        groups={groupMessages}
                        channelInfo={channelInfo}
                        avatarColors={avatarColors}
                    />
                }
                title='Direct Messages'
                count={dmCount}
                directMessage={directMessages}
                channelInfo={channelInfo}
                avatarColors={avatarColors}
            />
        </Grid.Column>
    )
}
class LeftNavBar extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentChannelId: 9999,
            toggleChannel: this.toggleChannel
        }

        this.toggleChannel = this.toggleChannel.bind(this);
    }

    toggleChannel = (id) => {
        this.setState({currentChannelId : id})
    }

    render() {
        const channelInfo = {
            currentChannelId: this.state.currentChannelId,
            toggleChannel: this.toggleChannel
        }

        return (
            <NavBar
                info={this.props.info}
                channelInfo={channelInfo}
                avatarColors={this.props.approvedColors}
            />
        )
    }
}

export default LeftNavBar;
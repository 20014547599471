import React from 'react';
import { Popup, Icon, Grid } from 'semantic-ui-react';
import { TimeUnitToggler, CustomRangeDateModal } from 'dyl-components';

const TimestampFilters = ({
    onOpenCustomDateRange = () => {},
    timestamp,
    onToggleTimeUnit = () => { },

    areTimeUnitOptionsOpen,
    onOpenTimeUnitOptions,
    onCloseTimeUnitOptions,

    isCustomDateRangeOpen,
    onCloseCustomDateRange,
    onDateRangeChange,
    dateRange,
    getData,
    minDate
}) => (
    <React.Fragment>
        <Popup
            position='bottom center'
            flowing
            basic
            trigger={<Icon link className='fa-solid fa-calendar-plus' />}
            content={(
                <Grid onClick={onCloseTimeUnitOptions} columns={'equal'}>
                    <Grid.Column>
                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('Today') }} content='Today' active={timestamp === 'Today'} />
                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('This Week') }} content='This Week' active={timestamp === 'This Week'} />
                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('This Month') }} content='This Month' active={timestamp === 'This Month'} />
                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('All Time') }} content='All Time' active={timestamp === 'All Time'} />
                        <TimeUnitToggler content='Custom Range' onClick={() => { onToggleTimeUnit('Custom Range'); onOpenCustomDateRange(); }} active={timestamp === 'Custom Range'} />
                    </Grid.Column>
                </Grid>
            )}
            on='click'
            onOpen={onOpenTimeUnitOptions}
            open={areTimeUnitOptionsOpen}
            onClose={onCloseTimeUnitOptions}
        />
        <CustomRangeDateModal
            minDate={minDate}
            open={isCustomDateRangeOpen}
            onClose={onCloseCustomDateRange}
            onDateRangeChange={onDateRangeChange}
            dateRange={dateRange}
            getData={getData}
        />
    </React.Fragment>
);

export default TimestampFilters;

import React from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react';

export default class CustomAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: -1
        }
    }

    isActive(index) {
        return this.state.activeIndex === index;
    }

    handleClick = (_, titleProps) => {
        let newActiveIndex = titleProps.index;
        if (newActiveIndex === this.state.activeIndex) {
            newActiveIndex = -1;
        }
        this.setState({activeIndex: newActiveIndex});
    }

    render() {
        const { items, itemOpenedIcon = 'dropdown', itemClosedIcon, fluid, styled, itemIconPosition = 'left' | 'right' } = this.props;
        return (
            <Accordion fluid={fluid} styled={styled}>
                {items.map((item, index) => (
                    <React.Fragment>
                        <Accordion.Title 
                            key={index} index={index} 
                            active={this.isActive(index)} 
                            onClick={this.handleClick}
                        >
                            <Grid columns='equal' reversed={itemIconPosition === 'right' ? 'computer' : ''}>
                                <Grid.Column width={2}>
                                <Icon name={this.isActive(index) ? itemOpenedIcon : itemClosedIcon || itemOpenedIcon}/>
                                </Grid.Column>
                                <Grid.Column textAlign='left'>
                                {item.title}
                                </Grid.Column>
                            </Grid>
                        </Accordion.Title>
                        <Accordion.Content active={this.isActive(index)}>
                            {item.content}
                        </Accordion.Content>
                    </React.Fragment>
                ))}
            </Accordion>
        )
    }
}

import React from 'react';
import { Segment, Grid, Icon } from 'semantic-ui-react';

import './index.scss';

const StatisticCard = ({ name, count, icon }) => (
    <Segment className='StatisticCard'>
        <Segment color='blue' className='StatisticCard__icon' inverted raised>
            {typeof icon === 'string' ? <Icon name={icon} size='large' /> : icon}
        </Segment>
        <Grid>
            <Grid.Row columns='equal'>
                <Grid.Column className="StatisticCard__name" textAlign='right'>
                    <small>{name}</small>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign='right'>
                    <h2>{count}</h2>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
) 

export default StatisticCard;

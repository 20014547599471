import React, { useState } from 'react';
import { Button, Form, Icon, Popup, Segment } from 'semantic-ui-react';
import { RichTextEditor, TEXT_EDITOR_MODES } from 'dyl-components';

import './index.scss';

const TemplateForm = ({
    name,
    category,
    categoryOptions = [
        { key: 1, value: 1, text: 'Sales' },
        { key: 2, value: 2, text: 'Marketing' },
        { key: 3, value: 3, text: 'Service' },
        { key: 4, value: 4, text: 'General' },
        { key: 5, value: 5, text: 'Billing' }
    ],
    isFavorite,
    subject,
    signature_preference,
    signature_preference_options = [
        {
            key: 'my_signature',
            value: 'my_signature',
            text: 'My Signature'
        },
        {
            key: 'company_signature',
            value: 'company_signature',
            text: 'Company Signature'
        },
        {
            key: 'none',
            value: 'none',
            text: 'None'
        }
    ],

    body,
    attachments,

    onChange,

    onPersonalize = () => { },
    editorRef,
    errors = {}
}) => {
    const [mode, setMode] = useState(TEXT_EDITOR_MODES.RICH_TEXT);

    const onSwitchToHTML = () => {
        setMode(TEXT_EDITOR_MODES.HTML);
    }

    const onSwitchToRichText = () => {
        setMode(TEXT_EDITOR_MODES.RICH_TEXT);
    }

    return (
        <Form onSubmit={() => { }}>
            <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Form.Input
                    label="Template Name"
                    required
                    name="name"
                    value={name}
                    onChange={onChange}
                    width={10}
                    error={errors.name}
                />
                <Form.Select
                    label="Category"
                    required
                    name="category"
                    placeholder="Select Category"
                    selectOnBlur={false}
                    options={categoryOptions}
                    onChange={onChange}
                    width={5}
                    value={category}
                    error={errors.category}
                />
                <Form.Field
                    control={Icon}
                    onClick={() => { onChange(null, { name: 'isFavorite', value: !isFavorite }); }}
                    name={!isFavorite ? 'heart outline' : 'heart'}
                    link
                    color='blue'
                    width={1}
                    size="big"
                    label=" "
                    style={{ marginTop: "0.75em" }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Input
                    label="Subject"
                    name="subject"
                    value={subject}
                    onChange={onChange}
                    width={10}
                    placeholder="Type email subject" />
                <Form.Field width={5}>
                    <Form.Field label="Signature" />
                    <Form.Group style={{ marginTop: "0.75em" }}>
                        {signature_preference_options.map(({ key, value, text }) => (
                            <Form.Radio
                                key={key}
                                label={text}
                                value={value}
                                checked={signature_preference === value}
                                onChange={onChange}
                                name="signature_preference"
                            />
                        ))}
                    </Form.Group>
                </Form.Field>
            </Form.Group>
            <Segment.Group>
                <RichTextEditor
                    basic
                    style={{ marginBottom: 0 }}
                    name='body'
                    onChange={onChange}
                    value={body}
                    toolbarClassName={"body_toolbar"}
                    allowSwitchToHTML
                    onSwitchToHTML={onSwitchToHTML}
                    onSwitchToRichText={onSwitchToRichText}
                    mode={mode}
                    editorRef={editorRef}
                    otherControls={[
                        <Popup
                            trigger={<Button type="reset" primary>Personalize</Button>}
                            content={(
                                <Form className="Template__variables">
                                    <h5>Sender Variables</h5>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%My Full Name%%') }} fluid size='mini' basic primary>My Full Name</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%My First Name%%') }} fluid size='mini' basic primary>My First Name</Form.Button>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%My Phone%%') }} fluid size='mini' basic primary>My Phone</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%My Company%%') }} fluid size='mini' basic primary>My Company</Form.Button>
                                    </Form.Group>
                                    <h5>Recipient Variables</h5>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%Full Name%%') }} fluid size='mini' basic primary>Full Name</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%First Name%%') }} fluid size='mini' basic primary>First Name</Form.Button>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%Last Name%%') }} fluid size='mini' basic primary>Last Name</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%Phone%%') }} fluid size='mini' basic primary>Phone</Form.Button>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%Address%%') }} fluid size='mini' basic primary>Address</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%City%%') }} fluid size='mini' basic primary>City</Form.Button>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Button onClick={() => { onPersonalize('%%State%%') }} fluid size='mini' basic primary>State</Form.Button>
                                        <Form.Button onClick={() => { onPersonalize('%%Zip%%') }} fluid size='mini' basic primary>Zip</Form.Button>
                                    </Form.Group>
                                </Form>
                            )}
                            position='bottom right'
                            on='click'
                        />
                    ]}
                />
                <Form.Field as={Segment} style={{ height: 125 }}>
                    <Icon color='blue' name='plus circle' link /> <b>Attach Files</b>
                </Form.Field>
            </Segment.Group>
        </Form>
    );
};

export default TemplateForm;

import { GridList } from "dyl-components";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Icon, Grid, Header } from "semantic-ui-react";

const GridTimelineItem = ({
    onClick,
    date,
    time,
    markerColor,
    itemComponent,
    otherControls,
    selectedItem,
    icon,
    id,
    counter,
}) => {
    return (
        <GridList.Row
            onClick={onClick}
            className={`GridTimeline__item${selectedItem ? selectedItem === id ? '--selected' : '' : ''}`}
            basic
            style={{
                padding: "10px 5px",
                marginBottom: "0.5em",
            }}
            verticalAlign="middle"
            columns="equal"
        >
            {otherControls && (
                <GridList.Cell width={1}>{otherControls}</GridList.Cell>
            )}
            <GridList.Cell width={2} className="GridTimeline__timestamp">
                <Header sub as="h6" size="tiny" style={{ marginBottom: 0 }}>
                    {date}
                </Header>
                <Header
                    sub
                    as="h6"
                    size="tiny"
                    style={{ fontWeight: 100, marginTop: 0, display: "inline" }}
                >
                    {time}
                </Header>
            </GridList.Cell>
            <GridList.Cell width={1} className="GridTimeline__marker-line">
                {icon ? (
                    <>
                        <div className="GridTimeline__markerIcon">
                            <Icon className={icon} />
                        </div>
                        {counter && (
                            <div className="GridTimeline__markerCounter">
                                <Header
                                    sub
                                    as="h6"
                                    style={{ fontWeight: 100, marginBottom: 0 }}
                                >
                                    {counter}
                                </Header>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        className="GridTimeline__marker"
                        style={{ borderColor: markerColor }}
                    />
                )}
            </GridList.Cell>
            {itemComponent}
        </GridList.Row>
    );
};
const GridTimeLineHeader = ({
    titles = [],
    otherControls,
    dateIcon1,
    dateIcon2,
}) => (
    <GridList.Row className="GridTimeline__header">
        {otherControls && <GridList.Cell width={1} />}
        <GridList.Cell width={2}>
            <Header as="h4" className="GridTimeline__date">
                <Grid.Row>
                    Date {dateIcon1} {dateIcon2}
                </Grid.Row>
            </Header>
        </GridList.Cell>
        <GridList.Cell width={1} />
        {titles.map((title, index) => (
            <GridList.Cell key={index} width={title.width}>
                <Header as="h4" color={title.color}>
                    {title.title}
                    {title.icon}
                </Header>
            </GridList.Cell>
        ))}
    </GridList.Row>
);

const GridTimeline = ({
    items = [],
    titles = [],
    selectedRow = () => { },
    ...otherProps
}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const otherControls =
        items.length !== 0 ? items[0].hasOwnProperty("otherControls") : false;
    const isTitlesEmpty = titles.length === 0;
    return (
        <GridList className="GridTimeline">
            {!isTitlesEmpty && (
                <GridTimeLineHeader
                    titles={titles}
                    otherControls={otherControls}
                    {...otherProps}
                />
            )}
            {items.map(({ ...props }) => {
                return (
                    <GridTimelineItem
                        key={props.id}
                        {...props}
                        {...otherProps}
                        selectedItem={selectedItem}
                        onClick={() => {
                            setSelectedItem(props.id);
                            selectedRow(props);
                        }}
                    />
                );
            })}
        </GridList>
    );
};

export default GridTimeline;

import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';
import './index.scss';

const LatestNoteContent = ({ noteVisible, latestNote, onShowNote }) => (
    <React.Fragment>
        {noteVisible && (
            <div className='LatestNoteContent' >
                <span>
                    {latestNote}
                </span>
            </div>
        )}
        <Popup
            inverted
            position='top center'
            trigger={
                <div className='LatestNotePopup'>
                    <ButtonLink onClick={onShowNote}>
                        {noteVisible ? 'Hide note' : 'Show note'}<Icon name='caret right' />
                    </ButtonLink>
                </div>
            }
            content={latestNote}
            disabled={noteVisible}
        />
    </React.Fragment>
);

export default LatestNoteContent;

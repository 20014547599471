import React, { Component } from "react";
import { Form, Menu, Input, Icon, Button, Dropdown } from "semantic-ui-react";

export default class ChatHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFavorite: false
        }
    }

    toggleFavorite = () => {
        this.setState((prevState) => ({
            isFavorite: !prevState.isFavorite,
        }));
    }

    checkMsgsNotification = () => {
        console.log(`|+|+|+ Doing something with unread messages`);
    }

    searchSubmit = (e) => {
        console.log(`|+|+|+ Doing something with Search input`);
    }

    render() {
        const { isFavorite } = this.state;
        const channelName = this.props.headerData.channelName;

        return (
            <Menu borderless className="Chat__Window__FeedHeader menu-border-bottom">
                <Menu.Item as="h2" className="header" name={channelName}>{channelName}</Menu.Item>

                <Menu.Item as={Button} onClick={this.toggleFavorite}>
                    <Icon name="star" color={isFavorite ? "yellow" : "none"} />
                </Menu.Item>

                <Menu.Menu position="right">
                    <Menu.Item disabled>
                        <Form onSubmit={this.searchSubmit}>
                            <Input icon="search" placeholder="Search..." disabled />
                        </Form>
                    </Menu.Item>

                    <Menu.Item as={Button} onClick={this.checkMsgsNotification} disabled>
                        <Icon name="bell" />
                    </Menu.Item>
                    <Menu.Item >
                        <Dropdown icon="ellipsis vertical">
                            <Dropdown.Menu>
                                <Dropdown.Item>Edit Room Title</Dropdown.Item>
                                <Dropdown.Item>Archive/Disable Room</Dropdown.Item>
                                <Dropdown.Item>Delete Room</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}

import React, { useState } from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';

import './index.scss';

const HoverControls = ({ controls, children }) => {
    const [visible, setControlsVisiblity] = useState(false);

    const onSetVisibility = (visible) => {
        setControlsVisiblity(visible);
    }

    return (
        <Segment 
            className='HoverControls'
            onMouseEnter={() => { onSetVisibility(true) }}
            onMouseLeave={() => { onSetVisibility(false) }}
        >
            <Dimmer active={visible}>
                {controls}
            </Dimmer>
            {children}
        </Segment>
    )
};

export default HoverControls;

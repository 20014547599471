import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import './TimelineTab.scss';

const TimelineTab = ({ color, timelineSubHeader, timelineBody, timelineFooter }) => {
    return (
        <Segment as={Grid} className='TimelineTab' style={{ borderTopColor: `${color}` }}>
            {timelineSubHeader &&
                <Grid.Row columns='equal' className='TimelineTab__Row TimelineTab_SubHeader'>
                    <Grid.Column>
                        {timelineSubHeader}
                    </Grid.Column>
                </Grid.Row>
            }
            {timelineBody &&
                <Grid.Row className='TimelineTab__Row TimelineTab_Body'>
                    <Grid.Column>
                        {timelineBody}
                    </Grid.Column>
                </Grid.Row>
            }
            {timelineFooter &&
                <Grid.Row columns='equal' className='TimelineTab__Row TimelineTab_Footer'>
                    <Grid.Column>
                        {timelineFooter}
                    </Grid.Column>
                </Grid.Row>
            }
        </Segment>
    )
};




export default TimelineTab;

import React from 'react';
import { Grid } from 'semantic-ui-react';

import './index.scss';

const OldGroupedTimeline = ({ groups, header }) => (
    <Grid>
        {groups.map((group, groupIndex) => (
            <Grid.Row key={groupIndex} className='MarkedTimeline__group'>
                <Grid.Column>
                    {header && groupIndex === 0 && (
                        <Grid className='MarkedTimeline__header' columns='equal'>
                            <Grid.Column width={3} />
                            <Grid.Column>
                                {header}
                            </Grid.Column>
                        </Grid>
                    )}
                    <Grid columns={3} verticalAlign='middle' textAlign='left'>
                        <div className='MarkedTimeline__line' />
                        <Grid.Column className='MarkedTimeline__group-name' width={1} textAlign='right'>
                            <strong><em>{group.name}</em></strong>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='MarkedTimeline__divider' />
                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                    </Grid>

                    {group.dateTimes.map(({ date, time, icon, details }, index) => (
                        <Grid className='MarkedTimeline__item' columns='equal' key={index} verticalAlign='middle'>
                            <Grid.Column width={2} textAlign='right'>
                                <div>
                                    <strong>{date}</strong>
                                </div>
                                <div>
                                    <small>{time}</small>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={1} className='MarkedTimeline__marker-line'>
                                <div className='MarkedTimeline__icon'>
                                    {icon}
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                {details}
                            </Grid.Column>
                        </Grid>
                    ))}
                </Grid.Column>
            </Grid.Row>
        ))}
    </Grid>
);

export default OldGroupedTimeline;

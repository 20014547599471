import React from "react";
import {
    Form,
    Transition,
    Segment,
    Pagination,
    Label,
    Icon,
} from "semantic-ui-react";
import { SearchBar, VALIDATION_PATTERNS } from "dyl-components";
import "./index.scss";

const SearchableOptions = ({
    loading,
    search,
    searchFunction,
    cancelSearchFunction,
    searchPlaceholder,
    onChangeSearch,
    searchSubcomponent,
    isSearchResultsVisible,
    renderSearchResults,
    isPaginationVisible,
    paginationProps,
    validationPattern = VALIDATION_PATTERNS.alphanumeric_search,
    values,
    label,
    onChangeSelected,
}) => {

    return (
        <React.Fragment>
            <b>{label}</b>
            {(values && values.length && (
                <div className="ToggableDropdown_Options">
                    {values.map((value, idx) => {
                        return (
                            <Label
                                key={idx}
                                className="ToggableDropdown__label"
                            >
                                {value.text || ""}
                                <Icon
                                    style={{ marginLeft: "5px" }}
                                    className="fas fa-times"
                                    link
                                    onClick={(e) => {
                                        onChangeSelected(e, {value});
                                    }}
                                />
                            </Label>
                        );
                    })}
                </div>
            )) ||
                ""}

            <Form.Group>
                <Form.Field
                    control={SearchBar}
                    isSearching={loading}
                    search={search}
                    searchFcn={searchFunction}
                    cancelFcn={cancelSearchFunction}
                    placeholder={searchPlaceholder}
                    onChange={onChangeSearch}
                    validationPattern={validationPattern}
                />
                {searchSubcomponent}
            </Form.Group>
            <Transition visible={isSearchResultsVisible}>
                <Segment.Group>
                    {renderSearchResults}
                    {isPaginationVisible && (
                        <Segment textAlign="right">
                            <Pagination {...paginationProps} />
                        </Segment>
                    )}
                </Segment.Group>
            </Transition>
        </React.Fragment>
    );
};

export default SearchableOptions;

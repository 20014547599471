import React from 'react';
import './index.scss'

const NoteItem = ({name, time, content}) => (
    <div className='NoteItem'>
        <div className='NoteItem__name'>{name}</div>
        <div className='NoteItem__time'>{time}</div>
        <div className='NoteItem__content'>{content}</div>
    </div>
)

export default NoteItem;

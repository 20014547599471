import React from 'react';
import Avatar from 'react-avatar';
import { List, Icon, Popup } from 'semantic-ui-react';
import './index.scss';

const UserAvatar = ({ user_id, showAllUsersList, usersList, usersLimit }) => {
    let users = [];
    for (let x = 0; (!showAllUsersList && (x < usersLimit && x < usersList.length)) || (showAllUsersList && x < usersList.length); x++) {
        users.push((
            <Popup
                key={usersList[x].id}
                trigger={(
                    <List className='UserAvatar'>
                        <List.Item key={usersList[x].id}>
                            <List.Icon verticalAlign='middle'>
                                <Avatar className={`sb-avatar${!usersList[x].active ? '--inactive' : ''}`} name={`${usersList[x].first_name} ${usersList[x].last_name}`} maxInitials={2} round size='3em' />
                            </List.Icon>
                            <List.Content>
                                <List.Header>
                                    <span className={`UserAvatar__name${!usersList[x].active ? '--inactive' : ''}`}>{usersList[x].first_name} {usersList[x].last_name}{usersList[x].id === user_id && " (me)"}</span>
                                </List.Header>
                                <List.Description className={`UserAvatar__desc${!usersList[x].active ? '--inactive' : ''}`}>
                                    <Icon disabled={!usersList[x].active} name="mail outline" /><User user={usersList[x]} />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                )}
                content={(
                    <div>
                        <p>Name: {usersList[x].first_name} {usersList[x].last_name}{usersList[x].id === user_id && " (me)"}</p>
                        <p>Email: {usersList[x].email}</p>
                    </div>
                )}
                inverted
            />
        ));
    }
    return users;
}

const User = ({ user }) => {
    return (
        <a href={`mailto:${user.email}`} className={!user.active ? 'UserAvatar--inactive' : ''}>{user.email}</a> 
    );
}

export default UserAvatar;
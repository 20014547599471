import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { Template } from 'dyl-components';

const EmailTemplateThumbnail = ({ isReading, templates, onPreview, onEdit, onCopy, onDelete, onFavorite }) => {
    return (
        isReading ? <Loader active /> :
            templates.map(template => (
                <Grid.Column key={template.name} style={{ padding: 0 }}>
                    <Template
                        previewContent={template.body}
                        name={template.name}
                        isFavorite={template.isFavorite}
                        favoriteIcon='star'
                        onPreview={() => { onPreview(template) }}
                        onEdit={() => { onEdit(template) }}
                        onCopy={() => { onCopy(template) }}
                        onDelete={() => { onDelete(template.id) }}
                        onFavorite={() => { onFavorite(template.id) }}
                    />
                </Grid.Column>
            ))
    )
}

export default EmailTemplateThumbnail;

import React, { Component } from 'react';
import { Grid, Accordion, Icon, List } from 'semantic-ui-react';
import Avatar, { Cache } from 'react-avatar';

import AddDirectMessage from './addDirectMessage';
import './index.scss';



const DirectMsg = ({
    groups,
    title,
    directMessage,
    count,
    isChannelOpen,
    onToggleChannel,
    avatarColor,
    onSelectDirectMessage,
    currentChannel,
    avatarColors

}) => {

    const cache = new Cache({
 
        // Keep cached source failures for up to 7 days
        sourceTTL: 7 * 24 * 3600 * 1000,
     
        // Keep a maximum of 20 entries in the source cache
        sourceSize: 20
    });

    return(
        <Grid.Column>
            <Accordion className='Chat__NavLeftMargin'>
                <Accordion.Title
                    onClick={onToggleChannel}
                    className='Chat__AccordTitleInline'
                >
                    <Icon name={isChannelOpen ? 'chevron down' : 'chevron right'} className='Chat__ChannelArrow' />
                    <span className='Chat__ChannelTitle'>{title}</span>
                </Accordion.Title>
                <span className='Chat__DirectMessage'>
                    <AddDirectMessage />
                </span>
                <span className='Chat__Count'>{count}</span>
                <Accordion.Content active={isChannelOpen}>
                    <List className="Chat__DirectMessageList">
                        {directMessage !== undefined && (
                            directMessage.map((dm, i) => {  
                                
                                return(
                            
                                    <List.Item key={i}
                                        onClick={() => { onSelectDirectMessage(`DM-${i}`) }}
                                    >
                                        <List.Icon name='circle' size='tiny' color={dm.status} verticalAlign='middle' />
                                        <List.Content className={(currentChannel === `DM-${i}` ? 'Chat__ChannelName active' : 'Chat__ChannelName')}>
                                            <Avatar
                                                color={avatarColors[i]}
                                                name={dm.name}
                                                maxInitials={2}
                                                round='3px'
                                                size={32}
                                                style={{ marginRight: 10 }}
                                                cache={cache}
                                            />
                                            {dm.name}
                                        </List.Content>
                                    </List.Item>
                                )
                            }))}
                    </List>
                    {groups !== undefined && (
                        groups
                    )}
                </Accordion.Content>
            </Accordion>
        </Grid.Column>
    )
};


export default class DirectMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleChannelGroup: this.toggleChannelGroup,
            isChannelGroupOpen: true,
        }
    }

    toggleChannelGroup = () => {
        this.setState(prevState => ({
            isChannelGroupOpen: !prevState.isChannelGroupOpen
        }))
    }

    onSelectDirectMessage = (id) => {
        console.log(`|+| Clickon the DM for id`, id);
        this.props.channelInfo.toggleChannel(id);
    }

    render() {
        const { isChannelGroupOpen } = this.state
        
        return (
            <DirectMsg
                groups={this.props.groups}
                title={this.props.title}
                directMessage={this.props.directMessage}
                count={this.props.count}
                isChannelOpen={isChannelGroupOpen}
                onToggleChannel={this.toggleChannelGroup}
                avatarColor={'#00bfa5'}
                onSelectDirectMessage={this.onSelectDirectMessage}
                currentChannel={this.props.channelInfo.currentChannelId}
                avatarColors={this.props.avatarColors}


            />
        )
    }
}

import React, { useRef } from "react";
import { Grid, Button, Label } from "semantic-ui-react";

import "./index.scss";

export const FileAttach = ({ buttonName = 'Attach', value, onChange, ...otherProps }) => {
  const fileInputRef = useRef(null);
  return (
    <Button fluid as='div' labelPosition='right'>
      <Button basic onClick={() => {fileInputRef.current.click()}}>
        {buttonName}
      </Button>
      <Label basic className={'FileAttach__label'}>
        {value}
      </Label>
      <input
          type="file"
          ref={fileInputRef}
          hidden
          value={value}
          onChange={onChange}
          {...otherProps}
      />
    </Button>
  )
}

export class IconFileAttach extends React.Component {
    constructor(props) {
        super(props);

        this.fileRef = React.createRef();
    }

    triggerInputFileClick = () => {
        this.fileRef.current.click();
    };

    render() {
        const { icon } = this.props;

        return (
            <Grid className="IconFileAttach">
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={2}
                        computer={2}
                        className="IconFileAttach__col1"
                    >
                        <Button
                            basic
                            onClick={this.triggerInputFileClick}
                            icon={icon}
                        ></Button>
                        <input
                            type="file"
                            className="IconFileAttach__col1-file"
                            ref={this.fileRef}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

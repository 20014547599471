import React, { Component, createRef } from "react";
import { Comment, Segment, Divider } from "semantic-ui-react";
import Avatar, { Cache } from "react-avatar";
import dayjs from "dayjs";

const showMessageTime = (timestamp) => {
    const date = dayjs(parseInt(timestamp, 10)),
        validDate = date.isValid(),
        getDay = (date) => {

            if (date.isSame(Date.now(), 'day')) {
                return "Today"
            } else {
                return date.isBefore(Date.now(), "year")
                    ? date.format("dddd, MMMM D, YYYY")
                    : date.format("dddd, MMMM D");
            }
        };

    return validDate
        ? { time: date.format("h:mm A"), day: getDay(date) }
        : { time: "--:--", day: "--:--" };
};

const MessageFeed = ({ feed, color }) => {
    /** TODO: properly sort the feed  e.g. memoize*/
    feed.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

    let sectionDate = "Beginning of room";
    const cachAvatarTime = new Cache({

        // Keep cached source failures for up to 7 days
        sourceTTL: 7 * 24 * 3600 * 1000,

        // Keep a maximum of 600 entries in the source cache
        sourceSize: 600
    });



    return (
        <div className="FeedStream">
            {feed !== undefined &&
                feed.map((msg, i) => {
                    const msgDate = showMessageTime(msg.timestamp);
                    const dayHeader = sectionDate !== msgDate.day && (
                        <Divider horizontal>{msgDate.day}</Divider>
                    );
                    sectionDate = msgDate.day;

                    return (
                        <Comment.Group key={i}>
                            {dayHeader}
                            <Comment className="chat-message" key={i}>
                                <Avatar
                                    color={color}
                                    name={msg.author}
                                    maxInitials={2}
                                    round="3px"
                                    size={32}
                                    src={msg.avatar}
                                    cache={cachAvatarTime}
                                />

                                <Comment.Content>
                                    <Comment.Author as="a" href="#">
                                        {msg.author}
                                    </Comment.Author>
                                    <Comment.Metadata>
                                        <span> {msgDate.time} </span>
                                    </Comment.Metadata>
                                    <Comment.Text>{msg.message}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        </Comment.Group>
                    );
                })}
        </div>
    );
};

export default class ChatFeed extends Component {
    constructor(props) {
        super(props);
        this.bottomOfFeedRef = createRef(null);
    }

    executeScroll = () => this.bottomOfFeedRef.current.scrollIntoView()

    componentDidMount() {
        this.executeScroll();
    }
    componentDidUpdate() {
        this.executeScroll();
    }

    
    render() {
        const feed = this.props.stream,
            color = this.props.color;

        return (
            <Segment compact className="Chat__Feed">
                <MessageFeed
                    feed={feed}
                    color={color} />
                <div ref={this.bottomOfFeedRef} />
            </Segment>
        );
    }
}



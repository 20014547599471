import React, { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import './index.scss';

const ListThumbnailViewer = ({ view = 'list', header = null, listView = <React.Fragment />, thumbnailView = <React.Fragment /> }) => {

    const [pageView, setView] = useState(view);

    const renderPageView = () => {
        switch (pageView) {
            case 'list':
                return (
                    <Grid.Column width={16} style={{ padding: 0 }}>
                        {listView}
                    </Grid.Column>)
            case 'thumbnail':
                return (thumbnailView);
            default:
                return <React.Fragment />
        }
    }

    return (
        <Grid key={pageView}>
            <Grid.Row columns={'equal'} style={{ padding: 0 }}>
                <Grid.Column textAlign='right' floated='right' style={{ padding: 0 }} className='ListThumbnailViewer_Switcher'>
                    <Icon name='fa-solid fa-objects-column' link size='large' onClick={() => { setView('thumbnail'); }} color={pageView === 'thumbnail' ? 'blue' : 'black'} />
                    <Icon name='fas fa-list' link size='large' onClick={() => { setView('list'); }} color={pageView === 'list' ? 'blue' : 'black'} />
                </Grid.Column>
            </Grid.Row>
            {header &&
                <Grid.Row>
                    {header}
                </Grid.Row>
            }
            <Grid.Row columns={pageView === 'list' ? 'equal' : 6} style={{ paddingTop: pageView === 'list' ? 0 : 14 }}>
                {renderPageView()}
            </Grid.Row>
        </Grid>
    );
}

export default ListThumbnailViewer;
